
<div>

  <h4>
    Detalles de Venta de Vísceras
  </h4>


  <button class="btn btn-secondary"
          (click)="close()">
    Cerrar
  </button>

  <hr />

  <app-header-details #saleDetails></app-header-details>

  <div class="row">
    <div class="col-md-10 col-sm-12 mx-auto">
      <app-list-details #customerDetails></app-list-details>
      <hr />

      <app-list-details #cageDetails></app-list-details>
      <hr />

      <app-square-details #gutsList></app-square-details>
      <hr />
    </div>
  </div>

</div>

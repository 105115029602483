
<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5>Crear Nómina</h5>

      <hr />
      <br />

      <form [formGroup]="payrollForm">

        <app-butchery-selector #butcherySelector></app-butchery-selector>

        <app-date-range #datesSelector></app-date-range>

        <igx-input-group>
          <input igxInput name="Food"
                 [ngClass]="{'is-invalid': payrollForm.get('Food').errors && payrollForm.get('Food').touched}"
                 type="number"
                 aria-label="Comida"
                 formControlName="Food" />
          <label igxLabel for="Food">Comida</label>
        </igx-input-group>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="getData()">
            Continuar
          </button>

          <!--<button class="btn btn-primary"
                  (click)="test()">
            TEST
          </button>-->

          <button class="btn btn-secondary"
                  (click)="cancel()">
            Cancelar
          </button>

        </div>

      </form>

    </div>
  </div>

</div>

<div [hidden]="showForm">

  <h5 *ngIf="!viewDetails">Crear Nómina</h5>
  <h5 *ngIf="viewDetails">Detalles de Nómina</h5>

  <hr />
  <br />

  <div class="row">
    <div class="col-md-8">
      <app-header-details #payrollHeader></app-header-details>
    </div>
    <div class="col-md-4 text-right">
      <!--<p class="text-right">-->

      <button class="btn btn-outline-secondary"
              *ngIf="!showDetails"
              (click)="cancel()">
        Modificar Parámetros
      </button>

      <button class="btn btn-outline-secondary"
              *ngIf="showDetails"
              (click)="close()">
        Cerrar
      </button>

      <!--</p>-->
    </div>
  </div>













  <div [hidden]="!showGrid">
    <kendo-grid id="productsGrid"
                (cellClick)="cellClickHandler($event)"
                [data]="view">
      <ng-template kendoGridToolbarTemplate>
        <button *ngIf="isEditing"
                style="float:left;"
                (click)="saveRow()">
          Guardar
        </button>
        <button *ngIf="isEditing"
                style="float:left;"
                (click)="cancelHandler()">
          Cancelar
        </button>
        <button kendoGridExcelCommand type="button"
                icon="file-excel"
                style="float:right;">
          Exportar a Excel
        </button>
      </ng-template>
      <kendo-grid-column field="Employee.FullName" title="Empleado"></kendo-grid-column>
      <kendo-grid-column field="DaysWorked" editor="numeric" title="Días Trabajados"></kendo-grid-column>
      <kendo-grid-column field="DailySalary" editor="numeric" title="Sueldo Diario"></kendo-grid-column>
      <kendo-grid-column field="CalculatedSalary" editor="numeric" title="Sueldo Calculado"></kendo-grid-column>
      <kendo-grid-column field="Food" editor="numeric" title="Comidas"></kendo-grid-column>
      <kendo-grid-column field="Delays" editor="numeric" title="Retardos"></kendo-grid-column>
      <kendo-grid-column field="TotalMeals" editor="numeric" title="Total Comidas"></kendo-grid-column>
      <kendo-grid-column field="Discounts" editor="numeric" title="Descuentos"></kendo-grid-column>
      <kendo-grid-column field="Extra" editor="numeric" title="Extras"></kendo-grid-column>
      <kendo-grid-column field="Total" editor="numeric" title="Total"></kendo-grid-column>

      <!--<kendo-grid-pdf fileName="{{filename}}.pdf" [repeatHeaders]="true"></kendo-grid-pdf>-->
      ri
      <kendo-grid-excel fileName="{{filename}}.xlsx"></kendo-grid-excel>

    </kendo-grid>

    <br />
    <hr />

    <div class="text-right">
      <button class="btn btn-primary"
              *ngIf="!showDetails"
              style="width:100px;"
              (click)="save()">
        Guardar
      </button>
    </div>
  </div>
</div>

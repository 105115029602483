import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerVM, CustomerMovement } from '../../../models/ViewModels';
import { CustomValidators} from '../../../validators/custom-validators';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { SearchCustomerComponent } from '../../../components/search-customer/search-customer.component';
import { HeaderDetailsComponent } from '../../../components/header-details/header-details.component';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  customers: CustomerVM[] = [];
  customerVM: CustomerVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  customerForm: FormGroup;

  @ViewChild("customerSelector", { read: SearchCustomerComponent, static: true })
  public customerSelector: SearchCustomerComponent;

  showDetails: boolean = false;
  @ViewChild("details", { read: HeaderDetailsComponent })
  public details: HeaderDetailsComponent;

  movements: CustomerMovement[] = [];
  @ViewChild("movementsGrid", { read: GenericGridComponent })
  public movementsGrid: GenericGridComponent;

  @ViewChild("balanceAlert") balanceAlert!: ElementRef;

  constructor(private customerService: CustomerService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.customerSelector.getNoCredits = true;
    this.getAllCustomers();
  }

  initForm() {
    this.customerForm = this.fb.group({
      BalanceAlert: [0, [Validators.required, CustomValidators.greaterThanZero]],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.customerForm.valid
      && this.customerSelector.isValidForm();
  }

  isEditionValidForm(): boolean {
    return this.customerForm.valid;
  }

  getAllCustomers() {
    this.loading.show();
    this.customerService.getCreditCustomers()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          this.customers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.customers);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "FullName", title: "Nombre" },
      //{ field: "TotalPurchases", title: "Total Compras", currencyType: true },
      //{ field: "TotalPayments", title: "Total Pagos", currencyType: true },
      { field: "BalanceAlert", title: "Saldo de Alerta", currencyType: true },
      { field: "Balance", title: "Saldo", currencyType: true },
      { field: "LastPayment", title: "Último Pago", dateType: true },
      //{ field: "StateName", title: "Estado" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.customerSelector.disabled = false;
    this.customerSelector.resetForm();
    this.showForm = false;
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const creditCustomer: CustomerVM = this.customerSelector.selectedItem;
      creditCustomer.HasCredit = true;
      creditCustomer.BalanceAlert = this.customerForm.controls["BalanceAlert"].value;
      this.customerService.updateCreditStatus(creditCustomer)
        .subscribe((response: boolean) => {
          if (response) {
            this.grid.addRow(creditCustomer);
            this.cancel();
            this.customerSelector.loadComboValues();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(customer: CustomerVM) {
    this.customerVM = customer;
    this.customerForm.setValue({
      BalanceAlert: this.customerVM.BalanceAlert,
    });
    this.customerSelector.itemText = this.customerVM.FullName;
    this.isEdition = true;
    this.customerSelector.disabled = true;
    this.setFormVisibility();
    setTimeout(() => {
      this.balanceAlert.nativeElement.focus();
      this.balanceAlert.nativeElement.select();
    }, 0);
  }

  update() {
    if (this.isEditionValidForm()) {
      this.loading.show();
      this.customerVM.BalanceAlert = this.customerForm.controls["BalanceAlert"].value;
      this.customerService.updateCredit(this.customerVM)
        .subscribe((response: boolean) => {
          if (response) {
            this.grid.updateRowByUUID(this.customerVM);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(customer: CustomerVM) {
    this.alertify.confirm(
      `Eliminar cliente: ${customer.FullName}`,
      () => {
        this.loading.show();        
        customer.HasCredit = false;
        this.customerService.updateCreditStatus(customer)
          .subscribe((response: boolean) => {
            if (response) {
              this.customerSelector.loadComboValues();
              this.grid.deleteRow(customer.UUID);
              this.cancel();
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  select(customer: CustomerVM) {
    this.loading.show();
    this.customerService.getCustomerMovements(customer.UUID)
      .subscribe((response: CustomerMovement[]) => {
        if (response) {
          this.movements = response;
        }
        this.setMovementsGridOptions();
        this.setDetailsHeader(customer);
        this.loading.hide();
        this.showDetails = true;
      }, (err) => {
          this.loading.hide();
      }); 
  }

  setDetailsHeader(customer: CustomerVM) {
    this.details.headerItems = [
      { title: "Cliente", value: customer.FullName },
      { title: "Total Compras", value: customer.TotalPurchases, isCurrency: true },
      { title: "Total Pagos", value: customer.TotalPayments, isCurrency: true },
      { title: "Saldo", value: customer.Balance, isCurrency: true },
      { title: "Último Pago", value: customer.LastPayment, isDate: true },
      { title: "Saldo de Alerta", value: customer.BalanceAlert, isCurrency: true },
    ];
    this.details.showHeader = true;
  }

  setMovementsGridOptions() {
    this.movementsGrid.setData(this.movements);
    this.movementsGrid.options.primaryKey = "UUID";
    this.movementsGrid.options.toolbarTitle = "Movimientos de Cliente";
    this.movementsGrid.options.showDetailsButton = false;
    this.movementsGrid.options.showEditButton = false;
    this.movementsGrid.options.showDeleteButton = false;
    this.movementsGrid.options.hideActionButtons = true;
    this.movementsGrid.columns = [
      { field: "Date", title: "Fecha", dateType: true },
      { field: "MovementTypeNAme", title: "Tipo" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "MovementInfo", title: "Descripción" },
    ];
    this.movementsGrid.setGridOptions();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CageIdentifier } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { StateService } from '../../services/state.service';
import { CageService } from '../../services/cage.service';

@Component({
  selector: 'app-cage-identifier-selector',
  templateUrl: './cage-identifier-selector.component.html',
  styleUrls: ['./cage-identifier-selector.component.scss']
})
export class CageIdentifierSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione identificador...";
  items: CageIdentifier[];
  identifierForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedIdentifier: CageIdentifier;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  constructor(private cageService: CageService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadIdentifiersCombo();

  }

  initForm() {
    this.identifierForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedIdentifier = this.items.find(item => item.Description == eventArgs.newSelection.value);
    this.identifierForm.get('ComboValue').setValue(this.selectedIdentifier.Description);
  }

  public loadIdentifiersCombo(): void {
    this.initForm();
    this.items = this.cageService.getCageIdentifiers();
    this.setTodayDay();
  }

  setTodayDay() {
    let day = new Date().getDay();
    this.setComboById(this.items[day].Id);
  }

  setComboByDayOfTheWeek(day: number) {
    if (this.items) {
      this.setComboById(this.items[day].Id);
    }
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedIdentifier = this.items.find(item => item.Id == id);
      this.identifierForm.get('ComboValue').setValue(this.selectedIdentifier.Description);
    }
  }

  public setComboByDescription(description: string) {
    if (this.items && this.items.length > 0 && description !== "" && description !== null) {
      this.selectedIdentifier = this.items.find(item => item.Description == description);
      this.identifierForm.get('ComboValue').setValue(this.selectedIdentifier.Description);
    }
  }

  public getIdentifierDescription(): string {
    if (this.selectedIdentifier)
      return this.selectedIdentifier.Description;

    return null;
  }

  public getIdentifier(): string {
    if (this.selectedIdentifier)
      return this.selectedIdentifier.Id;

    return null;
  }

  resetForm() {
    this.initForm();
    //this.selectedIdentifier = null;
    this.setTodayDay();
  }

  openDropDown() {

  }


}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { ButcheryVM, OperationResult } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class ButcheryService {

  butcheryApi = `${environment.apiUrl}/Butchery`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(butchery: ButcheryVM) {
    return this.http.post(this.butcheryApi, butchery).pipe(
      map((response: OperationResult<ButcheryVM>) => {
        if (response.Success) {
          this.alertify.success("Carnicería creada exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(butchery: ButcheryVM) {
    return this.http.put(this.butcheryApi, butchery).pipe(
      map((response: OperationResult<ButcheryVM>) => {
        if (response.Success) {
          this.alertify.success("Carnicería editada exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.butcheryApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Carnicería eliminada exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  activate(butchery: ButcheryVM) {
    const uri = `${this.butcheryApi}/ActivateRecord`;
    return this.http.put(uri, butchery).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Carnicería activada exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.butcheryApi).pipe(
      map((response: OperationResult<ButcheryVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getDeleted() {
    const uri = `${this.butcheryApi}/GetDeleted`;;
    return this.http.get(uri).pipe(
      map((response: OperationResult<ButcheryVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.butcheryApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<ButcheryVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getByUserId(userId: string) {
    const uri = `${this.butcheryApi}/GetByUserId?userId=${userId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<ButcheryVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

}

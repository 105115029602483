import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { EmployeeVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-employee-selector',
  templateUrl: './employee-selector.component.html',
  styleUrls: ['./employee-selector.component.scss']
})
export class EmployeeSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un empleado...";
  items: EmployeeVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: EmployeeVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onEmployeeSelect: EventEmitter<EmployeeVM> = new EventEmitter<EmployeeVM>();

  constructor(private employeeService: EmployeeService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.employeeService.getAll()
      .subscribe((response: EmployeeVM[]) => {
        if (response) {
          response.forEach(item => {
            item.FullName = this.setFullName(item);
          });
          this.items = response;
        }
      });
  }

  setFullName(employee: EmployeeVM): string {
    return `${employee.Name} ${employee.FirstLastName} ${employee.SecondLastName}`;
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = this.selectedValue.FullName;
    this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    this.onEmployeeSelect.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectedItem = this.selectedValue.FullName;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedValue = this.items.find(item => item.FullName == name);
      this.selectedItem = this.selectedValue.FullName;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public getEmployeeName(): string {
    if (this.selectedValue)
      return this.selectedValue.FullName;

    return null;
  }

  public getEmployeeId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
    this.selectedItem = "";
  }

  openDropDown() {

  }


}

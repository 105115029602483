import { Component, OnInit } from '@angular/core';
import { SaleCutVM } from '../../models/ViewModels';

@Component({
  selector: 'app-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss']
})
export class SimpleGridComponent implements OnInit {

  cutsQuantity: number = 0;
  cutsPrice: number = 0;
  collapse: boolean = true;
  cuts: SaleCutVM[] = [];
  showHeader: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}

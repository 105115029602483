
<nav class="sidebar"
     [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
  <div class="list-group">

    <a routerLink="/home/cages"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Jaulas
    </a>

    <a routerLink="/home/farmers"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Ganaderos
    </a>

    <a routerLink="/home/locations"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Ubicaciones
    </a>

    <a routerLink="/home/customers"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Clientes
    </a>

    <a routerLink="/home/credits"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Créditos
    </a>

    <!-- Sales -->
    <div class="nested-menu">
      <a class="list-group-item"
         (click)="addExpandClass_sales('sales')">
        Ventas
        &nbsp;
        <i class="fa fa-angle-down pull-right"
           *ngIf="salesCollapsed"></i>
        <i class="fa fa-angle-up pull-right"
           *ngIf="!salesCollapsed"></i>
      </a>
      <li class="nested"
          [class.expand]="showMenu === 'sales'">
        <ul class="submenu">
          <li>
            <a routerLink="/home/animals-sales">
              Reses
            </a>
          </li>
          <li>
            <a routerLink="/home/guts-sales">
              Visceras
            </a>
          </li>
        </ul>
      </li>
    </div>

    <!-- Payments -->
    <div class="nested-menu">
      <a class="list-group-item"
         (click)="addExpandClass_payments('payments')">
        Pagos
        &nbsp;
        <i class="fa fa-angle-down pull-right"
           *ngIf="paymentsCollapsed"></i>
        <i class="fa fa-angle-up pull-right"
           *ngIf="!paymentsCollapsed"></i>
      </a>
      <li class="nested"
          [class.expand]="showMenu === 'payments'">
        <ul class="submenu">
          <li>
            <a routerLink="/home/farmers-pay">
              Pagos a Ganaderos
            </a>
          </li>
          <li>
            <a routerLink="/home/customers-pay">
              Pagos de Clientes
            </a>
          </li>
        </ul>
      </li>
    </div>

    <a routerLink="/home/butcheries"
       [routerLinkActive]="['router-link-active']"
       class="list-group-item">
      Carnicerías
    </a>

    <!-- Employees -->
    <div class="nested-menu">
      <a class="list-group-item"
         (click)="addExpandClass_employees('employees')">
        Empleados
        &nbsp;
        <i class="fa fa-angle-down pull-right"
           *ngIf="employeesCollapsed"></i>
        <i class="fa fa-angle-up pull-right"
           *ngIf="!employeesCollapsed"></i>
      </a>
      <li class="nested"
          [class.expand]="showMenu === 'employees'">
        <ul class="submenu">
          <li>
            <a routerLink="/home/employees">
              Empleados
            </a>
          </li>
          <li>
            <a routerLink="/home/payroll">
              Nómina
            </a>
          </li>
          <li>
            <a routerLink="/home/loans">
              Préstamos
            </a>
          </li>
        </ul>
      </li>
    </div>

    <!-- reports -->
    <div class="nested-menu">
      <a class="list-group-item"
         (click)="addExpandClass_reports('reports')">
        Reportes
        &nbsp;
        <i class="fa fa-angle-down pull-right"
           *ngIf="reportsCollapsed"></i>
        <i class="fa fa-angle-up pull-right"
           *ngIf="!reportsCollapsed"></i>
      </a>
      <li class="nested"
          [class.expand]="showMenu === 'reports'">
        <ul class="submenu">
          <li>
            <a routerLink="/home/cages-report">
              Jaulas
            </a>
          </li>
        </ul>
      </li>
    </div>

    <!-- settings -->
    <div class="nested-menu">
      <a class="list-group-item"
         (click)="addExpandClass('pages')">
        <i class="fa fa-gears"></i> Configuración
        &nbsp;
        <i class="fa fa-angle-down pull-right"
           *ngIf="settingsCollapsed"></i>
        <i class="fa fa-angle-up pull-right"
           *ngIf="!settingsCollapsed"></i>
      </a>
      <li class="nested"
          [class.expand]="showMenu === 'pages'">
        <ul class="submenu">
          <li>
            <a routerLink="/home/users">
              Usuarios
            </a>
          </li>
          <!--<li>
          <a routerLink="/home/roles">
            Roles
          </a>
        </li>-->
          <li>
            <a routerLink="/home/change-password">
              Cambiar Contraseña
            </a>
          </li>
          <li>
            <a routerLink="/home/deleted-records">
              Registros eliminados
            </a>
          </li>
          <li>
            <a (click)="logout()"
               style="cursor:pointer;">
              Cerrar Sesión
            </a>
          </li>
        </ul>
      </li>
    </div>
  </div>
  <div class="toggle-button"
       aria-label="Ocultar Menu"
       [ngClass]="{collapsed: collapsed}"
       (click)="toggleCollapsed()">
    <i class="fa fa-fw fa-angle-double-{{collapsed?'right':'left'}}"></i>&nbsp;
    <!--<span class="sr-only">Ocultar Menu</span>-->
    <span>Ocultar Menu</span>
  </div>
</nav>

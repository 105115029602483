
<div [hidden]="showForm">
  <h4>
    Jaulas
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Jaula</h5>
      <h5 *ngIf="isEdition">Editar Jaula {{cageVM.CageInfo}}</h5>

      <hr />
      <br />

      <form [formGroup]="cageForm">

        <app-date-picker #datePicker
                         (dayOfTheWeek)="getDayOfTheWeek($event)">
        </app-date-picker>

        <app-cage-identifier-selector #identifierSelector></app-cage-identifier-selector>

        <igx-input-group>
          <input igxInput name="NumberOfHeads"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': cageForm.get('NumberOfHeads').errors && cageForm.get('NumberOfHeads').touched}"
                 type="number"
                 aria-label="Número de Cabezas"
                 formControlName="NumberOfHeads" />
          <label igxLabel for="NumberOfHeads">Número de Cabezas</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="Weight"
                 [ngClass]="{'is-invalid': cageForm.get('Weight').errors && cageForm.get('Weight').touched}"
                 type="number"
                 aria-label="Peso en KG"
                 formControlName="Weight" />
          <label igxLabel for="Weight">Peso</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="KiloCost"
                 [ngClass]="{'is-invalid': cageForm.get('KiloCost').errors && cageForm.get('KiloCost').touched}"
                 type="number"
                 aria-label="Precio por kilo"
                 formControlName="KiloCost" />
          <label igxLabel for="KiloCost">Precio por KG</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="FreightCost"
                 [ngClass]="{'is-invalid': cageForm.get('FreightCost').errors && cageForm.get('FreightCost').touched}"
                 type="number"
                 aria-label="Precio de Flete"
                 formControlName="FreightCost" />
          <label igxLabel for="FreightCost">Flete</label>
        </igx-input-group>

        <p class="form-control d-flex justify-content-between">
          <b>Costo Total:</b>
          <b>
            {{ totalCost | currency }}
          </b>
        </p>

        <app-farmer-selector #farmerSelector></app-farmer-selector>
        <br />
        <igx-input-group>
          <input igxInput name="SkinPrice"
                 type="number"
                 aria-label="Precio de Piel"
                 formControlName="SkinPrice" />
          <label igxLabel for="SkinPrice">Precio de Piel</label>
        </igx-input-group>

        <div class="row">
          <div class="col-3">
            <igx-input-group>
              <input igxInput name="SkinWeight1"
                     type="number"
                     aria-label="Peso de Piel 1"
                     formControlName="SkinWeight1" />
              <label igxLabel for="SkinWeight1">Peso de Piel 1</label>
            </igx-input-group>
          </div>
          <div class="col-3">
            <igx-input-group>
              <input igxInput name="SkinWeight2"
                     type="number"
                     aria-label="Peso de Piel 2"
                     formControlName="SkinWeight2" />
              <label igxLabel for="SkinWeight2">Peso de Piel 2</label>
            </igx-input-group>
          </div>
          <div class="col-3">
            <igx-input-group>
              <input igxInput name="SkinWeight3"
                     type="number"
                     aria-label="Peso de Piel 3"
                     formControlName="SkinWeight3" />
              <label igxLabel for="SkinWeight3">Peso de Piel 3</label>
            </igx-input-group>
          </div>
          <div class="col-3">
            <igx-input-group>
              <input igxInput name="SkinWeight4"
                     type="number"
                     aria-label="Peso de Piel 4"
                     formControlName="SkinWeight4" />
              <label igxLabel for="SkinWeight4">Peso de Piel 4</label>
            </igx-input-group>
          </div>
        </div>

        <p class="form-control d-flex justify-content-between">
          <b>Peso de Piel:</b>
          <b>
            {{ totalSkin | number }} Kg
          </b>
        </p>

        <p class="form-control d-flex justify-content-between">
          <b>Total de Piel:</b>
          <b>
            {{ totalSkinPrice | currency }}
          </b>
        </p>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

import { Injectable } from '@angular/core';
import { AlertifyService } from '../services/alertify.service';
import { OperationResult } from '../models/ViewModels';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private alertify: AlertifyService) { }

  logerrorMessage(error: OperationResult<any>) {
    if (error.StatusCode == 404) {
      this.alertify.warning("No hay resultados");
    } else if (error.StatusCode == 409) {
      this.alertify.warning("Registro duplicado");
    } else {
      console.log(error);
    }
    }

  }

import { Component, OnInit, ViewChild } from '@angular/core';
import { RolVM } from '../../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { RolService } from '../../../services/rol.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  roles: RolVM[] = [];
  rolVm: RolVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  rolForm: FormGroup;

  constructor(private rolService: RolService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private notify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllRoles();

  }

  initForm() {
    this.rolForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(3)]],
      Name: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.rolForm.valid;
  }

  getAllRoles() {
    this.loading.show();
    this.rolService.getAll()
      .subscribe((response: RolVM[]) => {
        if (response) {
          this.roles = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        alert("error");
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.roles);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Roles";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newRol: RolVM = Object.assign(this.rolForm.value);
      this.rolService.create(newRol)
        .subscribe((response: RolVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(rol: RolVM) {
    this.rolVm = rol;
    this.rolForm.setValue({
      Code: this.rolVm.Code,
      Name: this.rolVm.Name,
    });
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedRol: RolVM = Object.assign({
        UUID: this.rolVm.UUID
      }, this.rolForm.value);

      this.rolService.update(updatedRol)
        .subscribe((response: RolVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedRol);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(rol: RolVM) {
    this.notify.confirm(
      `Eliminar rol: ${rol.Name}`,
      () => {
        this.loading.show();
        this.rolService.delete(rol.Code)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(rol.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

}

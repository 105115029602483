
<div [hidden]="showForm || !hideList">
  <h4>
    Ubicaciones
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Ubicación</h5>
      <h5 *ngIf="isEdition">Editar Ubicación</h5>

      <hr />
      <br />

      <form [formGroup]="locationForm">

        <igx-input-group>
          <input igxInput name="Alias"
                 [igxFocus]="showForm && !isEdition"
                 [readOnly]="isEdition"
                 [ngClass]="{'is-invalid': locationForm.get('Alias').errors && locationForm.get('Alias').touched}"
                 type="text"
                 aria-label="Alias"
                 formControlName="Alias" />
          <label igxLabel for="Alias">Alias</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="Address1"
                 [igxFocus]="showForm && isEdition"
                 type="text"
                 aria-label="Domicilio 1"
                 formControlName="Address1" />
          <label igxLabel for="Address1">Domicilio 1</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="Address2"
                 type="text"
                 aria-label="Domicilio 2"
                 formControlName="Address2" />
          <label igxLabel for="Address2">Domicilio 2</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="ZipCode"
                 type="text"
                 maxlength="5"
                 aria-label="Código Postal"
                 formControlName="ZipCode" />
          <label igxLabel for="ZipCode">Código Postal</label>
        </igx-input-group>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <igx-input-group>
              <input igxInput name="City"
                     type="text"
                     aria-label="Ciudad"
                     formControlName="City" />
              <label igxLabel for="City">Ciudad</label>
            </igx-input-group>
          </div>

          <div class="col-md-6 col-sm-12">
            <app-state-selector #stateSelector></app-state-selector>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <igx-input-group>
              <input igxInput name="Latitude"
                     type="number"
                     aria-label="Latitud"
                     formControlName="Latitude" />
              <label igxLabel for="Latitude">Latitud</label>
            </igx-input-group>
          </div>

          <div class="col-md-6 col-sm-12">
            <igx-input-group>
              <input igxInput name="Longitude"
                     type="number"
                     aria-label="Longitud"
                     formControlName="Longitude" />
              <label igxLabel for="Longitude">Longitud</label>
            </igx-input-group>
          </div>
        </div>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

<div [hidden]="hideList" class="row">
  <div class="col-md-10 col-sm-12 mx-auto">
    <h5>
      Medias en {{locationAlias}}
    </h5>

    <button class="btn btn-secondary"
            (click)="hideList=!hideList">
      Cerrar
    </button>

    <hr />

    <div [hidden]="animals.length==0">
      <app-animals-details #animalsList></app-animals-details>
    </div>

    <p class="text-center" [hidden]="animals.length>0">
      No hay resultados
    </p>
  </div>
</div>


  <h4>
    Reporte de Jaulas
  </h4>

<button class="btn btn-primary"
        *ngIf="cageSelected"
        (click)="cageSelected=false">
  Cambiar de Jaula
</button>

  <hr />

  <div [hidden]="cageSelected">
    <app-cage-selector #cageSelector
                       (onItemSelected)="onCageSelected($event)">

    </app-cage-selector>
  </div>

  <div [hidden]="!cageSelected">


    <app-header-details #headerDetails></app-header-details>

    <br />

    <app-generic-grid #grid>
    </app-generic-grid>
  </div>

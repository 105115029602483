import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  public showGrid: boolean = false;

  public columns: any[] = [];
  public items: any[] = [];
  public bindingType: String = 'array';
  public view: Observable<GridDataResult>;
  public gridData: any;

  public gridDataResult: GridDataResult;

  constructor() { }

  ngOnInit(): void {
  }

  initGrid() {
    this.gridDataResult = {
      data: this.items,
      total: this.items.length
    };
    this.showGrid = true;
  }

}

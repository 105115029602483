import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../components/loading/loading.service';
import { PayrollHeaderVM } from '../../../models/ViewModels';
import { AlertifyService } from '../../../services/alertify.service';
import { PayrollService } from '../../../services/payroll.service';
import { PayrollFormComponent } from './payroll-form/payroll-form.component';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  payrolls: PayrollHeaderVM[] = [];
  payrollHeaderVM: PayrollHeaderVM;

  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("payrollForm", { read: PayrollFormComponent })
  public payrollForm: PayrollFormComponent;

  constructor(private payrollService: PayrollService,
    private loading: LoadingService,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.getAllPayrolls();
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  getAllPayrolls() {
    this.loading.show();
    this.payrollService.getAll()
      .subscribe((response: PayrollHeaderVM[]) => {
        if (response) {
          this.payrolls = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.payrolls);
    this.grid.options.toolbarTitle = "Nómina";
    this.grid.options.showDetailsButton = true;
    this.grid.options.showEditButton = false;
    this.grid.columns = [
      { field: "ButcheryName", title: "Carnicería" },
      { field: "StartDate", title: "Fecha Inicial", dateType: true },
      { field: "EndDate", title: "Fecha Final", dateType: true },
      { field: "Total", title: "Total", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.setFormVisibility();
  }

  save(value: any) {
    this.loading.show();
    const newPayroll: PayrollHeaderVM = {
      ButcheryId: this.payrollForm.butcherySelector.getButcheryId(),
      StartDate: this.payrollForm.datesSelector.getFormValues().StartDate,
      EndDate: this.payrollForm.datesSelector.getFormValues().EndDate,
      Total: 0,
      Payrolls: this.payrollForm.view
    };

    this.payrollService.create(newPayroll)
      .subscribe((response: PayrollHeaderVM) => {
        if (response) {
          this.grid.addRow(response);
          this.payrollForm.cancel();
          this.showForm = false;
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  // Grid events
  edit(payroll: PayrollHeaderVM) {
    this.payrollHeaderVM = payroll;
    this.setFormVisibility();
  }

  delete(payroll: PayrollHeaderVM) {
    this.alertify.confirm(
      `Eliminar nómina`,
      () => {
        this.loading.show();
        this.payrollService.delete(payroll.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(payroll.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  select(payroll: PayrollHeaderVM) {
    this.loading.show();
    this.payrollService.getById(payroll.UUID)
      .subscribe((response: PayrollHeaderVM) => {
        if (response) {
          this.payrollHeaderVM = response;
          this.payrollForm.payrollHeader.headerItems = [
            { title: "Carnicería", value: this.payrollHeaderVM.ButcheryName },
            { title: "Fecha Inicial", value: this.payrollHeaderVM.StartDate, isDate: true },
            { title: "Fecha Final", value: this.payrollHeaderVM.EndDate, isDate: true },
            { title: "TOTAL", value: this.payrollHeaderVM.Total, isCurrency: true },
          ];
          this.payrollForm.payrollHeader.showHeader = true;

          this.payrollForm.records = this.payrollHeaderVM.Payrolls;
          this.payrollForm.records.forEach(item => {
            item.Employee.FullName = this.payrollForm.setFullName(item.Employee);
          });
          this.payrollForm.view = this.payrollForm.records;
          this.payrollForm.showForm = false;
          this.payrollForm.showGrid = true;
          this.payrollForm.viewDetails = true;
          this.payrollForm.showDetails = true;
          this.showForm = true;
          this.loading.hide();

        }
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

}

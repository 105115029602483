import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginRequestVM } from '../../../models/ViewModels';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { LocalData } from '../../../models/local.data';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  defaultPage: string;

  constructor(private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder) {

    if (this.authService.SessionUserValue()) {
      this.setDefaultPage(this.authService.SessionUserValue().Rol);
      this.router.navigate([this.returnUrl]);
    }

  }

  ngOnInit(): void {
    this.initForm();
    //this.returnUrl = this.route
    //  .snapshot.queryParams['returnUrl'] || '/home/cages';
  }

  initForm() {
    this.loginForm = this.fb.group({
      User: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  isValidForm(): boolean {
    return this.loginForm.valid;
  }

  login() {
    if (this.isValidForm()) {
      const userInfo: LoginRequestVM = Object.assign(this.loginForm.value);
      this.authService.login(userInfo)
        .pipe(first())
        .subscribe(response => {
          this.setDefaultPage(response.Rol);
          this.router.navigate([this.returnUrl]);
        }, error => {
            console.log(error);
        });
    } 
  }

  setDefaultPage(rol: string) {
    switch (rol) {
      case LocalData.ADMIN_ROL: this.returnUrl = this.route
        .snapshot.queryParams['returnUrl'] || '/home/cages';
        break;
      case LocalData.CHECK_ROL: this.returnUrl = this.route
        .snapshot.queryParams['returnUrl'] || '/check/butchery-selector';
        break;
      default: this.defaultPage = null;;
        break;
    }
    
  }



}

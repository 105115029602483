import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { AlertifyService } from '../../../../services/alertify.service';
import { EmployeeVM } from '../../../../models/ViewModels';
import { EmployeeService } from '../../../../services/employee.service';

@Component({
  selector: 'app-employees-records',
  templateUrl: './employees-records.component.html',
  styleUrls: ['./employees-records.component.scss']
})
export class EmployeesRecordsComponent implements OnInit {

  employees: EmployeeVM[] = [];
  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private employeeService: EmployeeService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.loading.show();
    this.employeeService.getDeleted()
      .subscribe((response: EmployeeVM[]) => {
        if (response) {
          this.employees = response;
        }
        this.employees.forEach(item => {
          item.FullName = this.setFullName(item);
        });
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.employees);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showActivateButton = true;
    this.grid.options.showExportExcelButton = false;
    this.grid.options.showExportPDFButton = false;
    this.grid.columns = [
      { field: "FullName", title: "Nombre" },
      { field: "EmployeeNumber", title: "No. Empleado" },
      { field: "ButcheryName", title: "Carnicería" },
    ];
    this.grid.setGridOptions();
  }

  activate(employee: EmployeeVM) {
    this.alertify.confirm(
      `Activar empleado: ${employee.Name}`,
      () => {
        this.loading.show();
        this.employeeService.activate(employee)
          .subscribe((response: boolean) => {
            if (Response) {
              this.grid.deleteRow(employee.UUID);
            }
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  setFullName(employee: EmployeeVM): string {
    return `${employee.Name} ${employee.FirstLastName} ${employee.SecondLastName}`;
  }

}

import { Component, OnInit } from '@angular/core';
import { SaleAnimalsVM, SaleGutsVM } from '../../models/ViewModels';
import { LoadingService } from '../loading/loading.service';
import { SaleAnimalsService } from '../../services/sale-animals.service';
import { SaleGutsService } from '../../services/sale-guts.service';

@Component({
  selector: 'app-multi-sales-selector',
  templateUrl: './multi-sales-selector.component.html',
  styleUrls: ['./multi-sales-selector.component.scss']
})
export class MultiSalesSelectorComponent implements OnInit {

  componentTitle: string;

  sales: any[] = [];
  disabled: boolean = true;
  collapse: boolean = true;
  selectedIds: string[] = [];
  selectedSales: number = 0;

  constructor(private saleAnimalsService: SaleAnimalsService,
    private saleGutsService: SaleGutsService,
    private loading: LoadingService) { }

  ngOnInit(): void {
  }

  getCustomerAnimalSales(customerId: string) {
    this.loading.show();
    this.saleAnimalsService.getCustomerSales(customerId)
      .subscribe((response: SaleAnimalsVM[]) => {
        if (response) {
          this.sales = response;
          this.disabled = false;
        }
        this.loading.hide();
      });
  }

  getCustomerGutsSales(customerId: string) {
    this.loading.show();
    this.saleGutsService.getCustomerSales(customerId)
      .subscribe((response: SaleGutsVM[]) => {
        if (response) {
          this.sales = response;
          this.disabled = false;
        }
        this.loading.hide();
      });
  }

  selectItem(item: SaleAnimalsVM) {
    item.IsSelected = !item.IsSelected;
    this.selectedSales = this.sales.filter(item => item.IsSelected == true).length;
  }

  getIdsList(): string[] {
    this.sales.filter(item => item.IsSelected == true).forEach(sale => {
      this.selectedIds.push(sale.UUID);
    });
    return this.selectedIds;
  }

  setSelectedItems(customerId: string, idsList: string[]) {
    this.loading.show();
    this.saleAnimalsService.getCustomerSales(customerId)
      .subscribe((response: SaleAnimalsVM[]) => {
        if (response) {
          this.sales = response;
          idsList.forEach(item => {
            this.sales.find(s => s.UUID == item).IsSelected = true;
          });
          this.selectedSales = this.sales.filter(item => item.IsSelected == true).length;
          this.disabled = false;
        }
        this.loading.hide();
      });
  }

  reset() {
    this.selectedSales = 0;
    this.disabled = true;
    this.selectedIds = [];
    this.sales = [];
  }



}

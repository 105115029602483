<nav class="navbar navbar-expand-lg fixed-top">
  <b class="navbar-brand">
    RT Management
  </b>
  <button class="navbar-toggler"
          type="button"
          (click)="toggleSidebar()">
    <!-- <span class="navbar-toggler-icon"></span> -->
    <i class="fa fa-bars text-muted" aria-hidden="true"></i>
  </button>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto"></ul>
  </div>
</nav>

import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { AlertifyService } from '../../../../services/alertify.service';
import { FarmerVM } from '../../../../models/ViewModels';
import { FarmerService } from '../../../../services/farmer.service';

@Component({
  selector: 'app-farmers-records',
  templateUrl: './farmers-records.component.html',
  styleUrls: ['./farmers-records.component.scss']
})
export class FarmersRecordsComponent implements OnInit {

  farmers: FarmerVM[] = [];
  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private farmerService: FarmerService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.getFarmers();
  }

  getFarmers() {
    this.loading.show();
    this.farmerService.getDeleted()
      .subscribe((response: FarmerVM[]) => {
        if (response) {
          this.farmers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.farmers);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showActivateButton = true;
    this.grid.options.showExportExcelButton = false;
    this.grid.options.showExportPDFButton = false;
    this.grid.columns = [
      { field: "Name", title: "Nombre" },
      { field: "StateName", title: "Estado" },
      { field: "Balance", title: "Saldo", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  activate(farmer: FarmerVM) {
    this.alertify.confirm(
      `Activar ganadero: ${farmer.Name}`,
      () => {
        this.loading.show();
        this.farmerService.activate(farmer)
          .subscribe((response: boolean) => {
            if (Response) {
              this.grid.deleteRow(farmer.UUID);
            }
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

}

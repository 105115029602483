import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { CustomerVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { StateSelectorComponent } from '../../../components/state-selector/state-selector.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  customers: CustomerVM[] = [];
  customerVM: CustomerVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  customerForm: FormGroup;

  @ViewChild("stateSelector", { read: StateSelectorComponent })
  public stateSelector: StateSelectorComponent;

  collapseTelephoneForm: boolean = true;
  collapseAddressForm: boolean = true;

  constructor(private customerService: CustomerService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllCustomers();
  }

  initForm() {
    this.customerForm = this.fb.group({
      FullName: ['', [Validators.required, Validators.minLength(3)]],
      Telephone: ['', [Validators.maxLength(10)]],
      MobilePhone: ['', [Validators.maxLength(10)]],
      Email: [''],
      Address1: [''],
      Address2: [''],
      ZipCode: [''],
      City: [''],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.customerForm.valid;
  }

  getAllCustomers() {
    this.loading.show();
    this.customerService.getAll()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          this.customers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.customers);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "FullName", title: "Nombre" },
      { field: "TotalPurchases", title: "Total Compras", currencyType: true },
      { field: "TotalPayments", title: "Total Pagos", currencyType: true },
      { field: "Balance", title: "Saldo", currencyType: true },
      { field: "LastPayment", title: "Último Pago", dateType: true },
      //{ field: "StateName", title: "Estado" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.collapseAddressForm = true;
    this.collapseTelephoneForm = true;
    this.stateSelector.resetForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newCustomer: CustomerVM = Object.assign({
        StateId: this.stateSelector.getStateId(),
        StateName: this.stateSelector.getStateName(),
        HasCredit: false,
        BalanceAlert: 0,
        Balance: 0,
        PurchasedAnimals: 0,
        PurchasedGuts: 0,
        TotalPurchases: 0,
        TotalPayments: 0,
        LastPayment: new Date("2000-01-01T00:00:00")
        }, this.customerForm.value);
      this.customerService.create(newCustomer)
        .subscribe((response: CustomerVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(customer: CustomerVM) {
    this.customerVM = customer;
    this.customerForm.setValue({
      FullName: this.customerVM.FullName,
      Telephone: this.customerVM.Telephone,
      MobilePhone: this.customerVM.MobilePhone,
      Email: this.customerVM.Email,
      Address1: this.customerVM.Address1,
      Address2: this.customerVM.Address2,
      ZipCode: this.customerVM.ZipCode,
      City: this.customerVM.City,
    });

    this.stateSelector.setComboByName(this.customerVM.StateName);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedCustomer: CustomerVM = Object.assign({
        UUID: this.customerVM.UUID,
        StateId: this.stateSelector.getStateId(),
        StateName: this.stateSelector.getStateName(),
        HasCredit: this.customerVM.HasCredit,
        BalanceAlert: this.customerVM.BalanceAlert,
        Balance: this.customerVM.Balance,
        PurchasedAnimals: this.customerVM.PurchasedAnimals,
        PurchasedGuts: this.customerVM.PurchasedGuts,
        TotalPurchases: this.customerVM.TotalPurchases,
        TotalPayments: this.customerVM.TotalPayments,
        LastPayment: this.customerVM.LastPayment,
      }, this.customerForm.value);

      this.customerService.update(updatedCustomer)
        .subscribe((response: CustomerVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedCustomer);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(customer: CustomerVM) {
    this.alertify.confirm(
      `Eliminar cliente: ${customer.FullName}`,
      () => {
        this.loading.show();
        this.customerService.delete(customer.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(customer.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

}

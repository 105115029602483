import { Component, OnInit } from '@angular/core';
import { ButcheryService } from '../../../services/butchery.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../components/loading/loading.service';
import { ButcheryVM, LoginResponse } from '../../../models/ViewModels';
import { LocalData } from '../../../models/local.data';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit {

  user: any;
  hasResult: boolean = false;

  constructor(private butcheryService: ButcheryService,
    private router: Router,
    private loading: LoadingService) {
    this.user = JSON.parse(localStorage.getItem('sessionUser'));
  }

  ngOnInit(): void {
    this.getButchery();
  }

  getButchery() {
    this.loading.show();
    this.butcheryService.getByUserId(this.user.uuid)
      .subscribe((response: ButcheryVM) => {
        if (response) {
          this.hasResult = true;
          this.selectButchery(response);
        }
        this.loading.hide();
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

  selectButchery(butchery: ButcheryVM) {
    localStorage.setItem(LocalData.SELECTED_BUTCHERY_UUID, butchery.UUID);
    localStorage.setItem(LocalData.SELECTED_BUTCHERY_NAME, butchery.Name);
    this.router.navigate(['check/register']);
  }

}


<div class="text-right">
  <button class="btn btn-secondary"
          (click)="showGrid=!showGrid">
    <span *ngIf="!showGrid">
      Ver Tabla
    </span>
    <span *ngIf="showGrid">
      Ver Cuadrícula
    </span>
  </button>
</div>

<br />

<div [hidden]="showGrid">
  <div class="row" *ngIf="animals.length>0">
    <div class="col-md-3 col-sm-12 __animal"
         *ngFor="let animal of animals"
         (click)="itemClick(animal)">
      <div class="text-center font-weight-bolder">
        {{ animal.AnimalInfo }}
      </div>
      <!-- id -->
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="locationDetails">
          <small>Jaula</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.CageIdentifier }}{{ animal.CageId }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="locationDetails">
          <small>ID</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.Id }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <small>Peso</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.Weight | number }} KG
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails">
          <small>Ubicación</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.LocationAlias }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails && !animal.IsForCut">
          <small>Vendido</small>
          <span class="badge badge-primary badge-pill"
                *ngIf="animal.Sold">
            Si
          </span>
          <span class="badge badge-primary badge-pill"
                *ngIf="!animal.Sold">
            No
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails && animal.IsForCut">
          <small>Enviado a Corte</small>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="animal.Sold && !locationDetails && !animal.IsForCut">
          <small>Vendido a</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.CustomerName}}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="animal.Sold && !locationDetails"
            style="cursor:pointer;"
            (click)="editDate(animal)">
          <small *ngIf="!animal.IsForCut">Fecha de Venta</small>
          <small *ngIf="animal.IsForCut">Fecha</small>
          <span class="badge badge-primary badge-pill">
            {{ animal.SaleDate | date:'dd/MM/yyyy'}}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails && !animal.IsForCut && !animal.Sold">
          <button class="btn btn-secondary"
                  (click)="confirmSendToCut(animal)">
            Enviar a Corte
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<div [hidden]="!showGrid">
  <app-generic-grid #grid></app-generic-grid>
</div>


<!-- Send to cut modal dialog -->
<igx-dialog #form_sendToCut
            [isModal]="true"
            [closeOnOutsideSelect]="true">
  <igx-dialog-title>
    <div class="dialog-container">
      <div class="dialog-title">{{ dialogTitle }}</div>
    </div>
  </igx-dialog-title>

  <div *ngIf="animalVM"
       class="row">
    <div class="col">Animal:</div>
    <div class="col-8">
      <b>{{ animalVM.AnimalInfo || '' }}</b>
    </div>
  </div>

  <div class="row">
    <div class="col">
      Fecha:
    </div>
    <div class="col-8">
      <app-date-picker #dateSelector></app-date-picker>
    </div>
  </div>
  <div class="row">
    <div class="col">
      Peso:
    </div>
    <div class="col-8">
      <igx-input-group>
        <input igxInput name="animalWeight"
               type="number"
               aria-label="Peso"
               [(ngModel)]="animalWeight" />
        <label igxLabel for="animalWeight">Peso</label>
      </igx-input-group>
    </div>
  </div>

  <br />
  <hr />

  <div igxDialogActions>
    <button class="btn btn-outline-primary"
            [disabled]="!isSendToCutValid()"
            (click)="sendAnimalToCut()">
      Aceptar
    </button>
    <button class="btn btn-outline-secondary"
            (click)="closeDialog()">
      Cancelar
    </button>
  </div>
</igx-dialog>

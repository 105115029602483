import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { CustomerPayVM, SaleAnimalsVM, CustomerVM, SaleGutsVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { PaymentMethodSelectorComponent } from '../../../components/payment-method-selector/payment-method-selector.component';
import { CustomerPayService } from '../../../services/customer-pay.service';
import { MultiSalesSelectorComponent } from '../../../components/multi-sales-selector/multi-sales-selector.component';
import { ConfirmDeleteComponent } from '../../../components/confirm-delete/confirm-delete.component';
import { ListDetailsComponent } from '../../../components/list-details/list-details.component';
import { SearchCustomerComponent } from '../../../components/search-customer/search-customer.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';

@Component({
  selector: 'app-customer-pay',
  templateUrl: './customer-pay.component.html',
  styleUrls: ['./customer-pay.component.scss']
})
export class CustomerPayComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  pays: CustomerPayVM[] = [];
  payVM: CustomerPayVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  payForm: FormGroup;

  @ViewChild("paymentMethodSelector", { read: PaymentMethodSelectorComponent, static: true })
  public paymentMethodSelector: PaymentMethodSelectorComponent;

  @ViewChild("customerSelector", { read: SearchCustomerComponent, static: true })
  public customerSelector: SearchCustomerComponent;

  //@ViewChild("animalsalesSelector", { read: MultiSalesSelectorComponent, static: true })
  //public animalsalesSelector: MultiSalesSelectorComponent;

  //@ViewChild("gutsSalesSelector", { read: MultiSalesSelectorComponent, static: true })
  //public gutsSalesSelector: MultiSalesSelectorComponent;

  @ViewChild("confirmDeleteCmp", { read: ConfirmDeleteComponent, static: true })
  public confirmDeleteCmp: ConfirmDeleteComponent;
  showDelete: boolean = false;

  showDetails: boolean = false;
  @ViewChild("payDetails", { read: ListDetailsComponent, static: true })
  public payDetails: ListDetailsComponent;

  //@ViewChild("salesList", { read: ListDetailsComponent, static: true })
  //public salesList: ListDetailsComponent;

  //payAnimalsSales: SaleAnimalsVM[] = [];
  //payGutsSales: SaleGutsVM[] = [];

  @ViewChild("dateSelector", { read: DatePickerComponent, static: true })
  public dateSelector: DatePickerComponent;

  constructor(private payService: CustomerPayService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.customerSelector.showBalance = true;
    this.getAllPays();

  }

  initForm() {
    this.payForm = this.fb.group({
      Amount: [0, [Validators.required]],
      PaymentReference: [''],
      GetPaidName: [''],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  selectCustomer(customer: CustomerVM) {
    //this.animalsalesSelector.getCustomerAnimalSales(customer.UUID);
    //this.gutsSalesSelector.getCustomerGutsSales(customer.UUID);
  }

  isValidForm(): boolean {
    return this.payForm.valid
      && this.paymentMethodSelector.selectorForm.valid
      && this.dateSelector.dateForm.valid
      && this.customerSelector.isValidForm();
      //&& (this.animalsalesSelector.selectedSales > 0 || this.gutsSalesSelector.selectedSales > 0);
  }

  getAllPays() {
    this.loading.show();
    this.payService.getAll()
      .subscribe((response: CustomerPayVM[]) => {
        if (response) {
          this.pays = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.pays);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Pagos de Clientes";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "CustomerName", title: "Cliente" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "PaymentMethodName", title: "Método de Pago" },
      { field: "PaymentReference", title: "Referencia" },
      { field: "GetPaidName", title: "Recibió Pago" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.paymentMethodSelector.resetForm();
    this.customerSelector.resetForm();
    this.dateSelector.resetForm();
    //this.animalsalesSelector.reset();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newPay: CustomerPayVM = Object.assign({
        CreateDate: this.dateSelector.getFormValue().CustomDate,
        CustomerId: this.customerSelector.getCustomerId(),
        CustomerName: this.customerSelector.getCustomerName(),
        PaymentMethodId: this.paymentMethodSelector.getItemId(),
        PaymentMethodName: this.paymentMethodSelector.getItemInfo(),
        //ListOfAnimalsSales: this.animalsalesSelector.getIdsList(),
        //ListOfGutsSales: this.gutsSalesSelector.getIdsList(),
      }, this.payForm.value);
      this.payService.create(newPay)
        .subscribe((response: CustomerPayVM) => {
          if (response) {
            this.grid.addRowAtTop(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  select(pay: CustomerPayVM) {
    this.loading.show();
    this.payService.getById(pay.UUID)
      .subscribe((response: CustomerPayVM) => {
        if (response) {
          this.payDetails.detailsTitle = "Detalles";
          this.payDetails.details = [
            { title: "Fecha", value: response.CreateDate, isDate: true },
            { title: "Cliente", value: response.CustomerName },
            { title: "Monto", value: response.Amount, isCurrency: true },
            { title: "Método de Pago", value: response.PaymentMethodName },
            { title: "Referencia", value: response.PaymentReference },
            { title: "Recibió Pago", value: response.GetPaidName },
          ];
          this.payDetails.showItems = true;

          //this.payAnimalsSales = response.AnimalsSales;
          //this.salesList.detailsTitle = "Notas de Venta";
          //this.payAnimalsSales.forEach(sale => {
          //  this.salesList.details.push(
          //    { title: sale.NoteNumber, value: sale.Total, isCurrency: true },
          //  );
          //});

          //this.payGutsSales = response.GutsSales;
          //this.payGutsSales.forEach(sale => {
          //  this.salesList.details.push(
          //    { title: sale.NoteNumber, value: sale.Total, isCurrency: true },
          //  );
          //});

          //this.salesList.showItems = true;

          this.showDetails = true;
          this.loading.hide();
        }
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

  edit(pay: CustomerPayVM) {
    this.payVM = pay;
    this.payForm.setValue({
      Amount: this.payVM.Amount,
      PaymentReference: this.payVM.PaymentReference,
      GetPaidName: this.payVM.GetPaidName,
    });
    this.dateSelector.setDate(new Date(this.payVM.CreateDate));
    this.paymentMethodSelector.setComboById(this.payVM.PaymentMethodId);
    this.customerSelector.setComboById(this.payVM.CustomerId);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedPay: CustomerPayVM = Object.assign({
        UUID: this.payVM.UUID,
        CreateDate: this.dateSelector.getFormValue().CustomDate,
        CustomerId: this.customerSelector.getCustomerId(),
        CustomerName: this.customerSelector.getCustomerName(),
        PaymentMethodId: this.paymentMethodSelector.getItemId(),
        PaymentMethodName: this.paymentMethodSelector.getItemInfo(),
        //ListOfSales: this.animalsalesSelector.getIdsList(),
      }, this.payForm.value);

      this.payService.update(updatedPay)
        .subscribe((response: CustomerPayVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedPay);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(pay: CustomerPayVM) {
    this.payVM = pay;
    this.confirmDeleteCmp.confirmTitle = "Eliminar Pago";
    this.confirmDeleteCmp.confirmText = `Eliminar pago de: ${pay.CustomerName}`;
    this.showDelete = true;
  }

  confirmDelete(removeNotes: string) {
    this.loading.show();
    this.payVM.RemoveNotes = removeNotes;
    this.payService.deletePay(this.payVM)
      .subscribe((response: boolean) => {
        if (response) {
          this.grid.deleteRow(this.payVM.UUID);
          this.confirmDeleteCmp.RemoveNotes = "";
          this.showDelete = false;
          this.customerSelector.loadComboValues();
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  cancelDelete(x) {
    this.showDelete = false;
  }

}

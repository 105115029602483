
<div class="text-right">
  <button class="btn btn-secondary"
          (click)="showGrid=!showGrid">
    <span *ngIf="!showGrid">
      Ver Tabla
    </span>
    <span *ngIf="showGrid">
      Ver Cuadrícula
    </span>
  </button>
</div>

<br />

<div [hidden]="showGrid">
  <div class="row" *ngIf="guts.length>0">
    <div class="col-md-3 col-sm-12 __animal"
         *ngFor="let gut of guts"
         (click)="itemClick(gut)">
      <div class="text-center font-weight-bolder">
        {{ gut.Id }}
      </div>
      <!-- id -->
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="locationDetails">
          <small>Jaula</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.CageIdentifier }}{{ gut.CageId }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="locationDetails">
          <small>ID</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.Id }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <small>Precio</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.Price | currency }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails">
          <small>Ubicación</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.LocationAlias }}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="!locationDetails">
          <small>Vendido</small>
          <span class="badge badge-primary badge-pill"
                *ngIf="gut.Sold">
            Si
          </span>
          <span class="badge badge-primary badge-pill"
                *ngIf="!gut.Sold">
            No
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="gut.Sold && !locationDetails">
          <small>Vendido a</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.CustomerName}}
          </span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngIf="gut.Sold && !locationDetails">
          <small>Fecha de Venta</small>
          <span class="badge badge-primary badge-pill">
            {{ gut.SaleDate | date:'dd/MM/yyyy'}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div [hidden]="!showGrid">
  <app-generic-grid #grid></app-generic-grid>
</div>


<div [hidden]="showForm">
  <h4>
    Ganaderos
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Ganadero</h5>
      <h5 *ngIf="isEdition">Editar Ganadero</h5>

      <hr />
      <br />

      <form [formGroup]="farmerForm">

        <igx-input-group>
          <input igxInput name="Name"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': farmerForm.get('Name').errors && farmerForm.get('Name').touched}"
                 type="text"
                 aria-label="Nombre"
                 formControlName="Name" />
          <label igxLabel for="Name">Nombre</label>
        </igx-input-group>

        <igx-input-group [hidden]="true">
          <input igxInput name="Balance"
                 type="number"
                 aria-label="Saldo"
                 formControlName="Balance" />
          <label igxLabel for="Balance">Saldo</label>
        </igx-input-group>

        <app-state-selector #stateSelector></app-state-selector>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { CageVM } from '../../models/ViewModels';
import { CageService } from '../../services/cage.service';

@Component({
  selector: 'app-cage-selector',
  templateUrl: './cage-selector.component.html',
  styleUrls: ['./cage-selector.component.scss']
})
export class CageSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione una jaula...";
  items: CageVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: CageVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output()
  onItemSelected: EventEmitter<CageVM> = new EventEmitter<CageVM>();

  constructor(private apiService: CageService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.apiService.getAll()
      .subscribe((response: CageVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = `${this.selectedValue.Identifier}${this.selectedValue.Id} (${this.setDateFormat(new Date(this.selectedValue.Date))})`;
    this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    this.onItemSelected.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectedItem = `${this.selectedValue.Identifier}${this.selectedValue.Id} (${this.setDateFormat(new Date(this.selectedValue.Date))})`;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public getItemInfo(): string {
    if (this.selectedValue) {
      this.selectedItem = `${this.selectedValue.Identifier}${this.selectedValue.Id} (${this.setDateFormat(new Date(this.selectedValue.Date))})`;
      return this.selectedItem;
    }
    return null;
  }

  public getItemId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
    this.selectedItem = null;
  }

  openDropDown() {

  }

  setDateFormat(d: Date): string {
    let day: number = d.getDate();
    let month: number = d.getMonth() + 1;
    let year: number = d.getFullYear();

    return `${day}/${month}/${year}`;
  }


}

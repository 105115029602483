import { Pipe, PipeTransform } from '@angular/core';
import { AnimalVM } from '../models/ViewModels';

@Pipe({
  name: 'forCut'
})
export class ForCutPipe implements PipeTransform {

  transform(animals: AnimalVM[], isForCut: boolean): AnimalVM[] {
    if (!animals) {
      return;
    }
    if (!isForCut) {
      return animals;
    }
    return animals.filter(a => a.IsForCut === false);
  }

}

import { Component, OnInit, Input, ViewChild, ViewEncapsulation, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomDateVM } from '../../models/componentInterfaces';
import { AlertifyService } from '../../services/alertify.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() placeHolder: string;  

  dateForm: FormGroup;
  public date: Date = new Date(Date.now());
  private dayFormatter = new Intl.DateTimeFormat("es-MX", { weekday: "long" });
  private monthFormatter = new Intl.DateTimeFormat("es-MX", { month: "long" });
  public customDate: string;
  public customDateVM: CustomDateVM;
  public notBeforeToday: boolean = false;
  public notAfterToday: boolean = false;

  @Output() dayOfTheWeek: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.customDate = null;
    this.customDateVM = null;
    this.initForm();
  }

  initForm() {
    this.dateForm = this.fb.group({
      CustomDate: [null, [Validators.required]],
    });
    this.setDate(new Date());
  }


  //public formatter = (date: Date) => {
  //  // tslint:disable-next-line:max-line-length
  //  return `You selected ${this.dayFormatter.format(date)}, ${date.getDate()} ${this.monthFormatter.format(date)}, ${date.getFullYear()}`;
  //}

  public formatter = (date: Date) => {
    const currentDate = `${this.monthFormater(date.getDate())}/${this.monthFormater(date.getMonth() + 1)}/${date.getFullYear()}`;
    if (currentDate == "1/1/1")
      return null;
    return currentDate;
  }

  setDate(e: Date) {
    if (!this.notBeforeToday) {
      this.customDate = this.formatter(e);
      this.dateForm.patchValue({
        CustomDate: e
      });
      this.dayOfTheWeek.emit(e.getDay());
      this.onDateChanged.emit(e);
    } else {
      if (e < this.date) {
        this.alertify.warning("La fecha no puede ser anterior al día de hoy");
      } else {
        this.customDate = this.formatter(e);
        this.dateForm.patchValue({
          CustomDate: e
        });
      }
    }
  }

  setValue(e: Date) {
    this.customDate = this.formatter(e);
    this.dateForm.patchValue({
      CustomDate: e
    });
  }

  getFormValue(): CustomDateVM {
    this.customDateVM = Object.assign({
      CustomDate: this.dateForm.controls.CustomDate,
    }, this.dateForm.value);
    return this.customDateVM;
  };

  getDate(): Date {
    const controlDate: Date = new Date(this.getFormValue().CustomDate);
    const dateNow: Date = new Date(Date.now());
    // return new date with year, month and day from controlDate variable and with hour, minute and second from this.date variable
    return new Date(controlDate.getFullYear(), controlDate.getMonth(), controlDate.getDate(), dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds(), dateNow.getMilliseconds());
  }

  resetForm() {
    this.customDate = null;
    this.dateForm.reset();
    Object.keys(this.dateForm.controls).forEach(key => {
      this.dateForm.controls[key].setErrors(null);
    });
    this.initForm();
  }

  monthFormater(m: number): string {
    let result: string = "";
    if (m < 10) {
      result = `0${m}`;
    } else {
      result = `${m}`;
    }
    return result;
  }
}


<div [hidden]="showForm || showDelete || showDetails">
  <h4>
    Pagos de Clientes
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Pago</h5>
      <h5 *ngIf="isEdition">Editar Pago</h5>

      <hr />
      <br />

      <form [formGroup]="payForm">

        <app-date-picker #dateSelector></app-date-picker>

        <br />

        <app-search-customer #customerSelector></app-search-customer>

        <!--<br />
    <b>Notas de Venta de Medias</b>
    <app-multi-sales-selector #animalsalesSelector></app-multi-sales-selector>-->
        <!--<br />
    <b>Notas de Venta de Visceras</b>
    <app-multi-sales-selector #gutsSalesSelector></app-multi-sales-selector>-->

        <igx-input-group>
          <input igxInput name="Amount"
                 [ngClass]="{'is-invalid': payForm.get('Amount').errors && payForm.get('Amount').touched}"
                 type="number"
                 aria-label="Monto"
                 formControlName="Amount" />
          <label igxLabel for="Amount">Monto</label>
        </igx-input-group>

        <app-payment-method-selector #paymentMethodSelector></app-payment-method-selector>

        <igx-input-group>
          <input igxInput name="PaymentReference"
                 type="text"
                 aria-label="Número de Referencia"
                 formControlName="PaymentReference" />
          <label igxLabel for="PaymentReference">No. Referencia</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="GetPaidName"
                 type="text"
                 aria-label="Recibió el Pago"
                 formControlName="GetPaidName" />
          <label igxLabel for="GetPaidName">Recibió el Pago</label>
        </igx-input-group>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

<app-confirm-delete #confirmDeleteCmp
                    [hidden]="!showDelete"
                    (onDelete)="confirmDelete($event)"
                    (onCancel)="cancelDelete($event)">
</app-confirm-delete>

<div [hidden]="!showDetails">
  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <h5>Detalles de Pago</h5>

      <button class="btn btn-secondary"
              (click)="showDetails=false">
        Cerrar
      </button>

      <hr />
      <br />
      <app-list-details #payDetails></app-list-details>
      <app-list-details #salesList></app-list-details>
    </div>
  </div>
</div>

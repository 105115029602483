import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { ChangeEmployeePassword, EmployeeVM, OperationResult } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  employeeApi = `${environment.apiUrl}/Employee`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(employee: EmployeeVM) {
    return this.http.post(this.employeeApi, employee).pipe(
      map((response: OperationResult<EmployeeVM>) => {
        if (response.Success) {
          this.alertify.success("Empleado creado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(employee: EmployeeVM) {
    return this.http.put(this.employeeApi, employee).pipe(
      map((response: OperationResult<EmployeeVM>) => {
        if (response.Success) {
          this.alertify.success("Empleado editado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.employeeApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Empleado eliminado exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  activate(employee: EmployeeVM) {
    const uri = `${this.employeeApi}/ActivateRecord`;
    return this.http.put(uri, employee).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Empleado activado exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  changePassword(changePasswordInfo: ChangeEmployeePassword) {
    const uri = `${this.employeeApi}/ChangePassword`;
    return this.http.put(uri, changePasswordInfo).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se modificó la contraseña");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.employeeApi).pipe(
      map((response: OperationResult<EmployeeVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getDeleted() {
    const uri = `${this.employeeApi}/GetDeleted`;;
    return this.http.get(uri).pipe(
      map((response: OperationResult<EmployeeVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.employeeApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<EmployeeVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getNextEmployeeNumber() {
    const uri = `${this.employeeApi}/GetNextEmployeeNumber`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<string>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }
}


<div class="row">
  <div class="col-md-6 col-sm-12 mx-auto">
    <app-list-details #details></app-list-details>
  </div>
</div>

<div [hidden]="showLoans"
     class="jumbotron text-center">
  <b>No hay préstamos</b>
</div>

<div [hidden]="!showLoans || showPayments">
  <h5>
    Préstamos
  </h5>

  <br />

  <app-generic-grid #loansGrid
                    (onCellClicked)="select($event)">
  </app-generic-grid>
</div>

<div [hidden]="!showPayments"
     class="row">

  <div class="col-md-10 mx-auto">
    <div class="row">
      <div class="col-md-6">
        <h5>Pagos</h5>
      </div>
      <div class="col-md-6 align-content-end">
        <p class="pull-right">
          <button class="btn btn-outline-sed"
                  (click)="showPayments=false">
            Cerrar
          </button>
        </p>
      </div>
    </div>

    <br />

    <app-header-details #paymentDetails></app-header-details>

    <br />

    <app-generic-grid #paymentsGrid></app-generic-grid>
  </div>
</div>

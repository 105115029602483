import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { EmployeeLoanVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { EmployeeSelectorComponent } from '../../../components/employee-selector/employee-selector.component';
import { LoansService } from '../../../services/loans.service';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss']
})
export class LoansComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  loans: EmployeeLoanVM[] = [];
  employeeLoanVM: EmployeeLoanVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  loanForm: FormGroup;

  @ViewChild("employeeSelector", { read: EmployeeSelectorComponent })
  public employeeSelector: EmployeeSelectorComponent;

  employeeName: string = "";

  constructor(private loansService: LoansService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllLoans();
  }

  initForm() {
    this.loanForm = this.fb.group({
      Authorizer: ['', [Validators.required, Validators.minLength(3)]],
      Amount: [0, [Validators.required]],
      WeeklyPayment: [0, [Validators.required]],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.loanForm.valid
      && this.employeeSelector.selectorForm.valid;
  }

  getAllLoans() {
    this.loading.show();
    this.loansService.getAll()
      .subscribe((response: EmployeeLoanVM[]) => {
        if (response) {
          response.forEach(item => {
            item.EmployeeName = `${item.Employee.Name} ${item.Employee.FirstLastName} ${item.Employee.SecondLastName}`;
          });
          this.loans = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.loans);
    this.grid.options.toolbarTitle = "Préstamos";
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "EmployeeName", title: "Empleado" },
      { field: "Authorizer", title: "Autorizador" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "WeeklyPayment", title: "Pago Semanal", currencyType: true },
      { field: "Paid", title: "Liquidado", booleanType: true },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.employeeSelector.resetForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newLoan: EmployeeLoanVM = Object.assign({
        EmployeeId: this.employeeSelector.getEmployeeId(),
        EmployeeName: this.employeeSelector.getEmployeeName(),
        Paid: false,
      }, this.loanForm.value);

      this.loansService.create(newLoan)
        .subscribe((response: EmployeeLoanVM) => {
          if (response) {
            response.EmployeeName = newLoan.EmployeeName;
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(loan: EmployeeLoanVM) {
    this.employeeLoanVM = loan;
    this.loanForm.setValue({
      Authorizer: this.employeeLoanVM.Authorizer,
      Amount: this.employeeLoanVM.Amount,
      WeeklyPayment: this.employeeLoanVM.WeeklyPayment,
    });
    this.employeeSelector.setComboById(this.employeeLoanVM.EmployeeId);
    this.employeeName = this.employeeLoanVM.EmployeeName;
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedLoan: EmployeeLoanVM = Object.assign({
        UUID: this.employeeLoanVM.UUID,
        EmployeeId: this.employeeLoanVM.EmployeeId,
        EmployeeName: this.employeeSelector.getEmployeeName(),
        CreateDate: this.employeeLoanVM.CreateDate,
        Paid: this.employeeLoanVM.Paid,
      }, this.loanForm.value);

      this.loansService.update(updatedLoan)
        .subscribe((response: EmployeeLoanVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedLoan);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(loan: EmployeeLoanVM) {
    this.alertify.confirm(
      `Eliminar prestamo a: ${loan.EmployeeName} por $ ${loan.Amount}`,
      () => {
        this.loading.show();
        this.loansService.delete(loan.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(loan.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

}

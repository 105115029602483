import { Component, OnInit, ViewChild } from '@angular/core';
import { CageSelectorComponent } from '../../../../components/cage-selector/cage-selector.component';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { HeaderDetailsComponent } from '../../../../components/header-details/header-details.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { CageAnimalReportVM, CageReportVM, CageVM } from '../../../../models/ViewModels';
import { CageService } from '../../../../services/cage.service';

@Component({
  selector: 'app-cages-report',
  templateUrl: './cages-report.component.html',
  styleUrls: ['./cages-report.component.scss']
})
export class CagesReportComponent implements OnInit {

  cageSelected: boolean = false;

  @ViewChild("cageSelector", { read: CageSelectorComponent })
  public cageSelector: CageSelectorComponent;

  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;

  report: CageReportVM;
  gridReport: CageAnimalReportVM[] = [];

  @ViewChild("headerDetails", { read: HeaderDetailsComponent, static: false })
  public headerDetails: HeaderDetailsComponent;

  constructor(private cageService: CageService,
    private loading: LoadingService) { }

  ngOnInit(): void {
  }

  onCageSelected(cage: CageVM) {
    this.loading.show();
    this.cageService.createCageReport(cage.UUID)
      .subscribe((response: CageReportVM) => {
        if (response) {
          this.report = response;
          this.gridReport = response.AnimalDetails;          
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.gridReport);
    this.grid.options.toolbarTitle = `Reporte de Jaula ${this.report.Cage.CageInfo}`;
    this.grid.options.hideActionButtons = true;
    this.grid.columns = [
      { field: "CageId", title: "Id Jaula" },
      { field: "CageInfo", title: "Jaula" },
      { field: "AnimalId", title: "Id Animal" },
      { field: "AnimalInfo", title: "Animal" },
      { field: "SaleDate", title: "Fecha de Venta", dateType: true },
      { field: "PurchaseWeight", title: "Peso de Compra", numberType: true },
      { field: "SaleWeight", title: "Peso de Venta", numberType: true },
      { field: "Weightdifference", title: "Diferencia", numberType: true },
      { field: "PurchasePrice", title: "Precio de Compra", currencyType: true },
      { field: "SalePrice", title: "Precio de Venta", currencyType: true },
      { field: "PurchaseKiloCost", title: "Precio Kg Compra", currencyType: true },
      { field: "SaleKiloCost", title: "Precio Kg Venta", currencyType: true },
      { field: "CustomerName", title: "Cliente" },
    ];
    this.grid.setGridOptions();
    this.createHeader();
    this.cageSelected = true;
  }

  createHeader() {
    this.headerDetails.headerItems = [
      { title: "Identificador", value: `${this.report.Cage.Identifier}${this.report.Cage.Id}` },
      { title: "Fecha", value: this.report.Cage.Date, isDate: true },
      { title: "Ganadero", value: this.report.Cage.Farmer.Name },
      { title: "Peso", value: `${this.report.Cage.Weight} KG` },
      { title: "Cabezas", value: this.report.Cage.NumberOfHeads },
      { title: "Costo Total", value: this.report.Cage.TotalCost, isCurrency: true },
    ];

    this.headerDetails.showHeader = true;
  }


}


<h5 class="__header"
    *ngIf="showCollapseButton"
    (click)="collapse=!collapse">
  {{ detailsTitle || '' }} {{subtitle || ''}}
  <i class="fa fa-caret-up pull-right"
     *ngIf="!collapse"></i>
  <i class="fa fa-caret-down pull-right"
     *ngIf="collapse"></i>
</h5>

<div [ngClass]="{'collapse': collapse}">
  <ul class="list-group" *ngIf="showItems">
    <li class="list-group-item d-flex justify-content-between align-items-center"
        *ngFor="let item of details">
      <b>{{ item.title }}</b>
      <span class="badge badge-primary badge-pill"
            *ngIf="!item.isCurrency && !item.isDate && !item.isBoolean">
        {{ item.value || ''}}
      </span>
      <span class="badge badge-primary badge-pill"
            *ngIf="item.isDate">
        {{ item.value | date:'dd/MM/yyyy' }}
      </span>
      <span class="badge badge-primary badge-pill"
            *ngIf="item.isCurrency">
        {{ item.value | currency }}
      </span>

    </li>
  </ul>

</div>

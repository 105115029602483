
<div class="__component">
  <button class="btn btn-secondary"
          [disabled]="disabled"
          (click)="collapse=!collapse">
    {{selectedCages}} jaulas seleccionadas
  </button>

  <div [ngClass]="{'collapse': collapse}">
    <div class="__listContainer">
      <div class="__listItem"
           *ngFor="let cage of cages"
           (click)="selectItem(cage)">
        <div class="row">
          <div class="col-sm-1">
            <i class="fa fa-check"
               *ngIf="cage.IsSelected"></i>
          </div>
            <div class="col-sm-2">
              <b>{{cage.Identifier}}{{cage.Id}}</b>
            </div>
            <!--<div class="col-sm-4">
              {{cage.TotalCost | currency}}
            </div>-->
          </div>
      </div>
    </div>
  </div>

</div>

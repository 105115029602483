import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { CustomerVM } from '../../models/ViewModels';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent implements OnInit {

  items: CustomerVM[] = [];
  selectedItem: CustomerVM;
  searchValue: string = "";
  itemText: string = "";

  @Input("getNoCredits") getNoCredits: boolean = false;
  disabled: boolean = false;

  @ViewChild("search") search: ElementRef;
  collapse: boolean = true;
  @Output() onCustomerSelect: EventEmitter<CustomerVM> = new EventEmitter<CustomerVM>();
  showBalance: boolean = false;

  constructor(private dataService: CustomerService) { }

  ngOnInit(): void {
    this.loadComboValues();
  }

  public loadComboValues(): void {
    this.dataService.getAll()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          response.sort((n1, n2) => {
            if (n1.FullName > n2.FullName) { return 1; }
            else if (n1.FullName < n2.FullName) { return -1; }
            else { return 0; }
          });
          if (!this.getNoCredits) {
            this.items = response;
          } else {
            this.items = response.filter(item => item.HasCredit === false);
          }
        }
      });
  }

  focusOnSearchInput() {
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 0);
  }

  changeCollapseStatus() {
    this.collapse = !this.collapse;
    if (!this.collapse) {
      this.focusOnSearchInput();
    }
  }

  selectItem(item: CustomerVM) {
    this.selectedItem = item;
    this.itemText = item.FullName;
    this.searchValue = "";
    this.collapse = true;
    this.onCustomerSelect.emit(this.selectedItem);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedItem = this.items.find(item => item.UUID == id);
      this.itemText = this.selectedItem.FullName;
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedItem = this.items.find(item => item.FullName == name);
      this.itemText = this.selectedItem.FullName;
    }
  }

  public getCustomerName(): string {
    if (this.selectedItem)
      return this.selectedItem.FullName;

    return null;
  }

  public getCustomerId(): string {
    if (this.selectedItem)
      return this.selectedItem.UUID;

    return null;
  }

  resetForm() {
    this.selectedItem = null;
    this.itemText = "";
    this.searchValue = "";
    this.collapse = true;
  }

  isValidForm(): boolean {
    if (this.selectedItem && this.itemText !== '')
      return true;
    return false;
  }

}


export enum LocalData {
  ADMIN_ROL = "ADMIN",
  CHECK_ROL = "CHECK",
  SELECTED_CAGE_UUID = "selectedCageUUID",
  CAGE_ANIMALS= "cageAnimals",
  SELECTED_SALE_UUID = "SelectedSaleUUID",
  SELECTED_GUT_SALE_UUID = "SelectedGutSaleUUID",
  SELECTED_BUTCHERY_UUID = "SelectedButcheryUUID",
  SELECTED_BUTCHERY_NAME = "SelectedButcheryName",
  REGISTRY_TYPE_ENTRY = "I",
  REGISTRY_TYPE_EXIT = "O",
  TOGGLE_MENU = "toggleMenu",
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { ButcheryVM } from '../../../../models/ViewModels';
import { AlertifyService } from '../../../../services/alertify.service';
import { ButcheryService } from '../../../../services/butchery.service';

@Component({
  selector: 'app-butcheries-records',
  templateUrl: './butcheries-records.component.html',
  styleUrls: ['./butcheries-records.component.scss']
})
export class ButcheriesRecordsComponent implements OnInit {

  butcheries: ButcheryVM[] = [];
  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private butcheryService: ButcheryService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.getButcheries();
  }

  getButcheries() {
    this.loading.show();
    this.butcheryService.getDeleted()
      .subscribe((response: ButcheryVM[]) => {
        if (response) {
          this.butcheries = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.butcheries);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showActivateButton = true;
    this.grid.options.showExportExcelButton = false;
    this.grid.options.showExportPDFButton = false;
    this.grid.columns = [
      { field: "Name", title: "Nombre" },
      { field: "StringTime", title: "Hora de Entrada" },
      { field: "DelayMinutes", title: "Minutos de Tolerancia" },
      { field: "UserName", title: "Usuario" },
    ];
    this.grid.setGridOptions();
  }

  activate(butchery: ButcheryVM) {
    this.alertify.confirm(
      `Activar carnicería: ${butchery.Name}`,
      () => {
        this.loading.show();
        this.butcheryService.activate(butchery)
          .subscribe((response: boolean) => {
            if (Response) {
              this.grid.deleteRow(butchery.UUID);
            }
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

}

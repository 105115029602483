import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomerVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un Cliente...";
  items: CustomerVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: CustomerVM;

  showBalance: boolean = false;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onCustomerSelect: EventEmitter<CustomerVM> = new EventEmitter<CustomerVM>();

  constructor(private customerService: CustomerService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.customerService.getAll()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          response.sort((n1, n2) => {
            if (n1.FullName > n2.FullName) { return 1; }
            else if (n1.FullName < n2.FullName) { return -1; }
            else { return 0; }
          });
          this.items = response;
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectorForm.get('ComboValue').setValue(this.selectedValue.FullName);
    this.onCustomerSelect.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectorForm.get('ComboValue').setValue(this.selectedValue.FullName);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedValue = this.items.find(item => item.FullName == name);
      this.selectorForm.get('ComboValue').setValue(this.selectedValue.FullName);
    }
  }

  public getCustomerName(): string {
    if (this.selectedValue)
      return this.selectedValue.FullName;

    return null;
  }

  public getCustomerId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
  }

  openDropDown() {

  }


}


<h4>
  Asignar Ubicación
</h4>

<hr />

<div class="row">
  <div class="col-md-10 col-sm-12 mx-auto">

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <app-location-selector #locationSelector></app-location-selector>
      </div>

      <div class="col-md-6 col-sm-12 text-center">
        <igx-checkbox [checked]="toChamber"
                      *ngIf="selectedTab===2"
                      [(ngModel)]="toChamber">
          Enviar a Cámara
        </igx-checkbox>
      </div>
    </div>

    <br />

    <igx-tabs [(selectedIndex)]="selectedTab">
      <igx-tabs-group label="Reses">
        <app-animals-selector #animalSelector></app-animals-selector>

        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  type="button"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>
      </igx-tabs-group>

      <igx-tabs-group label="Visceras">
        <app-guts-selector #gutSelector></app-guts-selector>

        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  type="button"
                  [disabled]="!gutIsValid()"
                  (click)="saveGuts()">
            Guardar
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>
      </igx-tabs-group>

      <igx-tabs-group label="Cortes">

        
        <div class="row">
          <div class="col-md-8 col-sm-12 mx-auto">

            <app-list-details #cutsList></app-list-details>

            <form [formGroup]="cutsForm"
                  [hidden]="totalCuts <= 0">

              <app-date-picker #dateSelector></app-date-picker>

              <igx-input-group>
                <input igxInput name="Weight"
                       [ngClass]="{'is-invalid': cutsForm.get('Weight').errors && cutsForm.get('Weight').touched}"
                       type="number"
                       aria-label="Peso"
                       formControlName="Weight" />
                <label igxLabel for="Weight">Peso</label>
              </igx-input-group>

              <hr />

              <div class="d-flex justify-content-between">
                <button class="btn btn-primary"
                        type="button"
                        [disabled]="!cutsValidForm()"
                        (click)="saveCuts()">
                  Guardar
                </button>

                <button class="btn btn-secondary"
                        type="button"
                        (click)="cancel()">
                  Cancelar
                </button>
              </div>

            </form>
          </div>
        </div>
      </igx-tabs-group>

    </igx-tabs>

  </div>
</div>

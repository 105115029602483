import { Component, OnInit } from '@angular/core';
import { ListDetailsItem } from '../../models/componentInterfaces';

@Component({
  selector: 'app-list-details',
  templateUrl: './list-details.component.html',
  styleUrls: ['./list-details.component.scss']
})
export class ListDetailsComponent implements OnInit {

  details: ListDetailsItem[] = [];
  showItems: boolean = false;
  detailsTitle: string;
  collapse: boolean = false;
  showCollapseButton: boolean = true;

  subtitle: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}

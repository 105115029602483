import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { RolVM, OperationResult } from '../models/ViewModels';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  rolApi = `${environment.apiUrl}/Rol`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(rol: RolVM) {
    return this.http.post(this.rolApi, rol).pipe(
      map((response: OperationResult<RolVM>) => {
        if (response.Success) {
          this.alertify.success("Rol creado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(rol: RolVM) {
    return this.http.put(this.rolApi, rol).pipe(
      map((response: OperationResult<RolVM>) => {
        if (response.Success) {
          this.alertify.success("Rol editado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(code: string) {
    const uri = `${this.rolApi}?rolCode=${code}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Rol eliminado exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.rolApi).pipe(
      map((response: OperationResult<RolVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationSelectorComponent } from '../../../components/location-selector/location-selector.component';
import { AnimalsSelectorComponent } from '../../../components/animals-selector/animals-selector.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { LocalData } from '../../../models/local.data';
import { Router } from '@angular/router';
import { CageService } from '../../../services/cage.service';
import { CageVM, AnimalVM, LocationCutVM } from '../../../models/ViewModels';
import { LoadingService } from '../../../components/loading/loading.service';
import { GutsSelectorComponent } from '../../../components/guts-selector/guts-selector.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListDetailsComponent } from '../../../components/list-details/list-details.component';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LocationCutsService } from '../../../services/location-cuts.service';

@Component({
  selector: 'app-assign-location',
  templateUrl: './assign-location.component.html',
  styleUrls: ['./assign-location.component.scss']
})
export class AssignLocationComponent implements OnInit {

  userId: string;

  @ViewChild("locationSelector", { read: LocationSelectorComponent, static: true })
  public locationSelector: LocationSelectorComponent;

  @ViewChild("animalSelector", { read: AnimalsSelectorComponent, static: false })
  public animalSelector: AnimalsSelectorComponent;

  @ViewChild("gutSelector", { read: GutsSelectorComponent, static: false })
  public gutSelector: GutsSelectorComponent;

  cageId: string;
  cageVM: CageVM;
  selectedTab: number = -1;
  toChamber: boolean = false;
  totalCuts: number = 0;
  cutsForm: FormGroup;
  @ViewChild("dateSelector", { read: DatePickerComponent, static: true })
  public dateSelector: DatePickerComponent;

  @ViewChild("cutsList", { read: ListDetailsComponent, static: false })
  public cutsList: ListDetailsComponent;

  constructor(private router: Router,
    private authService: AuthenticationService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private cageService: CageService,
    private locationCutService: LocationCutsService) {

    // Get user from local data
    this.userId = this.authService.SessionUserValue().uuid;

    // Get cage from local data
    if (localStorage.getItem(LocalData.SELECTED_CAGE_UUID) == null) {
      this.router.navigate(['home/cages']);
    }
    this.cageId = localStorage.getItem(LocalData.SELECTED_CAGE_UUID).toString();
    this.getCageInfo();

  }

  ngOnInit(): void {
    this.initCutsForm();
  }

  initCutsForm() {
    this.cutsForm = this.fb.group({
      Weight: [0, [Validators.required]]
    });
  }

  cutsValidForm(): boolean {
    return this.cutsForm.valid
      && this.dateSelector.dateForm.valid;
  }

  getCageInfo() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.cageVM = response;
          this.animalSelector.items = this.cageVM.Animals;
          this.gutSelector.items = this.cageVM.Guts;
          this.animalSelector.showItems = true;
          this.gutSelector.showItems = true;
          this.calculateTotalCuts();
          this.animalSelector.collapse = false;
          this.gutSelector.collapse = false;
          this.animalSelector.forSale = false;
          this.gutSelector.forSale = false;
        }
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  isValidForm(): boolean {
    return this.locationSelector.selectorForm.valid
      && this.animalSelector.isValidForLocation();
  }

  gutIsValid(): boolean {
    return this.locationSelector.selectorForm.valid
      && this.gutSelector.isValidForLocation();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      let cage: CageVM = {
        UUID: this.cageVM.UUID,
        Id: this.cageVM.Id,
        Identifier: this.cageVM.Identifier,
        NumberOfHeads: this.cageVM.NumberOfHeads,
        Weight: this.cageVM.Weight,
        TotalWeight: this.cageVM.TotalWeight,
        KiloCost: this.cageVM.KiloCost,
        FreightCost: this.cageVM.FreightCost,
        TotalCost: this.cageVM.TotalCost,
        Date: this.cageVM.Date,
        Farmer: this.cageVM.Farmer,
        FarmerName: this.cageVM.FarmerName,
        Animals: this.getAnimalsListWithNewLocation(),
      };
      this.cageService.updateAnimalLocation(cage)
        .subscribe((response: boolean) => {
          if (response) {
            this.cancel();
          }
        }, (err) => {
            console.log(err);
            this.loading.hide();
        });
    }
  }

  saveGuts() {
    if (this.gutIsValid()) {
      this.loading.show();
      let cage: CageVM = {
        UUID: this.cageVM.UUID,
        Id: this.cageVM.Id,
        Identifier: this.cageVM.Identifier,
        NumberOfHeads: this.cageVM.NumberOfHeads,
        Weight: this.cageVM.Weight,
        TotalWeight: this.cageVM.TotalWeight,
        KiloCost: this.cageVM.KiloCost,
        FreightCost: this.cageVM.FreightCost,
        TotalCost: this.cageVM.TotalCost,
        Date: this.cageVM.Date,
        Farmer: this.cageVM.Farmer,
        FarmerName: this.cageVM.FarmerName,
        Guts: this.getGutsListWithNewLocation(),
      };
      this.cageService.updateGutLocation(cage)
        .subscribe((response: boolean) => {
          if (response) {
            this.cancel();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  getAnimalsListWithNewLocation(): AnimalVM[] {
    let list = this.animalSelector.getSelectedAnimals();
    list.forEach(item => {
      item.LocationId = this.locationSelector.getItemId();
      item.LocationAlias = this.locationSelector.getItemInfo();
    });
    return list;
  }

  getGutsListWithNewLocation() {
    let list = this.gutSelector.getSelectedGuts();
    list.forEach(item => {
      item.LocationId = this.locationSelector.getItemId();
      item.LocationAlias = this.locationSelector.getItemInfo();
    });
    return list;
  }

  cancel() {

    this.router.navigate(['home/cage-details']);
  }

  calculateTotalCuts() {
    this.cageVM.Animals.filter(item => item.IsForCut === true)
      .forEach(item => {
        this.totalCuts = this.totalCuts + item.SaleWeight;
        this.cutsList.details.push({
          title: `${item.AnimalInfo} (${item.SaleWeight} Kg)`,
          value: item.UpdateDate,
          isDate: true
        });
      });
    this.cutsList.showItems = true;
    this.cutsList.showCollapseButton = true;
    this.cutsList.detailsTitle = "Enviado a corte";
    this.cutsList.subtitle = `(${this.totalCuts} Kg)`;
    this.cutsList.collapse = true;
  }

  saveCuts() {
    if (this.cutsValidForm()) {
      this.loading.show();
      let locationId: string;
      if (this.toChamber) 
        locationId = null;
      else 
        locationId = this.locationSelector.getItemId();
      
      const newLocationCut: LocationCutVM = Object.assign({
        UserId: this.userId,
        Date: this.dateSelector.getFormValue().CustomDate,
        CreateDate: new Date(),
        CageId: this.cageId,
        LocationId: locationId,
        InChamber: this.toChamber,
      }, this.cutsForm.value);

      this.locationCutService.create(newLocationCut)
        .subscribe((response: LocationCutVM) => {
          if (response) {
            this.resetCutsForm();
          }
          this.loading.hide();
        }, (err) => {
            this.loading.hide();
        });
      
    }
  }

  resetCutsForm() {
    this.cutsForm.reset();
    this.toChamber = false;
    this.locationSelector.resetForm();
  }

}


<div class="row" *ngIf="showHeader">
  <div class="col-md-2 col-sm-12 mx-auto __box"
       *ngFor="let item of headerItems"
       (click)="itemClicked(item)"
       [ngClass]="{'__isLink': item.isLink}">
    <span class="__title">
      {{ item.title }}
    </span>
    <span class="__value" *ngIf="!item.isCurrency && !item.isDate">
      {{ item.value }}
    </span>
    <span class="__value" *ngIf="item.isCurrency">
      {{ item.value | currency }}
    </span>
    <span class="__value" *ngIf="item.isDate">
      {{ item.value | date:'dd/MM/yyyy' }}
    </span>
  </div>
</div>

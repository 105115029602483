import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginRequestVM, LoginResponse, OperationResult } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private sessionUserSubject: BehaviorSubject<LoginResponse>;
  public sessionUser: Observable<LoginResponse>;

  constructor(private http: HttpClient,
    private router: Router) {
    this.sessionUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem('sessionUser')));
    this.sessionUser = this.sessionUserSubject.asObservable();
  }

  public SessionUserValue(): LoginResponse {
    return this.sessionUserSubject.value;
  }

  login(user: LoginRequestVM) {
    const uri = `${environment.apiUrl}/Account/SignIn`;
    return this.http.post<any>(uri, user)
      .pipe(map((response: OperationResult<LoginResponse>) => {
        localStorage.setItem('sessionUser', JSON.stringify(response.Data));
        this.sessionUserSubject.next(response.Data);
        return response.Data;
      }));
  }

  logout() {
    localStorage.removeItem('sessionUser');
    this.sessionUserSubject.next(null);
    this.router.navigate(['/landing']);
  }

  
}

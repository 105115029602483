
<div>
  <h4>
    Cambiar Contraseña
  </h4>

  <br />
  <hr />

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <form [formGroup]="passwordForm">

        <p class="form-control">
          {{user.user}}
        </p>

        <igx-input-group *ngIf="!showPassword">
          <input igxInput name="NewPassword"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': passwordForm.get('NewPassword').errors && passwordForm.get('NewPassword').touched}"
                 type="password"
                 aria-label="Nueva Contraseña"
                 formControlName="NewPassword" />
          <label igxLabel for="NewPassword">Nueva Contraseña</label>
        </igx-input-group>

        <igx-input-group *ngIf="showPassword">
          <input igxInput name="NewPassword"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': passwordForm.get('NewPassword').errors && passwordForm.get('NewPassword').touched}"
                 type="text"
                 aria-label="Nueva Contraseña"
                 formControlName="NewPassword" />
          <label igxLabel for="NewPassword">Nueva Contraseña</label>
        </igx-input-group>

        <igx-input-group *ngIf="!showPassword">
          <input igxInput name="ConfirmNewPassword"
                 [ngClass]="{'is-invalid': passwordForm.get('ConfirmNewPassword').errors && passwordForm.get('ConfirmNewPassword').touched}"
                 type="password"
                 aria-label="Confirmar Nueva Contraseña"
                 formControlName="ConfirmNewPassword" />
          <label igxLabel for="ConfirmNewPassword">Confirmar Nueva Contraseña</label>
        </igx-input-group>

        <igx-input-group *ngIf="showPassword">
          <input igxInput name="ConfirmNewPassword"
                 [ngClass]="{'is-invalid': passwordForm.get('ConfirmNewPassword').errors && passwordForm.get('ConfirmNewPassword').touched}"
                 type="text"
                 aria-label="Confirmar Nueva Contraseña"
                 formControlName="ConfirmNewPassword" />
          <label igxLabel for="ConfirmNewPassword">Confirmar Nueva Contraseña</label>
        </igx-input-group>

        <p *ngIf="!passwordsMatch()">
          Las contraseñas no coinciden
        </p>

        <igx-checkbox [checked]="showPassword"
                      (change)="showPassword=!showPassword">
          Mostrar Contraseña
        </igx-checkbox>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>



<div class="jumbotron text-center">
  <h3>Iniciar Sesión</h3>
  <h5>RT Management [QAs]</h5>
</div>

<div class="row">
  <div class="col-md-6 col-sm-12 mx-auto">
    <form [formGroup]="loginForm">

      <igx-input-group>
        <input igxInput name="User"
               [igxFocus]="true"
               [ngClass]="{'is-invalid': loginForm.get('User').errors && loginForm.get('User').touched}"
               type="text"
               formControlName="User" />
        <label igxLabel for="User">Usuario</label>
      </igx-input-group>

      <igx-input-group>
        <input igxInput name="Password"
               [ngClass]="{'is-invalid': loginForm.get('Password').errors && loginForm.get('Password').touched}"
               type="password"
               formControlName="Password" />
        <label igxLabel for="Password">Contraseña</label>
      </igx-input-group>

      <div class="text-center">
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!isValidForm()"
                (click)="login()">
          Entrar
        </button>
      </div>

    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { FarmerPayVM, OperationResult } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class FarmerPayService {

  paymentApi = `${environment.apiUrl}/PaymentToFarmer`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(pay: FarmerPayVM) {
    return this.http.post(this.paymentApi, pay).pipe(
      map((response: OperationResult<FarmerPayVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(pay: FarmerPayVM) {
    return this.http.put(this.paymentApi, pay).pipe(
      map((response: OperationResult<FarmerPayVM>) => {
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  deletePay(pay: FarmerPayVM) {
    const uri = `${this.paymentApi}/DeletePayment`;
    return this.http.put(uri, pay).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.paymentApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.paymentApi).pipe(
      map((response: OperationResult<FarmerPayVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.paymentApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<FarmerPayVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }
}

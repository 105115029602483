import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { LocationVM, AnimalVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { StateSelectorComponent } from '../../../components/state-selector/state-selector.component';
import { LocationService } from '../../../services/location.service';
import { CageService } from '../../../services/cage.service';
import { AnimalsDetailsComponent } from '../../../components/animals-details/animals-details.component';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  locations: LocationVM[] = [];
  locationVM: LocationVM;
  animals: AnimalVM[] = [];

  showForm: boolean = false;
  isEdition: boolean = false;
  locationForm: FormGroup;

  @ViewChild("stateSelector", { read: StateSelectorComponent })
  public stateSelector: StateSelectorComponent;

  @ViewChild("animalsList", { read: AnimalsDetailsComponent })
  public animalsList: AnimalsDetailsComponent;

  hideList: boolean = true;
  locationAlias: string = "";

  constructor(private locationService: LocationService,
    private cageService: CageService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllLocations();
  }

  initForm() {
    this.locationForm = this.fb.group({
      Alias: ['', [Validators.required, Validators.minLength(3)]],
      Address1: [''],
      Address2: [''],
      ZipCode: [''],
      City: [''],
      Latitude: [''],
      Longitude: [''],
      
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.locationForm.valid
      && this.stateSelector.stateForm.valid;
  }

  getAllLocations() {
    this.loading.show();
    this.locationService.getAll()
      .subscribe((response: LocationVM[]) => {
        if (response) {
          this.locations = response;
        }
        this.locations.forEach(item => {
          item.FullAddress = this.setFullAddress(item);
        });
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.locations);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Ubicaciones";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "Alias", title: "Alias" },
      { field: "FullAddress", title: "Domicilio" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.stateSelector.resetForm();
    this.initForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newLocation: LocationVM = Object.assign({
        State: this.stateSelector.selectedState,
        StateName: this.stateSelector.selectedState.Name,
        IsDefault: false,
      }, this.locationForm.value);
      this.locationService.create(newLocation)
        .subscribe((response: LocationVM) => {
          if (response) {
            response.FullAddress = this.setFullAddress(response);
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(location: LocationVM) {
    this.locationVM = location;
    this.locationForm.setValue({
      Alias: this.locationVM.Alias,
      Address1: this.locationVM.Address1,
      Address2: this.locationVM.Address2,
      ZipCode: this.locationVM.ZipCode,
      City: this.locationVM.City,
      Latitude: this.locationVM.Latitude,
      Longitude: this.locationVM.Longitude,
    });
    this.stateSelector.setComboById(this.locationVM.State.UUID);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedLocation: LocationVM = Object.assign({
        UUID: this.locationVM.UUID,
        State: this.stateSelector.selectedState,
        StateName: this.stateSelector.selectedState.Name,
        IsDefault: this.locationVM.IsDefault,
      }, this.locationForm.value);

      this.locationService.update(updatedLocation)
        .subscribe((response: LocationVM) => {
          if (response) {
            updatedLocation.FullAddress = this.setFullAddress(updatedLocation);
            this.grid.updateRowByUUID(updatedLocation);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(location: LocationVM) {
    this.alertify.confirm(
      `Eliminar ubicación: ${location.Alias}`,
      () => {
        this.loading.show();
        this.locationService.delete(location.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(location.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  setFullAddress(location: LocationVM): string {
    return `${location.Address1} ${location.Address2}, ${location.ZipCode}, ${location.City}, ${location.State.Name}`;
  }

  select(location: LocationVM) {
    this.loading.show();
    this.cageService.getAnimalsFromLocation(location.UUID)
      .subscribe((response: AnimalVM[]) => {
        if (response) {
          response.forEach(item => {
            item.CageInfo = `${item.CageIdentifier}${item.CageId}`;
          });
          this.animals = response;
          this.animals.sort((a, b) => {
            if (a.CageId > b.CageId) { return 1; }
            else if (a.CageId < b.CageId) { return -1; }
            else { return 0; }
          });
          this.animalsList.animals = this.animals;
          this.animalsList.locationDetails = true;
          this.animalsList.setGridOptionsForLocationDetails();
        }
        this.locationAlias = location.Alias;
        this.hideList = false;
        this.loading.hide();
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

}

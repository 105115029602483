import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CageVM, AnimalVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { CageService } from '../../../services/cage.service';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { LocalData } from '../../../models/local.data';
import { Router } from '@angular/router';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';

@Component({
  selector: 'app-animals',
  templateUrl: './animals.component.html',
  styleUrls: ['./animals.component.scss']
})
export class AnimalsComponent implements OnInit, OnDestroy {

  cageVM: CageVM;
  animals: AnimalVM[] = [];
  headerItems: HeaderDetailsItem[] = [];
  detailsItems: HeaderDetailsItem[] = [];

  showHeader: boolean = false;

  cageId: string;

  displayFlex: boolean = false;
  totalWeight: number = 0;

  constructor(private cageService: CageService,
    private alertify: AlertifyService,
    private router: Router,
    private loading: LoadingService) {

    if (localStorage.getItem(LocalData.SELECTED_CAGE_UUID) == null) {
      this.router.navigate(['home/cages']);
    }
    this.cageId = localStorage.getItem(LocalData.SELECTED_CAGE_UUID).toString();

  }

  ngOnInit(): void {
    this.getCageInfo();
  }

  getCageInfo() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.cageVM = response;
          this.totalWeight = this.cageVM.TotalWeight;
          this.animals = this.cageVM.Animals;
        }
        this.createHeader();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  createHeader() {
    this.headerItems = [
      { title: "Identificador", value: `${this.cageVM.Identifier}${this.cageVM.Id}` },
      { title: "Fecha", value: this.cageVM.Date, isDate: true },
      { title: "Ganadero", value: this.cageVM.Farmer.Name },
      { title: "Estado", value: this.cageVM.Farmer.State.Name },
      { title: "Peso", value: `${this.cageVM.Weight} KG` },
    ];

    this.detailsItems = [
      { title: "Cabezas", value: this.cageVM.NumberOfHeads },
      { title: "Precio por Kilo", value: this.cageVM.KiloCost, isCurrency: true },
      { title: "Precio de Flete", value: this.cageVM.FreightCost, isCurrency: true },
      { title: "Costo Total", value: this.cageVM.TotalCost, isCurrency: true },
    ];

    this.showHeader = true;
  }

  setWeight(animal: AnimalVM) {
    try {
      let w = parseFloat(animal.Weight.toString());
      if (w != 0) {
        this.loading.show();
        this.calculateTotalWeight();
        const cage: CageVM = {
          UUID: this.cageVM.UUID,
          Id: this.cageVM.Id,
          Identifier: this.cageVM.Identifier,
          NumberOfHeads: this.cageVM.NumberOfHeads,
          Weight: this.cageVM.Weight,
          TotalWeight: this.totalWeight,
          KiloCost: this.cageVM.KiloCost,
          FreightCost: this.cageVM.FreightCost,
          TotalCost: this.cageVM.TotalCost,
          Date: this.cageVM.Date,
          Farmer: this.cageVM.Farmer,
          Animals: [animal]
        };

        this.cageService.updateTotalWeight(cage)
          .subscribe((response: boolean) => {
            if (response) {
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
            console.log(err);
          });
      }
    } catch (e) {
      this.alertify.warning(`El valor especificado no es válido`);
      animal.Weight = 0;
      this.loading.hide();
    }

  }

  calculateTotalWeight() {
    this.totalWeight = 0;
    this.animals.forEach(item => {
      this.totalWeight += item.Weight;
    });
  }


  // remove local storage on component destroy
  ngOnDestroy(): void {
    localStorage.removeItem(LocalData.SELECTED_CAGE_UUID);
  }

}

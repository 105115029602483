
<nav class="navbar navbar-expand-md">
  <a class="navbar-brand">
    RT Management
  </a>

  <button class="navbar-toggler navbar-toggler-right"
          type="button"
          aria-controls="appNavigation"
          aria-expanded="!navbarOpen"
          aria-label="Menu"
          (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse"
       id="appNavigation"
       [ngClass]="{ 'collapse': !navbarOpen }">

    <div class="navbar-nav ml-auto">

      
      <a (click)="logout()"
         class="nav-item nav-link"
         routerLink="/home/roles"
         routerLinkActive="active">
        Cerrar Sesión
      </a>

    </div>
    <!-- navbar-nav mr-auto -->

    <!--<div class="navbar-nav ml-auto">
    <a (click)="toggleMenu('Inicio')"
       class="nav-item nav-link"
       routerLink=""
       routerLinkActive="active">
      Usuarios
    </a>
  </div>-->

  </div>
</nav>

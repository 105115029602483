import { Component, OnInit } from '@angular/core';
import { CageVM } from '../../models/ViewModels';
import { CageService } from '../../services/cage.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-multi-cages-selector',
  templateUrl: './multi-cages-selector.component.html',
  styleUrls: ['./multi-cages-selector.component.scss']
})
export class MultiCagesSelectorComponent implements OnInit {

  cages: CageVM[] = [];
  disabled: boolean = true;
  collapse: boolean = true;
  selectedIds: string[] = [];
  selectedCages: number = 0;

  constructor(private cageService: CageService,
    private loading: LoadingService) { }

  ngOnInit(): void {
  }

  getFarmerCages(farmerId: string) {
    this.loading.show();
    this.cageService.getFarmerCages(farmerId)
      .subscribe((response: CageVM[]) => {
        if (response) {
          this.cages = response;
          this.disabled = false;
        }
        this.loading.hide();
      });
  }

  selectItem(item: CageVM) {
    item.IsSelected = !item.IsSelected;
    this.selectedCages = this.cages.filter(item => item.IsSelected == true).length;
  }

  getIdsList(): string[] {
    this.cages.forEach(item => {
      if (item.IsSelected) {
        this.selectedIds.push(item.UUID);
      }
    });
    return this.selectedIds;
  }

  setSelectedItems(farmerId: string, idsList: string[]) {
    this.loading.show();
    this.cageService.getFarmerCages(farmerId)
      .subscribe((response: CageVM[]) => {
        if (response) {
          this.cages = response;
          idsList.forEach(item => {
            this.cages.find(c => c.UUID == item).IsSelected = true;
          });
          this.selectedCages = this.cages.filter(item => item.IsSelected == true).length;
          this.disabled = false;
        }
        this.loading.hide();
      });
  }

  reset() {
    this.selectedCages = 0;
    this.disabled = true;
    this.selectedIds = [];
    this.cages = [];
  }



}

<form [formGroup]="selectorForm">
  <igx-input-group #inputGroup
                   [igxToggleAction]="dropDown">
    <input #input
           class="form-control"
           type="text"
           igxInput
           [igxDropDownItemNavigation]="igxDropDown"
           [ngClass]="{'is-invalid': selectorForm.get('ComboValue').errors && selectorForm.get('ComboValue').touched}"
           formControlName="ComboValue"
           readonly="readonly"
           placeholder="{{ ComboPlaceHolder }}"
           (keydown.ArrowDown)="openDropDown()" />
  </igx-input-group>

  <igx-drop-down #dropDown [width]="'100%'"
                 (onSelection)="onSelection($event)">
    <div class="drop-down__scroll-container">
      <igx-drop-down-item *ngFor="let item of items"
                          [value]="item.UUID">
        <span *ngIf="showBalance">
          {{ item.FullName }} ({{ item.Balance | currency }})
        </span>
        <span *ngIf="!showBalance">
          {{ item.FullName }}
        </span>
      </igx-drop-down-item>
    </div>
  </igx-drop-down>
</form>

import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { FarmerVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { StateSelectorComponent } from '../../../components/state-selector/state-selector.component';
import { FarmerService } from '../../../services/farmer.service';

@Component({
  selector: 'app-farmers',
  templateUrl: './farmers.component.html',
  styleUrls: ['./farmers.component.scss']
})
export class FarmersComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  farmers: FarmerVM[] = [];
  farmerVM: FarmerVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  farmerForm: FormGroup;

  @ViewChild("stateSelector", { read: StateSelectorComponent })
  public stateSelector: StateSelectorComponent;

  constructor(private farmerService: FarmerService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllFarmers();
  }

  initForm() {
    this.farmerForm = this.fb.group({
      Name: ['', [Validators.required, Validators.minLength(3)]],
      Balance: [0],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.farmerForm.valid
      && this.stateSelector.stateForm.valid;
  }

  getAllFarmers() {
    this.loading.show();
    this.farmerService.getAll()
      .subscribe((response: FarmerVM[]) => {
        if (response) {
          this.farmers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.farmers);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Ganaderos";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Name", title: "Nombre" },
      { field: "StateName", title: "Estado" },
      { field: "Balance", title: "Saldo", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.stateSelector.initStateForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newFarmer: FarmerVM = Object.assign({
        State: this.stateSelector.selectedState,
        StateName: this.stateSelector.selectedState.Name,
      }, this.farmerForm.value);
      this.farmerService.create(newFarmer)
        .subscribe((response: FarmerVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(farmer: FarmerVM) {
    this.farmerVM = farmer;
    this.farmerForm.setValue({
      Name: this.farmerVM.Name,
      Balance: this.farmerVM.Balance,
    });
    this.stateSelector.setComboById(this.farmerVM.State.UUID);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedFarmer: FarmerVM = Object.assign({
        UUID: this.farmerVM.UUID,
        State: this.stateSelector.selectedState,
        StateName: this.stateSelector.selectedState.Name
      }, this.farmerForm.value);

      this.farmerService.update(updatedFarmer)
        .subscribe((response: FarmerVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedFarmer);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(farmer: FarmerVM) {
    this.alertify.confirm(
      `Eliminar ganadero: ${farmer.Name}`,
      () => {
        this.loading.show();
        this.farmerService.delete(farmer.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(farmer.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

}

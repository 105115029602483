import { Component, OnInit, ViewChild } from '@angular/core';
import { CageSelectorComponent } from '../../../components/cage-selector/cage-selector.component';
import { AnimalVM, CageVM, CustomerVM, EditGutSale, SaleGutsVM } from '../../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';
import { GutsSelectorComponent } from '../../../components/guts-selector/guts-selector.component';
import { SaleGutsService } from '../../../services/sale-guts.service';
import { SearchCustomerComponent } from '../../../components/search-customer/search-customer.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { CustomDateVM } from '../../../models/componentInterfaces';

@Component({
  selector: 'app-guts-sales',
  templateUrl: './guts-sales.component.html',
  styleUrls: ['./guts-sales.component.scss']
})
export class GutsSalesComponent implements OnInit {

  @ViewChild("customerSelector", { read: SearchCustomerComponent, static: true })
  public customerSelector: SearchCustomerComponent;

  @ViewChild("cageSelector", { read: CageSelectorComponent, static: true })
  public cageSelector: CageSelectorComponent;

  @ViewChild("gutsSelector", { read: GutsSelectorComponent, static: false })
  public gutsSelector: GutsSelectorComponent;

  saleForm: FormGroup;
  sales: SaleGutsVM[] = [];
  saleVM: SaleGutsVM;

  collapse: boolean = true;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  showForm: boolean = false;
  isEdition: boolean = false;
  totalCost: number = 0;

  saleCustomer: CustomerVM;
  saleCage: string;

  @ViewChild("saleDate", { read: DatePickerComponent })
  public saleDate: DatePickerComponent;

  selectedDate: Date = new Date();

  constructor(private salesService: SaleGutsService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private router: Router,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllSales();
    this.onFormChanges();
  }

  onFormChanges(): void {
    this.saleForm.valueChanges
      .subscribe(val => {
        //this.totalCost = val.KiloCost * this.gutsSelector.totalWeight;
      });
  }

  initForm() {
    this.saleForm = this.fb.group({
      NoteNumber: [''],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.saleForm.valid
      && this.customerSelector.isValidForm()
      && this.cageSelector.selectorForm.valid
      && this.gutsSelector.isValid();
  }

  isEditionValidForm(): boolean {
    return this.saleForm.valid
      && this.gutsSelector.isValid();
  }

  getAllSales() {
    this.loading.show();
    this.salesService.getAll()
      .subscribe((response: SaleGutsVM[]) => {
        if (response) {
          this.sales = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.sales);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Ventas";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "Date", title: "Fecha", dateType: true },
      { field: "CustomerName", title: "Cliente" },
      { field: "GutsQuantity", title: "Cantidad" },
      { field: "Total", title: "Total", currencyType: true },
      { field: "NoteNumber", title: "Nota" },

    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.saleDate.resetForm();
    this.initForm();
    this.totalCost = 0;
    this.customerSelector.resetForm();
    this.cageSelector.resetForm();
    this.gutsSelector.reset();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      let date: CustomDateVM = this.saleDate.getFormValue();
      this.gutsSelector.showItems = false;
      this.loading.show();
      const newSale: SaleGutsVM = Object.assign({
        CustomerId: this.customerSelector.getCustomerId(),
        CustomerName: this.customerSelector.getCustomerName(),
        Date: date.CustomDate,
        GutsQuantity: this.gutsSelector.selectedQuantity,
        Total: this.gutsSelector.totalPrice,
        CageId: this.cageSelector.getItemId(),
        CageInfo: this.cageSelector.getItemInfo(),
        GutsArray: this.gutsSelector.getGutsArray(),
        GutsList: this.gutsSelector.gutsList,
        Guts: this.gutsSelector.getSelectedGuts(),
        Paid: false,
      }, this.saleForm.value);

      this.salesService.create(newSale)
        .subscribe((response: SaleGutsVM) => {
          if (response) {
            this.grid.addRowAtTop(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });

    }
  }

  selectCage(cage: CageVM) {
    this.gutsSelector.cageId = cage.UUID;
    this.gutsSelector.getGuts();
  }

  selectGut(totalWeight: number) { }

  // Grid events

  select(sale: SaleGutsVM) {
    localStorage.setItem(LocalData.SELECTED_GUT_SALE_UUID, sale.UUID);
    this.router.navigate(['home/guts-sale-details']);
  }

  edit(sale: SaleGutsVM) {
    this.loading.show();
    this.salesService.getById(sale.UUID)
      .subscribe((response: SaleGutsVM) => {
        if (response) {
          this.saleVM = response;
          this.setForm();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setForm() {
    this.saleDate.setDate(new Date(this.saleVM.Date));
    this.saleCustomer = this.saleVM.Customer;
    this.saleCage = this.saleVM.Cage.CageInfo;
    this.saleForm.setValue({
      NoteNumber: this.saleVM.NoteNumber
    });
    this.gutsSelector.cageId = this.saleVM.CageId;
    this.gutsSelector.setSaleGuts(this.saleVM.Guts);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isEditionValidForm()) {
      let date: CustomDateVM = this.saleDate.getFormValue();
      this.gutsSelector.showItems = false;
      this.loading.show();
      const updatedSale: SaleGutsVM = Object.assign({
        UUID: this.saleVM.UUID,
        CustomerId: this.saleVM.CustomerId,
        CustomerName: this.saleVM.CustomerName,
        Date: date.CustomDate,
        GutsQuantity: this.gutsSelector.selectedQuantity,
        Total: this.gutsSelector.totalPrice,
        CageId: this.saleVM.CageId,
        CageInfo: this.saleVM.CageInfo,
        GutsArray: this.gutsSelector.getGutsArray(),
        GutsList: this.gutsSelector.gutsList,
        Guts: this.gutsSelector.getSelectedGuts(),
        Paid: this.saleVM.Paid
      }, this.saleForm.value);

      const editGutSale: EditGutSale = {
        SavedSale: this.saleVM,
        UpdatedSale: updatedSale
      };

      this.salesService.update(editGutSale)
        .subscribe((response: SaleGutsVM) => {
          if (response) {
            this.grid.addRowAtTop(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });

    }
  }

  delete(sale: SaleGutsVM) {
    this.alertify.confirm(
      `Eliminar venta de cliente ${sale.CustomerName}`,
      () => {
        this.loading.show();
        this.salesService.delete(sale.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(sale.UUID);
            }
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  dateChanged(date: Date) {
    if (this.isDateAfterCurrentDate(date)) {
      this.alertify.warning("La fecha no puede ser posterior a la fecha actual");
      this.saleDate.setDate(new Date());
    } else {
      this.selectedDate = date;
    }
  }

  isDateAfterCurrentDate(date: Date): boolean {
    const today = new Date();
    return date > today;
  }

  

}

<!-- display selector for sale -->
<div *ngIf="forSale">

  <div class="__animalsContainer"
       style="border: 1px solid #777;"
       *ngIf="showItems">

    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center"
          style="cursor:pointer;"
          (click)="collapse=!collapse">
        <b>{{selectedQuantity}} seleccionados</b>
        <span class="badge badge-primary badge-pill">
          <i class="fa fa-2x" [ngClass]="{'fa-angle-down':collapse, 'fa-angle-up': !collapse}"></i>
        </span>
      </li>
    </ul>

    <div [ngClass]="{'collapse': collapse}">

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <p>
            <igx-input-group>
              <input igxInput name="search"
                     type="text"
                     [(ngModel)]="searchQuery"
                     aria-label="Buscar"
                     placeholder="Buscar..." />
              <label igxLabel for="search">Buscar</label>
            </igx-input-group>
          </p>
        </div>
        <div class="col-md-6 col-sm-12">

          <p>
            <button type="button"
                    class="btn btn-secondary btn-block d-flex justify-content-between align-items-center"
                    (click)="toggleOptions=!toggleOptions">
              <span>Filtros</span>
              <i class="fa"
                 [ngClass]="{'fa-caret-down': !toggleOptions, 'fa-caret-up': toggleOptions}"></i>
            </button>
          </p>
          <div [ngClass]="{'collapse': toggleOptions}">

            <igx-checkbox [checked]="hideSold"
                          (change)="onHideSold()">
              Ocultar Vendidos
            </igx-checkbox>
            <hr />
            <igx-checkbox [checked]="hideCuts"
                          [hidden]="!hasCuts"
                          (change)="onHideCuts()">
              Ocultar Cortes
            </igx-checkbox>
            <hr />
            <igx-select #select1
                        [placeholder]="'Seleccione una jaula...'"
                        [displayDensity]="'compact'"
                        aria-label="Seleccione una jaula"
                        [(ngModel)]="filteredCage">
              <label igxLabel>Seleccione una jaula</label>
              <igx-select-item [value]="''">
                Seleccione una jaula...
              </igx-select-item>
              <igx-select-item *ngFor="let cage of filterCages"
                               [value]="cage">
                {{ cage }}
              </igx-select-item>
            </igx-select>
            <hr />
            <igx-checkbox [checked]="showFilterByDate"
                          (change)="onShowDateFilter()">
              Filtrar por fecha
            </igx-checkbox>
            <br />
            <app-date-picker #filterByDate
                             *ngIf="showFilterByDate"
                             (onDateChanged)="dateChanged($event)">
            </app-date-picker>
          </div>
        </div>
      </div>

      <div class="row __animalWeights">

        <div class="col-md-2 col-sm-6 __animalItem"
             *ngFor="let item of items | search: searchQuery | search: filteredCage | sold: hideSold | forCut: hideCuts | createDate: {ActiveFilter:showFilterByDate, FilterDate: filterDate }"
             [ngClass]="{'__selected': item.IsSelected, '__sold': item.Sold}"
             (click)="selectItem(item)">
          <b class="font-weight-bolder">{{item.AnimalInfo}}</b>
          <i class="fa fa-check"
             *ngIf="item.IsSelected"></i>
          <i class="fa fa-circle"
             style="color:#d00;"
             aria-label="Vendido"
             *ngIf="item.Sold"></i>
          <br />
          <div class="font-weight-lighter text-center">
            {{item.Weight | number }} Kg
          </div>
          <div *ngIf="item.IsForCut"
               class="text-center">
            <br />
            <i class="fa fa-cut"></i>
          </div>
        </div>
        <!-- col -->

      </div>
      <!-- row -->
    </div>
    <!-- animal selector collapse -->

    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center"
          style="cursor:pointer;"
          (click)="wCollapse=!wCollapse">
        <b>Peso Total: {{totalWeight | number}} Kg</b>
        <span class="badge badge-primary badge-pill">
          <i class="fa fa-2x" [ngClass]="{'fa-angle-down':wCollapse, 'fa-angle-up': !wCollapse}"></i>
        </span>
      </li>
    </ul>

    <div [ngClass]="{'collapse': wCollapse}">
      <div class="row __animalWeights">
        <div class="col-md-3 col-sm-6 d-flex justify-content-between __inputContainer"
             *ngFor="let animal of animals">

          <div class="row">
            <div class="col-3">
              <small>
                {{animal.AnimalInfo}}
              </small>
            </div>
            <div class="col-9">
              <input type="number"
                     class="form-control-sm border-0"
                     [(ngModel)]="animal.SaleWeight"
                     (focusout)="changeWeight(animal)"
                     placeholder="Peso {{animal.AnimalInfo}}" />
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- change weight Collapse -->

    <div [hidden]="!hasCuts">
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center"
            style="cursor:pointer;"
            (click)="cCollapse=!cCollapse">
          <b>Córtes: {{ cutsPrice | currency }}</b>
          <span class="badge badge-primary badge-pill">
            <i class="fa fa-2x" [ngClass]="{'fa-angle-down':cCollapse, 'fa-angle-up': !cCollapse}"></i>
          </span>
        </li>
      </ul>
      <div [ngClass]="{'collapse': cCollapse}">

        <p>
          <b>{{animalsForCut.length}} cortes disponibles</b>
        </p>

        <form [formGroup]="cutForm">

          <div class="row">
            <div class="col-md-3 col-sm-12">
              <igx-select #select2
                          [placeholder]="'Seleccione una fecha...'"
                          [displayDensity]="'compact'"
                          aria-label="Seleccione una fecha"
                          [(ngModel)]="cutDate"
                          [ngModelOptions]="{standalone: true}">
                <label igxLabel>Seleccione una fecha</label>
                <igx-select-item [value]="''">
                  Seleccione una fecha...
                </igx-select-item>
                <igx-select-item *ngFor="let afc of animalsForCut"
                                 [value]="afc">
                  [ {{ afc.CageInfo }} ]
                  &nbsp;
                  {{ afc.CutDate | date: 'dd/MM/yyyy' }}}}
                </igx-select-item>
              </igx-select>
            </div>
            <div class="col-md-3 col-sm-12">
              <app-cut-selector #cutSelector></app-cut-selector>
            </div>
            <div class="col-md-3 col-sm-12">
              <igx-input-group>
                <input igxInput name="Weight"
                       [ngClass]="{'is-invalid': cutForm.get('Weight').errors && cutForm.get('Weight').touched}"
                       type="number"
                       aria-label="Peso"
                       formControlName="Weight" />
                <label igxLabel for="Weight">Peso</label>
              </igx-input-group>
            </div>
            <div class="col-md-3 col-sm-12">
              <igx-input-group>
                <input igxInput name="KiloCost"
                       [ngClass]="{'is-invalid': cutForm.get('KiloCost').errors && cutForm.get('KiloCost').touched}"
                       type="number"
                       aria-label="Precio por kilo"
                       formControlName="KiloCost" />
                <label igxLabel for="KiloCost">Precio por Kilo</label>
              </igx-input-group>
            </div>

            <div class="col-md-3 col-sm-12"></div>
            <div class="col-md-3 col-sm-12"></div>
            <div class="col-md-3 col-sm-12"></div>
            <div class="col-md-3 col-sm-12">
              <button class="btn btn-outline-primary btn-block"
                      type="submit"
                      (click)="addCut()"
                      [disabled]="!cutFormValid()">
                Agregar
              </button>
            </div>
          </div>
        </form>

        <br />

        <kendo-grid [data]="saleCuts">

          <kendo-grid-column field="CutDate" title="Fecha">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.CutDate | date: 'dd/MM/yyyy' }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="CutName" title="Corte">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.CutName }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Weight" title="Peso">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.Weight | number }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="KiloCost" title="Precio por Kilo">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.KiloCost | currency }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Total" title="Total">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.Total | currency }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="" width="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <button class="btn btn-outline-secondary btn-sm"
                        (click)="deleteCut(dataItem)"
                        aria-label="Eliminar">
                  <i class="fa fa-trash-o"></i>
                  <span class="sr-only">Eliminar</span>
                </button>
              </div>
            </ng-template>
          </kendo-grid-column>

        </kendo-grid>

      </div>
      <!-- cuts selector collapse -->
    </div>
  </div>
  <!-- __animalsContainer -->
</div>

<!-- display selector for location assignment -->
<div *ngIf="!forSale">
  <div class="__animalsContainer"
       *ngIf="showItems">
    <div class="row">
      <div class="col-md-4 col-sm-12 mx-auto text-center">
        <p *ngIf="showTotalWeight">
          {{
selectedQuantity
          }} seleccionados
        </p>
      </div>
      <div class="col-md-4 col-sm-12 mx-auto ">
        <p>
          <button class="btn btn-outline-primary"
                  (click)="selectAll()">
            Seleccionar todos
          </button>
        </p>
      </div>
      <div class="col-md-4 col-sm-12 mx-auto ">
        <p>
          <button class="btn btn-outline-primary"
                  (click)="collapse=!collapse">
            <span *ngIf="collapse">
              Abrir <i class="fa fa-caret-down"></i>
            </span>
            <span *ngIf="!collapse">
              Cerrar <i class="fa fa-caret-up"></i>
            </span>
          </button>
        </p>
      </div>
    </div>
    <!-- row -->

    <div [ngClass]="{'collapse': collapse}">
      <div class="row">

        <div class="col-md-2 col-sm-6 __animalItem"
             *ngFor="let item of items"
             [ngClass]="{'__selected': item.IsSelected, '__sold': item.Sold}"
             (click)="selectForLocation(item)">
          <b class="font-weight-bolder">{{item.AnimalInfo}}</b>
          <i class="fa fa-check"
             *ngIf="item.IsSelected"></i>
          <br />
          <div class="font-weight-lighter text-center"
               style="border-top: 1px solid #bbb; padding-top: 3px;">
            {{ item.Weight | number }} Kg
            <br />
            <small class="small">Peso</small>
          </div>
          <div class="font-weight-lighter text-center"
               style="border-top: 1px solid #bbb; padding-top: 3px;">
            {{ item.LocationAlias }}
            <br />
            <small class="small">Ubicación actual</small>
          </div>
        </div>
        <!-- col -->

      </div>
      <!-- row -->
    </div>
    <!-- collapse -->

  </div>
  <!-- __animalsContainer -->
</div>
<!-- for location assignment -->

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ChangePasswordVM, OperationResult, RegisterRequestVM, UserVM } from '../models/ViewModels';
import { AlertifyService } from './alertify.service';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userApi = `${environment.apiUrl}/User`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertifyService: AlertifyService) { }

  create(user: RegisterRequestVM) {
    return this.http.post(this.userApi, user).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertifyService.success("Usuario creado exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  update(user: UserVM) {
    return this.http.put(this.userApi, user).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertifyService.success("Usuario editado exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  changePassword(passwordInfo: ChangePasswordVM) {
    const uri = `${this.userApi}/ChangePassword`;
    return this.http.put(uri, passwordInfo).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertifyService.success("La contraseña se actualizó correctamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.userApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertifyService.success("Usuario eliminado exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.userApi).pipe(
      map((response: OperationResult<UserVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }
}

import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { FarmerPayVM, FarmerVM, CageVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { PaymentMethodSelectorComponent } from '../../../components/payment-method-selector/payment-method-selector.component';
import { FarmerPayService } from '../../../services/farmer-pay.service';
import { FarmerSelectorComponent } from '../../../components/farmer-selector/farmer-selector.component';
import { MultiCagesSelectorComponent } from '../../../components/multi-cages-selector/multi-cages-selector.component';
import { ConfirmDeleteComponent } from '../../../components/confirm-delete/confirm-delete.component';
import { ListDetailsComponent } from '../../../components/list-details/list-details.component';

@Component({
  selector: 'app-farmer-pay',
  templateUrl: './farmer-pay.component.html',
  styleUrls: ['./farmer-pay.component.scss']
})
export class FarmerPayComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  pays: FarmerPayVM[] = [];
  payVM: FarmerPayVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  payForm: FormGroup;

  @ViewChild("paymentMethodSelector", { read: PaymentMethodSelectorComponent, static: true })
  public paymentMethodSelector: PaymentMethodSelectorComponent;

  @ViewChild("farmerSelector", { read: FarmerSelectorComponent, static: true })
  public farmerSelector: FarmerSelectorComponent;

  @ViewChild("cagesSelector", { read: MultiCagesSelectorComponent, static: true })
  public cagesSelector: MultiCagesSelectorComponent;

  @ViewChild("confirmDeleteCmp", { read: ConfirmDeleteComponent, static: true })
  public confirmDeleteCmp: ConfirmDeleteComponent;
  showDelete: boolean = false;

  showDetails: boolean = false;
  @ViewChild("payDetails", { read: ListDetailsComponent, static: true })
  public payDetails: ListDetailsComponent;
  @ViewChild("cagesList", { read: ListDetailsComponent, static: true })
  public cagesList: ListDetailsComponent;

  payCages: CageVM[] = [];

  constructor(private payService: FarmerPayService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.farmerSelector.showBalance = true;
    this.getAllPays();
  }

  initForm() {
    this.payForm = this.fb.group({
      Amount: [0, [Validators.required]],
      PaymentReference: [''],
      GetPaidName: [''],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  selectFarmer(farmer: FarmerVM) {
    this.cagesSelector.getFarmerCages(farmer.UUID);
  }

  isValidForm(): boolean {
    return this.payForm.valid
      && this.paymentMethodSelector.selectorForm.valid
      && this.farmerSelector.farmerForm.valid
      && this.cagesSelector.selectedCages > 0;
  }

  getAllPays() {
    this.loading.show();
    this.payService.getAll()
      .subscribe((response: FarmerPayVM[]) => {
        if (response) {
          this.pays = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.pays);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Pagos a Ganaderos";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "FarmerName", title: "Ganadero" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "PaymentMethodName", title: "Método de Pago" },
      { field: "PaymentReference", title: "Referencia" },
      { field: "GetPaidName", title: "Recibió Pago" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.paymentMethodSelector.resetForm();
    this.farmerSelector.resetForm();
    this.cagesSelector.reset();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newPay: FarmerPayVM = Object.assign({
        CreateDate: new Date(),
        FarmerId: this.farmerSelector.getFarmerId(),
        FarmerName: this.farmerSelector.getFarmerName(),
        PaymentMethodId: this.paymentMethodSelector.getItemId(),
        PaymentMethodName: this.paymentMethodSelector.getItemInfo(),
        ListOfCages: this.cagesSelector.getIdsList(),
      }, this.payForm.value);
      this.payService.create(newPay)
        .subscribe((response: FarmerPayVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  select(pay: FarmerPayVM) {
    this.loading.show();
    this.payService.getById(pay.UUID)
      .subscribe((response: FarmerPayVM) => {
        if (response) {
          this.payDetails.detailsTitle = "Detalles";
          this.payDetails.details = [
            { title: "Fecha", value: response.CreateDate, isDate: true },
            { title: "Ganadero", value: response.FarmerName },
            { title: "Monto", value: response.Amount, isCurrency: true },
            { title: "Método de Pago", value: response.PaymentMethodName },
            { title: "Referencia", value: response.PaymentReference },
            { title: "Recibió Pago", value: response.GetPaidName },
          ];
          this.payDetails.showItems = true;

          this.payCages = response.Cages;
          this.cagesList.detailsTitle = "Jaulas";
          this.payCages.forEach(cage => {
            this.cagesList.details.push(
              { title: `${cage.Identifier}${cage.Id}`, value: cage.TotalCost, isCurrency: true },
            );
          });
          this.cagesList.showItems = true;

          this.showDetails = true;
          this.loading.hide();
        }
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

  edit(pay: FarmerPayVM) {
    this.loading.show();
    this.payService.getById(pay.UUID)
      .subscribe((response: FarmerPayVM) => {
        if (response) {
          this.payVM = response;
        }
        this.payForm.setValue({
          Amount: this.payVM.Amount,
          PaymentReference: this.payVM.PaymentReference,
          GetPaidName: this.payVM.GetPaidName,
        });
        this.paymentMethodSelector.setComboById(this.payVM.PaymentMethodId);
        this.farmerSelector.setComboById(this.payVM.FarmerId);
        this.cagesSelector.setSelectedItems(this.payVM.FarmerId, this.payVM.ListOfCages);
        this.isEdition = true;
        this.setFormVisibility();
      });
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedPay: FarmerPayVM = Object.assign({
        UUID: this.payVM.UUID,
        FarmerId: this.farmerSelector.getFarmerId(),
        FarmerName: this.farmerSelector.getFarmerName(),
        PaymentMethodId: this.paymentMethodSelector.getItemId(),
        PaymentMethodName: this.paymentMethodSelector.getItemInfo(),
        ListOfCages: this.cagesSelector.getIdsList(),
      }, this.payForm.value);

      this.payService.update(updatedPay)
        .subscribe((response: FarmerPayVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedPay);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(pay: FarmerPayVM) {
    this.payVM = pay;
    this.confirmDeleteCmp.confirmTitle = "Eliminar Pago";
    this.confirmDeleteCmp.confirmText = `Eliminar pago a: ${pay.FarmerName}`;
    this.showDelete = true;
  }

  confirmDelete(removeNotes: string) {
    this.loading.show();
    this.payVM.RemoveNotes = removeNotes;
    this.payService.deletePay(this.payVM)
      .subscribe((response: boolean) => {
        if (response) {
          this.grid.deleteRow(this.payVM.UUID);
          this.confirmDeleteCmp.RemoveNotes = "";
          this.showDelete = false;
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  cancelDelete(x) {
    this.showDelete = false;
  }

}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CageVM, GutPriceVM, GutVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { CageService } from '../../../services/cage.service';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { LocalData } from '../../../models/local.data';
import { Router } from '@angular/router';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';

@Component({
  selector: 'app-cage-guts',
  templateUrl: './cage-guts.component.html',
  styleUrls: ['./cage-guts.component.scss']
})
export class CageGutsComponent implements OnInit {

  cageVM: CageVM;
  guts: GutVM[] = [];

  cageId: string;

  totalPrice: number = 0;

  generalPrice: number = 0;

  constructor(private cageService: CageService,
    private alertify: AlertifyService,
    private router: Router,
    private loading: LoadingService) {

    if (localStorage.getItem(LocalData.SELECTED_CAGE_UUID) == null) {
      this.router.navigate(['home/cages']);
    }
    this.cageId = localStorage.getItem(LocalData.SELECTED_CAGE_UUID).toString();

  }

  ngOnInit(): void {
    this.getCageInfo();
  }

  getCageInfo() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.cageVM = response;
          this.guts = this.cageVM.Guts;
        }
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setPrice(gut: GutVM) {
    try {
      let w = parseFloat(gut.Price.toString());
      if (w != 0) {
        this.loading.show();
        this.cageService.updateGutPrice(gut)
          .subscribe((response: boolean) => {
            if (response) {
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
            console.log(err);
          });
      }
    } catch (e) {
      this.alertify.warning(`El valor especificado no es válido`);
      gut.Price = 0;
      this.loading.hide();
    }

  }

  setGeneralPrice() {
    try {
      this.loading.show();
      let price = parseFloat(this.generalPrice.toString());
      let gutPrice: GutPriceVM = {
        CageId: this.cageId,
        GeneralPrice:price
      };
      this.cageService.updateGutGeneralPrice(gutPrice)
        .subscribe((response: boolean) => {
          if (response) {
            this.guts.forEach(item => {
              item.Price = this.generalPrice;
            });
            this.loading.hide();
          }
        }, (err) => {
            this.loading.hide();
            console.log(err);
        });
    } catch (e) {
      this.loading.hide();
      this.alertify.warning(`El valor especificado no es válido`);
      this.generalPrice = 0;
    }
  }

  close() {
    this.router.navigate(['home/cage-details']);
  }

  // remove local storage on component destroy
  ngOnDestroy(): void {
    //localStorage.removeItem(LocalData.SELECTED_CAGE_UUID);
  }

}

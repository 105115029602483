import { Component, OnInit } from '@angular/core';
import { ClockModel } from './clock-model';
import { Observable } from 'rxjs';
import { ClockService } from './clock.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {
  datos$: Observable<ClockModel>;
  hora: number;
  minutos: string;
  dia: string;
  dia_movil: string;
  mes: string;
  diaDeMes: string;
  mes_movil: string;
  fecha: string;
  anyo: string;
  ampm: string;
  segundos: string;

  //date: Date = new Date();

  constructor(private clockService: ClockService) { }

  ngOnInit() {
    this.datos$ = this.clockService.getInfoReloj();
    this.datos$.subscribe(x => {
      this.hora = x.hora;
      this.minutos = x.minutos;
      this.dia = x.diadesemana;
      this.dia_movil = x.diadesemana.substring(0, 3);
      this.mes = x.mes;
      this.anyo = x.anyo;
      this.mes_movil = x.mes.substring(0, 3);
      this.fecha = x.diaymes;
      this.diaDeMes = x.diademes;
      this.ampm = x.ampm;
      this.segundos = x.segundo
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { AlertifyService } from '../../../../services/alertify.service';
import { LocationVM } from '../../../../models/ViewModels';
import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-locations-records',
  templateUrl: './locations-records.component.html',
  styleUrls: ['./locations-records.component.scss']
})
export class LocationsRecordsComponent implements OnInit {

  locations: LocationVM[] = [];
  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private locationService: LocationService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.getLocations();
  }

  getLocations() {
    this.loading.show();
    this.locationService.getDeleted()
      .subscribe((response: LocationVM[]) => {
        if (response) {
          this.locations = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.locations);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showActivateButton = true;
    this.grid.options.showExportExcelButton = false;
    this.grid.options.showExportPDFButton = false;
    this.grid.columns = [
      { field: "Alias", title: "Alias" },
      { field: "FullAddress", title: "Domicilio" },
    ];
    this.grid.setGridOptions();
  }

  activate(location: LocationVM) {
    this.alertify.confirm(
      `Activar ubicación: ${location.Alias}`,
      () => {
        this.loading.show();
        this.locationService.activate(location)
          .subscribe((response: boolean) => {
            if (Response) {
              this.grid.deleteRow(location.UUID);
            }
            this.loading.hide();
          }, (err) => {
            this.loading.hide();
          });
      });
  }

}

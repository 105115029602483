import { Component, OnInit, ViewChild } from '@angular/core';
import { CageSelectorComponent } from '../../../components/cage-selector/cage-selector.component';
import { AnimalVM, CageVM, SaleAnimalsVM } from '../../../models/ViewModels';
import { AnimalsSelectorComponent } from '../../../components/animals-selector/animals-selector.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { SaleAnimalsService } from '../../../services/sale-animals.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { CustomerSelectorComponent } from '../../../components/customer-selector/customer-selector.component';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  @ViewChild("customerSelector", { read: CustomerSelectorComponent, static: true })
  public customerSelector: CustomerSelectorComponent;

  @ViewChild("cageSelector", { read: CageSelectorComponent, static: true })
  public cageSelector: CageSelectorComponent;

  @ViewChild("animalsSelector", { read: AnimalsSelectorComponent, static: false })
  public animalsSelector: AnimalsSelectorComponent;

  saleForm: FormGroup;
  sales: SaleAnimalsVM[] = [];
  saleVM: SaleAnimalsVM;

  collapse: boolean = true;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  showForm: boolean = false;
  isEdition: boolean = false;
  totalCost: number = 0;

  constructor(private salesService: SaleAnimalsService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private router: Router,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllSales();
    this.onFormChanges();
  }

  onFormChanges(): void {
    this.saleForm.valueChanges
      .subscribe(val => {
        this.totalCost = val.KiloCost * this.animalsSelector.totalWeight;
      });
  }

  initForm() {
    this.saleForm = this.fb.group({
      KiloCost: [0, [Validators.required]],
      NoteNumber: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.saleForm.valid
      && this.customerSelector.selectorForm.valid
      && this.cageSelector.selectorForm.valid
      && this.animalsSelector.isValid();
  }

  getAllSales() {
    this.loading.show();
    this.salesService.getAll()
      .subscribe((response: SaleAnimalsVM[]) => {
        if (response) {
          this.sales = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.sales);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Ventas";
    this.grid.options.showDetailsButton = true;
    this.grid.options.showEditButton = false;
    this.grid.columns = [
      { field: "Date", title: "Fecha", dateType: true },
      { field: "CustomerName", title: "Cliente" },
      { field: "AnimalsQuantity", title: "Cantidad" },
      { field: "Weight", title: "Peso (Kg)" },
      { field: "KiloCost", title: "Precio Kg", currencyType: true },
      { field: "Total", title: "Total", currencyType: true },
      { field: "NoteNumber", title: "Nota" },

    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.totalCost = 0;
    this.customerSelector.resetForm();
    this.cageSelector.resetForm();
    this.animalsSelector.reset();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.animalsSelector.showItems = false;
      this.loading.show();
      const newSale: SaleAnimalsVM = Object.assign({
        CustomerId: this.customerSelector.getCustomerId(),
        CustomerName: this.customerSelector.selectedValue.FullName,
        Date: new Date(),
        AnimalsQuantity: this.animalsSelector.selectedQuantity,
        Weight: this.animalsSelector.totalWeight,
        Total: this.totalCost,
        CageId: this.cageSelector.getItemId(),
        CageInfo: this.cageSelector.getItemInfo(),
        AnimalsArray: this.animalsSelector.getAnimalsArray(),
        AnimalsList: this.animalsSelector.animalsList,
        Paid: false,
      }, this.saleForm.value);
      
      this.salesService.create(newSale)
        .subscribe((response: SaleAnimalsVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
            console.log(err);
            this.loading.hide();
        });

    }
  }

  // Grid events

  select(sale: SaleAnimalsVM) {
    localStorage.setItem(LocalData.SELECTED_SALE_UUID, sale.UUID);
    this.router.navigate(['home/sale-details']);
  }

  update() { }

  delete(sale: SaleAnimalsVM) { }

  selectCage(cage: CageVM) {
    this.animalsSelector.cageId = cage.UUID;
    this.animalsSelector.getAnimals();
  }

  selectAnimal(totalWeight: number) {
    let kiloCost: number = this.saleForm.value["KiloCost"];
    this.totalCost = totalWeight * kiloCost;
  }

}

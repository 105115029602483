
<div *ngIf="cageVM">
  <h4>
    Peso de Animales ({{cageVM.Identifier}}{{cageVM.Id}})
  </h4>

  <hr />

  <div class="row __weight">
    <div class="col-md-8 col-sm-12 mx-auto">
      <div class="form-control d-flex justify-content-between">
        <b>Peso Total:</b>
        <b>{{ totalWeight | number }} Kg</b>
      </div>
    </div>
  </div>

  <div class="row"
       *ngIf="animals">
    <div class="col-md-2 col-sm-6 d-flex justify-content-between __inputContainer"
         *ngFor="let animal of animals">

      <div class="row">
        <div class="col-3">
          <small>{{animal.AnimalInfo}}</small>
        </div>
        <div class="col-9">
          <input type="number"
                 class="form-control-sm border-0"
                 [(ngModel)]="animal.Weight"
                 (focusout)="setLocalWeight(animal)"
                 placeholder="Peso {{animal.AnimalInfo}}" />
        </div>
      </div>

    </div>
  </div>

  <hr />

  <div class="text-right">
    <button class="btn btn-primary"
            type="button"
            (click)="save()">
      Guardar
    </button>
    <button class="btn btn-secondary"
            type="button"
            (click)="close()">
      Siguiente
    </button>
  </div>
</div>

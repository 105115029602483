import { Component, OnInit, ViewChild } from '@angular/core';
import { CutVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { CutService } from '../../services/cut.service';

@Component({
  selector: 'app-cut-selector',
  templateUrl: './cut-selector.component.html',
  styleUrls: ['./cut-selector.component.scss']
})
export class CutSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un corte...";
  items: CutVM[];
  cutForm: FormGroup;
  //public loadingTemplate: TemplateRef<any>;
  public loading = false;
  selectedItem: string;
  public selectedCut: CutVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  constructor(private cutService: CutService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadCutsCombo();

  }

  initStateForm() {
    this.cutForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedCut = this.items.find(item => item.Name == eventArgs.newSelection.value);
    this.cutForm.get('ComboValue').setValue(this.selectedCut.Name);
  }

  public loadCutsCombo(): void {
    this.initStateForm();

    this.cutService.getAll()
      .subscribe((response: CutVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedCut = this.items.find(item => item.UUID == id);
      this.cutForm.get('ComboValue').setValue(this.selectedCut.Name);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedCut = this.items.find(item => item.Name == name);
      this.cutForm.get('ComboValue').setValue(this.selectedCut.Name);
    }
  }

  public getCutName(): string {
    if (this.selectedCut)
      return this.selectedCut.Name;

    return null;
  }

  public getCutId(): string {
    if (this.selectedCut)
      return this.selectedCut.UUID;

    return null;
  }

  resetForm() {
    this.initStateForm();
    this.selectedCut = null;
  }

  openDropDown() {

  }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {
  IgxInputGroupModule,
  IgxFocusModule,
  IgxButtonModule,
  IgxGridModule,
  IgxCheckboxModule,
  IgxSwitchModule,
  IgxIconModule,
  IgxRippleModule,
  IgxFilterModule,
  IgxSelectModule,
  IgxColumnHidingModule,
  IgxDropDownModule,
  IgxTabsModule,
  IgxDialogModule,
  IgxAutocompleteModule
} from 'igniteui-angular';

//Kendo Grid
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './views/landing/landing.component';
import { HomeComponent } from './views/home/home.component';
import { UsersComponent } from './views/home/users/users.component';
import { LoginComponent } from './views/landing/login/login.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BearerAuthInterceptor } from './interceptors/bearer-auth.interceptor';
import { LoadingComponent } from './components/loading/loading.component';
import { GenericGridComponent } from './components/generic-grid/generic-grid.component';
import { MenuComponent } from './views/menu/menu.component';
import { RolesComponent } from './views/home/roles/roles.component';
import { CustomersComponent } from './views/home/customers/customers.component';
import { CagesComponent } from './views/home/cages/cages.component';
import { FarmersComponent } from './views/home/farmers/farmers.component';
import { AnimalsComponent } from './views/home/animals/animals.component';
import { StateSelectorComponent } from './components/state-selector/state-selector.component';
import { FarmerSelectorComponent } from './components/farmer-selector/farmer-selector.component';
import { CageDetailsComponent } from './views/home/cage-details/cage-details.component';
import { CageIdentifierSelectorComponent } from './components/cage-identifier-selector/cage-identifier-selector.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { SalesComponent } from './views/home/sales/sales.component';
import { LocationsComponent } from './views/home/locations/locations.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CustomerSelectorComponent } from './components/customer-selector/customer-selector.component';
import { CageSelectorComponent } from './components/cage-selector/cage-selector.component';
import { AnimalsSelectorComponent } from './components/animals-selector/animals-selector.component';
import { SaleDetailsComponent } from './views/home/sale-details/sale-details.component';
import { HeaderDetailsComponent } from './components/header-details/header-details.component';
import { ListDetailsComponent } from './components/list-details/list-details.component';
import { CageAnimalsComponent } from './views/home/cage-animals/cage-animals.component';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { AssignLocationComponent } from './views/home/assign-location/assign-location.component';
import { SquareDetailsComponent } from './components/square-details/square-details.component';
import { AnimalsDetailsComponent } from './components/animals-details/animals-details.component';
import { FarmerPayComponent } from './views/home/farmer-pay/farmer-pay.component';
import { CustomerPayComponent } from './views/home/customer-pay/customer-pay.component';
import { PaymentMethodSelectorComponent } from './components/payment-method-selector/payment-method-selector.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { MultiSalesSelectorComponent } from './components/multi-sales-selector/multi-sales-selector.component';
import { MultiCagesSelectorComponent } from './components/multi-cages-selector/multi-cages-selector.component';
import { CageGutsComponent } from './views/home/cage-guts/cage-guts.component';
import { GutsDetailsComponent } from './components/guts-details/guts-details.component';
import { GutsSelectorComponent } from './components/guts-selector/guts-selector.component';
import { AnimalsSalesComponent } from './views/home/animals-sales/animals-sales.component';
import { GutsSalesComponent } from './views/home/guts-sales/guts-sales.component';
import { AnimalSaleDetailsComponent } from './views/home/animal-sale-details/animal-sale-details.component';
import { GutSaleDetailsComponent } from './views/home/gut-sale-details/gut-sale-details.component';
import { GenericSelectorComponent } from './components/generic-selector/generic-selector.component';
import { EmployeesComponent } from './views/home/employees/employees.component';
import { ButcherySelectorComponent } from './components/butchery-selector/butchery-selector.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { CheckComponent } from './views/check/check.component';
import { RegisterComponent } from './views/check/register/register.component';
import { SelectorComponent } from './views/check/selector/selector.component';
import { ClockComponent } from './components/clock/clock.component';
import { GridComponent } from './components/grid/grid.component';
import { CustomGridComponent } from './components/custom-grid/custom-grid.component';
import { exportPDF } from '@progress/kendo-drawing';
import { PayrollComponent } from './views/home/payroll/payroll.component';
import { ButcheriesComponent } from './views/home/butcheries/butcheries.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { PayrollFormComponent } from './views/home/payroll/payroll-form/payroll-form.component';
import { EmployeeSelectorComponent } from './components/employee-selector/employee-selector.component';
import { LoansComponent } from './views/home/loans/loans.component';
import { EmployeeDetailsComponent } from './views/home/employees/employee-details/employee-details.component';
import { CutSelectorComponent } from './components/cut-selector/cut-selector.component';
import { SimpleGridComponent } from './components/simple-grid/simple-grid.component';
import { RolSelectorComponent } from './components/rol-selector/rol-selector.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { ChangePasswordComponent } from './views/home/change-password/change-password.component';
import { SearchPipe } from './pipes/search.pipe';
import { DeletedRecordsComponent } from './views/home/deleted-records/deleted-records.component';
import { CustomersRecordsComponent } from './views/home/deleted-records/customers-records/customers-records.component';
import { FarmersRecordsComponent } from './views/home/deleted-records/farmers-records/farmers-records.component';
import { LocationsRecordsComponent } from './views/home/deleted-records/locations-records/locations-records.component';
import { ButcheriesRecordsComponent } from './views/home/deleted-records/butcheries-records/butcheries-records.component';
import { EmployeesRecordsComponent } from './views/home/deleted-records/employees-records/employees-records.component';
import { StartsWithPipe } from './pipes/starts-with.pipe';
import { SearchCustomerComponent } from './components/search-customer/search-customer.component';
import { SoldPipe } from './pipes/sold.pipe';
import { ForCutPipe } from './pipes/for-cut.pipe';
import { CreditsComponent } from './views/home/credits/credits.component';
import { CreateDatePipe } from './pipes/create-date.pipe';
import { SkinDetailsComponent } from './components/skin-details/skin-details.component';
import { CagesReportComponent } from './views/home/reports/cages-report/cages-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    UsersComponent,
    LoginComponent,
    NotFoundComponent,
    LoadingComponent,
    GenericGridComponent,
    MenuComponent,
    RolesComponent,
    CustomersComponent,
    CagesComponent,
    FarmersComponent,
    AnimalsComponent,
    StateSelectorComponent,
    FarmerSelectorComponent,
    CageDetailsComponent,
    CageIdentifierSelectorComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SalesComponent,
    LocationsComponent,
    CustomerSelectorComponent,
    CageSelectorComponent,
    AnimalsSelectorComponent,
    SaleDetailsComponent,
    HeaderDetailsComponent,
    ListDetailsComponent,
    CageAnimalsComponent,
    LocationSelectorComponent,
    AnimalsDetailsComponent,
    AssignLocationComponent,
    SquareDetailsComponent,
    FarmerPayComponent,
    CustomerPayComponent,
    PaymentMethodSelectorComponent,
    ConfirmDeleteComponent,
    MultiSalesSelectorComponent,
    MultiCagesSelectorComponent,
    CageGutsComponent,
    GutsDetailsComponent,
    GutsSelectorComponent,
    AnimalsSalesComponent,
    GutsSalesComponent,
    AnimalSaleDetailsComponent,
    GutSaleDetailsComponent,
    GenericSelectorComponent,
    EmployeesComponent,
    ButcherySelectorComponent,
    DateSelectorComponent,
    CheckComponent,
    RegisterComponent,
    SelectorComponent,
    ClockComponent,
    GridComponent,
    CustomGridComponent,
    PayrollComponent,
    ButcheriesComponent,
    DatePickerComponent,
    DateRangeComponent,
    PayrollFormComponent,
    EmployeeSelectorComponent,
    LoansComponent,
    EmployeeDetailsComponent,
    CutSelectorComponent,
    SimpleGridComponent,
    RolSelectorComponent,
    UserSelectorComponent,
    ChangePasswordComponent,
    SearchPipe,
    DeletedRecordsComponent,
    CustomersRecordsComponent,
    FarmersRecordsComponent,
    LocationsRecordsComponent,
    ButcheriesRecordsComponent,
    EmployeesRecordsComponent,
    StartsWithPipe,
    SearchCustomerComponent,
    SoldPipe,
    ForCutPipe,
    CreditsComponent,
    CreateDatePipe,
    SkinDetailsComponent,
    CagesReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    // Kendo
    GridModule,
    PDFModule,
    ExcelModule,
    DialogsModule,

    IgxInputGroupModule,
    IgxFocusModule,
    IgxButtonModule,
    IgxGridModule,
    IgxTabsModule,
    IgxCheckboxModule,
    IgxSwitchModule,
    IgxIconModule,
    IgxRippleModule,
    IgxFilterModule,
    IgxSelectModule,
    IgxColumnHidingModule,
    IgxDropDownModule,
    IgxInputGroupModule,
    IgxDialogModule,
    IgxAutocompleteModule,


  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

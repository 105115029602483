
<h4>
  Registros Eliminados
</h4>

<hr />


<igx-tabs>

  <igx-tab-item label="Clientes"
                routerLink="customers"
                routerLinkActive
                #rla1="routerLinkActive"
                [isSelected]="rla1.isActive">
  </igx-tab-item>

  <igx-tab-item label="Ganaderos"
                routerLink="farmers"
                routerLinkActive
                #rla2="routerLinkActive"
                [isSelected]="rla2.isActive">
  </igx-tab-item>

  <igx-tab-item label="Empleados"
                routerLink="employees"
                routerLinkActive
                #rla3="routerLinkActive"
                [isSelected]="rla3.isActive">
  </igx-tab-item>

  <igx-tab-item label="Ubicaciones"
                routerLink="locations"
                routerLinkActive
                #rla4="routerLinkActive"
                [isSelected]="rla4.isActive">
  </igx-tab-item>

  <igx-tab-item label="Carnicerías"
                routerLink="butcheries"
                routerLinkActive
                #rla5="routerLinkActive"
                [isSelected]="rla5.isActive">
  </igx-tab-item>

</igx-tabs>

<router-outlet></router-outlet>

import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateToString } from '../../helpers/help-methods';
import { LocationAndDateDto } from '../../models/dtos';
import { AnimalForCut } from '../../models/LocalModels';
import { AnimalVM, CageVM, CutVM, SaleAnimalsVM, SaleCutVM } from '../../models/ViewModels';
import { AlertifyService } from '../../services/alertify.service';
import { CageService } from '../../services/cage.service';
import { HelpService } from '../../services/help.service';
import { CutSelectorComponent } from '../cut-selector/cut-selector.component';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-animals-selector',
  templateUrl: './animals-selector.component.html',
  styleUrls: ['./animals-selector.component.scss']
})
export class AnimalsSelectorComponent implements OnInit {

  items: AnimalVM[] = [];
  allItems: AnimalVM[] = [];
  cuts: AnimalVM[] = [];
  saleCuts: SaleCutVM[] = [];
  animals: AnimalVM[] = [];
  showItems: boolean = false;
  cageId: string = "";
  locationId: string = "";
  totalWeight: number = 0;
  showTotalWeight: boolean = true;
  selectedQuantity: number = 0;
  selectedCuts: number = 0;
  cutsPrice: number = 0;

  selectorButton: string;
  collapse: boolean = true;
  wCollapse: boolean = true;
  cCollapse: boolean = true;
  hasCuts: boolean = true;

  @Output()
  onAnimalSelected: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onCutGridAction: EventEmitter<number> = new EventEmitter<number>();

  animalsList: string = "";
  animalsArray: string[];

  forSale: boolean = true;

  @ViewChild("cutSelector", { read: CutSelectorComponent, static: false })
  public cutSelector: CutSelectorComponent;

  cutForm: FormGroup;

  searchQuery: string = "";
  hideSold: boolean = false;
  hideCuts: boolean = false;

  toggleOptions: boolean = true;
  filterCages: string[] = [];
  filteredCage: string = "";

  animalsForCut: AnimalForCut[] = [];
  cutDate: AnimalForCut;

  @ViewChild("filterByDate", { read: DatePickerComponent })
  public filterByDate: DatePickerComponent;
  showFilterByDate: boolean = false;
  defaultDate: Date = new Date(2000, 0, 1, 0, 0, 0);
  filterDate: Date = new Date();

  constructor(private cageService: CageService,
    private helpService: HelpService,
    private fb: FormBuilder,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initCutForm();
  }

  cutFormValid(): boolean {
    if (this.forSale) {
      return this.cutForm.valid;
      //&& this.cutSelector.cutForm.valid;
    }
    return false;
  }

  initCutForm() {
    this.cutForm = this.fb.group({
      Weight: [0, [Validators.required]],
      KiloCost: [0, [Validators.required]],
    });
  }

  getAnimals() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.items = response.Animals;
          this.cuts = this.items.filter(item => item.IsForCut == true);
          this.hasCuts = this.cuts.length > 0;

          this.showItems = true;
          this.collapse = false;
          this.loading.hide();
        }
      }, (err) => {

      });
  }

  clear() {
    this.animals = [];
    this.items = [];
    this.allItems = [];
    this.cuts = [];
    this.hasCuts = false;
    this.showItems = false;
    this.collapse = true;
    this.hideCuts = false;
    this.hideSold = false;
  }

  getAnimalsFromLocationAndDate(date: Date) {
    if (this.locationId !== "") {
      this.loading.show();
      this.clear();
      const locationAndDate: LocationAndDateDto = {
        LocationId: this.locationId,
        Date: DateToString(date)
      };
      this.cageService.getAnimalsToSaleFromLocationAndDate(locationAndDate)
        .subscribe((response: AnimalVM[]) => {
          if (response) {
            response.forEach(item => {
              item.UpdateDate = new Date(item.UpdateDate);
              item.CreateDate = new Date(item.CreateDate);
            });
            this.items = response;
            this.allItems = response;
            this.cuts = this.items.filter(item => item.IsForCut == true);
            this.hasCuts = this.cuts.length > 0;
            this.getCagesToFilter();
            this.getAnimalsForCut();

            this.showItems = this.items.length > 0;
            this.collapse = false;
          } else {

          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  onHideSold() {
    this.hideSold = !this.hideSold;
    //this.items = this.allItems.filter(item => item.Sold === !this.hideSold);
  }

  onHideCuts() {
    this.hideCuts = !this.hideCuts;
    //this.items = this.allItems.filter(item => item.IsForCut === !this.hideCuts);
  }

  onShowDateFilter() {
    this.showFilterByDate = !this.showFilterByDate;
  }

  dateChanged(d: Date) {
    this.filterDate = d;
  }

  selectItem(item: AnimalVM) {
    if (!item.Sold) {
      item.IsSelected = !item.IsSelected;
      this.pushAnimal(item);
      this.calculateTotalWeight();
    }
  }

  changeWeight(animal: AnimalVM) {
    this.items.find(item => item.UUID == animal.UUID).SaleWeight = animal.SaleWeight;
    this.calculateTotalWeight();
  }

  pushAnimal(animal: AnimalVM) {
    let exists = this.animals.find(item => item.UUID == animal.UUID);
    if (exists) {
      let idx = this.animals.indexOf(exists);
      this.animals.splice(idx, 1);
    } else {
      this.animals.push(animal);
    }
  }

  calculateTotalWeight() {
    this.showTotalWeight = false;

    this.totalWeight = 0;
    this.selectedQuantity = 0;
    this.animalsArray = [];
    this.animalsList = "";

    this.items.forEach(item => {
      if (item.IsSelected) {
        this.totalWeight += item.SaleWeight;
        this.selectedQuantity++;
        this.animalsArray.push(item.AnimalInfo);
        this.animalsList += `${item.AnimalInfo}, `;
      }
    });

    this.onAnimalSelected.emit(this.totalWeight);
    this.showTotalWeight = true;
  }

  isValid(): boolean {
    return ((this.selectedQuantity > 0 && this.totalWeight > 0)
      || (this.saleCuts.length > 0 && this.cutsPrice > 0));
  }

  isValidForLocation(): boolean {
    return this.selectedQuantity > 0;
  }

  getAnimalsArray(): string[] {
    let array: string[] = [];

    this.items.forEach(item => {
      if (item.IsSelected) {
        array.push(item.Id.toString());
      }
    });

    return array;
  }

  reset() {
    this.items = [];
    this.showItems = false;
    this.totalWeight = 0;
    this.selectedQuantity = 0;
    this.saleCuts = [];
    this.cutsPrice = 0;
    this.animalsForCut = [];
  }

  // Methods for location assignment

  selectForLocation(item: AnimalVM) {
    if (!item.Sold) {
      item.IsSelected = !item.IsSelected;
      this.calculateQuantity();
    }
  }

  selectAll() {
    this.items.forEach(item => {
      if (!item.Sold)
        item.IsSelected = true;
    });
    this.calculateQuantity();
  }

  calculateQuantity() {
    this.showTotalWeight = false;
    this.selectedQuantity = this.items.filter(item => item.IsSelected == true).length;

    this.showTotalWeight = true;
  }

  getSelectedAnimals(): AnimalVM[] {
    return this.items.filter(item => item.IsSelected == true);
  }

  addCut() {
    if (this.cutFormValid
      && this.cutDate !== null
      && this.cutSelector.cutForm.valid) {
      let weight: number = this.cutForm.value["Weight"];
      let kiloCost: number = this.cutForm.value["KiloCost"];
      let total: number = weight * kiloCost;

      this.saleCuts.push({
        CutDate: this.cutDate.CreateDate,
        CageId: this.cutDate.CageUUID,
        CutId: this.cutSelector.getCutId(),
        CutName: this.cutSelector.getCutName(),
        Weight: weight,
        KiloCost: kiloCost,
        Total: total
      });

      this.cutsPrice += total;
      this.onCutGridAction.emit(this.cutsPrice);

      this.initCutForm();
      this.cutSelector.resetForm();
      this.cutDate = null;
    }
  }

  deleteCut(cut: SaleCutVM) {
    let idx = this.saleCuts.indexOf(cut);
    this.saleCuts.splice(idx, 1);
    this.cutsPrice = this.cutsPrice - cut.Total;
    this.onCutGridAction.emit(this.cutsPrice);
  }

  getCuts(customerId: string) {
    this.saleCuts.forEach(item => {
      item.CustomerId = customerId;
    });
    return this.saleCuts;
  }

  getCagesToFilter() {
    this.items.forEach(item => {
      if (!this.filterCages.includes(item.CageInfo)) {
        this.filterCages.push(item.CageInfo);
      }
    });
  }

  getAnimalsForCut() {
    let refDate: Date = new Date();
    this.items.filter(item => item.IsForCut == true).forEach(item => {
      let _afc: AnimalForCut = {
        CageUUID: item.CageUuid,
        CageInfo: item.CageInfo,
        CreateDate: item.UpdateDate,
        CutDate: this.helpService.setDateToStartTime(new Date(item.UpdateDate), refDate)
      };

      if (!this.animalsForCut.some(e => e.CageUUID === _afc.CageUUID && e.CutDate === _afc.CutDate)) {
        this.animalsForCut.push(_afc);
      }
    });

  }

  // sale edition methods

  setSaleAnimalsAndCuts(soldAnimals: AnimalVM[], _saleCuts: SaleCutVM[]) {
    this.loading.show();
    this.clear();
    this.cageService.getAnimalsToSaleFromLocation(this.locationId)
      .subscribe((response: AnimalVM[]) => {
        if (response) {
          response.forEach(item => {
            item.UpdateDate = new Date(item.UpdateDate);
          });

          soldAnimals.forEach(item => {
            response.find(x => x.UUID === item.UUID).Sold = false;
            response.find(x => x.UUID === item.UUID).IsSelected = true;
            this.pushAnimal(item);
          });

          this.items = response;
          this.allItems = response;

          this.cuts = this.items.filter(item => item.IsForCut == true);
          this.hasCuts = this.cuts.length > 0;
          this.getCagesToFilter();
          this.getAnimalsForCut();

          _saleCuts.forEach(item => {
            this.saleCuts.push({
              CutDate: item.CutDate,
              CageId: item.CageId,
              CutId: item.CutId,
              CutName: item.CutName,
              Weight: item.Weight,
              KiloCost: item.KiloCost,
              Total: item.Total,
            });

            this.cutsPrice += item.Total;
          });

          this.showItems = this.items.length > 0;
          this.collapse = false;
        } else {

        }
        this.loading.hide();
        this.calculateTotalWeight();
      }, (err) => {
        this.loading.hide();
      });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ButcheryVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { ButcheryService } from '../../../services/butchery.service';
import { UserSelectorComponent } from '../../../components/user-selector/user-selector.component';

@Component({
  selector: 'app-butcheries',
  templateUrl: './butcheries.component.html',
  styleUrls: ['./butcheries.component.scss']
})
export class ButcheriesComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  butcheries: ButcheryVM[] = [];
  butcheryVM: ButcheryVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  butcheryForm: FormGroup;

  @ViewChild("userSelector", { read: UserSelectorComponent, static: false })
  public userSelector: UserSelectorComponent;

  constructor(private butcheryService: ButcheryService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllButcheries();
  }

  initForm() {
    this.butcheryForm = this.fb.group({
      Name: ['', [Validators.required, Validators.minLength(3)]],
      StringTime: ['', [Validators.required]],
      DelayMinutes: [0, [Validators.required]],



    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.butcheryForm.valid;
  }

  getAllButcheries() {
    this.loading.show();
    this.butcheryService.getAll()
      .subscribe((response: ButcheryVM[]) => {
        if (response) {
          this.butcheries = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.butcheries);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Carnicerías";
    this.grid.columns = [
      { field: "Name", title: "Nombre" },
      { field: "StringTime", title: "Hora de Entrada" },
      { field: "DelayMinutes", title: "Minutos de Tolerancia" },
      { field: "UserName", title: "Usuario" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.userSelector.resetForm();
    this.initForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newButchery: ButcheryVM = Object.assign({
        UserId: this.userSelector.getUserId(),
        UserName: this.userSelector.getUsername()
      }, this.butcheryForm.value);

      this.butcheryService.create(newButchery)
        .subscribe((response: ButcheryVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(butchery: ButcheryVM) {
    this.butcheryVM = butchery;
    this.butcheryForm.setValue({
      Name: this.butcheryVM.Name,
      StringTime: this.butcheryVM.StringTime,
      DelayMinutes: this.butcheryVM.DelayMinutes,
    });
    this.userSelector.setComboById(this.butcheryVM.UserId);
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedButchery: ButcheryVM = Object.assign({
        UUID: this.butcheryVM.UUID,
        UserId: this.userSelector.getUserId(),
        UserName: this.userSelector.getUsername()
      }, this.butcheryForm.value);

      this.butcheryService.update(updatedButchery)
        .subscribe((response: ButcheryVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedButchery);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(butchery: ButcheryVM) {
    this.alertify.confirm(
      `Eliminar carnicería: ${butchery.Name}`,
      () => {
        this.loading.show();
        this.butcheryService.delete(butchery.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(butchery.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  formatTime(date: Date): string {
    let result: string = "";
    let hours: string = date.getHours().toString();
    let minutes: string = date.getMinutes().toString();

    if (date.getHours() < 10) {
      hours = "0" + hours;
    }

    if (date.getMinutes() < 10) {
      minutes = "0" + minutes;
    }

    result = hours + ":" + minutes;

    return result;
  }


}

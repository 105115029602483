import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { OperationResult, EmployeeLoanVM } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class LoansService {

  loanApi = `${environment.apiUrl}/EmployeeLoan`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(loan: EmployeeLoanVM) {
    return this.http.post(this.loanApi, loan).pipe(
      map((response: OperationResult<EmployeeLoanVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(loan: EmployeeLoanVM) {
    return this.http.put(this.loanApi, loan).pipe(
      map((response: OperationResult<EmployeeLoanVM>) => {
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.loanApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.loanApi).pipe(
      map((response: OperationResult<EmployeeLoanVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getFromEmployee(employeeId: string) {
    const uri = `${this.loanApi}/GetFromEmployee?employeeId=${employeeId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<EmployeeLoanVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.loanApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<EmployeeLoanVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor() { }

  // Set time to 00:00:00.0000 from a date object
  setDateToStartTime(date: Date, refDate: Date): Date {
    refDate.setFullYear(date.getFullYear());
    refDate.setMonth(date.getMonth());
    refDate.setDate(date.getDate());
    return refDate;
    //return new Date(
    //  date.getFullYear(),
    //  date.getMonth(),
    //  date.getDate(),
    //  0,0,0,0);
  }
}

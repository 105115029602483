

<h5 class="__header"
    (click)="collapse=!collapse">
  Córtes ({{ cutsQuantity }}): {{ cutsPrice | currency }}
  &nbsp;&nbsp;&nbsp;
  <i class="fa fa-caret-up" *ngIf="!collapse"></i>
  <i class="fa fa-caret-down" *ngIf="collapse"></i>
</h5>

<div [ngClass]="{'collapse': collapse}">

  <kendo-grid [data]="cuts">

    <kendo-grid-column field="CutName" title="Corte">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>{{ dataItem.CutName }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Weight" title="Peso">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>{{ dataItem.Weight | number }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="KiloCost" title="Precio por Kilo">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>{{ dataItem.KiloCost | currency }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Total" title="Total">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>{{ dataItem.Total | currency }}</div>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>

</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-skin-details',
  templateUrl: './skin-details.component.html',
  styleUrls: ['./skin-details.component.scss']
})
export class SkinDetailsComponent  {

  skinPrice: number = 0;
  skinWeight: number = 0;
  skinTotal: number = 0;

  constructor() { }

}

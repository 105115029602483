
<div [hidden]="showForm">
  <h4>
    Clientes
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

<hr />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Cliente</h5>
      <h5 *ngIf="isEdition">Editar Cliente</h5>

      <hr />
      <br />

      <form [formGroup]="customerForm">

        <igx-input-group>
          <input igxInput name="FullName"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': customerForm.get('FullName').errors && customerForm.get('FullName').touched}"
                 type="text"
                 aria-label="Nombre Completo"
                 formControlName="FullName" />
          <label igxLabel for="FullName">Nombre Completo</label>
        </igx-input-group>

        <br />

        <a class="btn btn-outline-secondary btn-block d-flex justify-content-between align-items-center"
           (click)="collapseTelephoneForm=!collapseTelephoneForm">
          Datos de Contacto
          &nbsp;&nbsp;
          <i class="fa"
             [ngClass]="{'fa-caret-up':!collapseTelephoneForm, 'fa-caret-down': collapseTelephoneForm}"></i>
        </a>
        <div [ngClass]="{'collapse': collapseTelephoneForm}">
          <igx-input-group>
            <input igxInput name="Telephone"
                   [igxFocus]="!collapseTelephoneForm"
                   maxlength="10"
                   type="tel"
                   aria-label="Teléfono"
                   formControlName="Telephone" />
            <label igxLabel for="Telephone">Teléfono</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="MobilePhone"
                   maxlength="10"
                   type="tel"
                   aria-label="Celular"
                   formControlName="MobilePhone" />
            <label igxLabel for="MobilePhone">Celular</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="Email"
                   type="email"
                   aria-label="Correo Electrónico"
                   formControlName="Email" />
            <label igxLabel for="Email">Correo Electrónico</label>
          </igx-input-group>
          <hr />
        </div>

        <br /><br />

        <a class="btn btn-outline-secondary btn-block d-flex justify-content-between align-items-center"
           (click)="collapseAddressForm=!collapseAddressForm">
          <span>Domicilio</span>
          &nbsp;&nbsp;
          <i class="fa"
             [ngClass]="{'fa-caret-up':!collapseAddressForm, 'fa-caret-down': collapseAddressForm}"></i>
        </a>
        <div [ngClass]="{'collapse': collapseAddressForm}">
          <igx-input-group>
            <input igxInput name="Address1"
                   [igxFocus]="!collapseAddressForm"
                   type="text"
                   aria-label="Domicilio 1"
                   formControlName="Address1" />
            <label igxLabel for="Address1">Domicilio 1</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="Address2"
                   type="text"
                   aria-label="Domicilio 2"
                   formControlName="Address2" />
            <label igxLabel for="Address2">Domicilio 2</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="ZipCode"
                   type="text"
                   maxlength="5"
                   aria-label="Código Postal"
                   formControlName="ZipCode" />
            <label igxLabel for="ZipCode">Código Postal</label>
          </igx-input-group>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <igx-input-group>
                <input igxInput name="City"
                       type="text"
                       aria-label="Ciudad"
                       formControlName="City" />
                <label igxLabel for="City">Ciudad</label>
              </igx-input-group>
            </div>

            <div class="col-md-6 col-sm-12">
              <app-state-selector #stateSelector></app-state-selector>
            </div>
          </div>
          <hr />
        </div>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

import { Pipe, PipeTransform } from '@angular/core';
import { AnimalVM } from '../models/ViewModels';

export interface FilterDateArgs {
  ActiveFilter: boolean;
  FilterDate: Date;
}

@Pipe({
  name: 'createDate'
})
export class CreateDatePipe implements PipeTransform {

  defaultDate: Date = new Date(2000, 0, 1, 0, 0, 0);
  startDate: Date = new Date();
  endDate: Date = new Date();

  setStartDate(date: Date) {
    const d = new Date(date);
    d.setHours(0, 0, 0);
    this.startDate = d;
  }

  setEndDate(date: Date) {
    const d = new Date(date);
    d.setHours(23, 59, 59);
    this.endDate = d;
  }
  private
  transform(animals: AnimalVM[], args: FilterDateArgs): AnimalVM[] {
    if (!animals) {
      return;
    }
    if (!args.ActiveFilter) {
      return animals;
    }
    this.setStartDate(args.FilterDate);
    this.setEndDate(args.FilterDate);
    return animals.filter(a =>
      a.UpdateDate.getTime() >= this.startDate.getTime()
      && a.UpdateDate.getTime() <= this.endDate.getTime()
    );
  }

}


<div class="clock-container">
  <br />
  
  <div class="row">
    <div class="col-sm-4 text-center date">
      {{dia}}
    </div>
    <div class="col-sm-4 text-center date">
      {{fecha}}
    </div>
    <div class="col-sm-4 text-center date">
      {{anyo}}
    </div>
  </div>

  <div class="clock">
    <div class="numbers">
      {{hora}}
    </div>
    <div class="numbers">
      : {{minutos}}
    </div>
    <div class="numbers">
      : {{segundos}}
    </div>
    <div class="numbers">
      {{ampm}}
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { RolVM } from '../../models/ViewModels';
import { RolService } from '../../services/rol.service';

@Component({
  selector: 'app-rol-selector',
  templateUrl: './rol-selector.component.html',
  styleUrls: ['./rol-selector.component.scss']
})
export class RolSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un rol...";
  items: RolVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: RolVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onRolSelect: EventEmitter<RolVM> = new EventEmitter<RolVM>();

  constructor(private rolService: RolService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.rolService.getAll()
      .subscribe((response: RolVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = this.selectedValue.Name;
    this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    this.onRolSelect.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectedItem = this.selectedValue.Name;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedValue = this.items.find(item => item.Name == name);
      this.selectedItem = this.selectedValue.Name;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public getRolName(): string {
    if (this.selectedValue)
      return this.selectedValue.Name;

    return null;
  }

  public getRolId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
    this.selectedItem = "";
  }

  openDropDown() {

  }


}

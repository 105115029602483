
<div class="row">
  <div class="col-md-6 col-sm-12 mx-auto">

    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <b>Precio de Piel</b>
        <span class="badge">
          {{ skinPrice | currency }}
        </span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <b>Peso de Piel</b>
        <span class="badge">
          {{ skinWeight | number }} KG
        </span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <b>Total</b>
        <span class="badge">
          {{ skinTotal | currency }}
        </span>
      </li>
    </ul>

  </div>
</div>

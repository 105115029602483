
<div [hidden]="showForm || showWelcome">

  <div class="jumbotron">
    RT Management - Checador
  </div>
  <app-clock></app-clock>
  <div class="row">
    <div class="col-sm-6">
      <button class="btn btn-outline-primary btn-lg btn-block"
              (click)="entryRegistry()">
        Registrar entrada
      </button>
    </div>
    <div class="col-sm-6">
      <button class="btn btn-outline-primary btn-lg btn-block"
              (click)="exitRegistry()">
        Registrar salida
      </button>
    </div>
  </div>
</div>
<!-- clock container -->
<div [hidden]="!showForm">
  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">
      <h5>
        {{ formTitle
      }}
      </h5>

      <br />
      <hr />

      <form [formGroup]="registryForm">

        <igx-input-group>
          <input igxInput name="UsernameOrNumber"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': registryForm.get('UsernameOrNumber').errors && registryForm.get('UsernameOrNumber').touched}"
                 type="text"
                 aria-label="Nombre de usuario o número de empleado"
                 formControlName="UsernameOrNumber" />
          <label igxLabel for="UsernameOrNumber">Nombre de usuario o número de empleado</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="Password"
                 [ngClass]="{'is-invalid': registryForm.get('Password').errors && registryForm.get('Password').touched}"
                 type="password"
                 aria-label="Contraseña"
                 formControlName="Password" />
          <label igxLabel for="Password">Contraseña</label>
        </igx-input-group>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Registrar
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

      <hr />
      <div style="padding:25px;"
           *ngIf="showError">
        <p>
          {{ errorMsg || '' }}
        </p>
      </div>


</div>
  </div>
</div>
<!-- form container -->

<div *ngIf="showWelcome">
  <div class="jumbotron text-center">
    <h4 *ngIf="isEntry">
      Bienvenid@ {{employee.FullName}}
    </h4>
    <h4 *ngIf="isExit">
      Hasta pronto {{employee.FullName}}
    </h4>
    <br />
    <hr />
    <button class="btn btn-primary btn-block"
            (click)="close()">
      Cerrar
    </button>
  </div>
</div>
<!-- welcome container -->

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './views/landing/landing.component';
import { LoginComponent } from './views/landing/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { AuthGuard } from './interceptors/auth.guard';
import { UsersComponent } from './views/home/users/users.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { RolesComponent } from './views/home/roles/roles.component';
import { CustomersComponent } from './views/home/customers/customers.component';
import { FarmersComponent } from './views/home/farmers/farmers.component';
import { CagesComponent } from './views/home/cages/cages.component';
import { CageDetailsComponent } from './views/home/cage-details/cage-details.component';
import { LocationsComponent } from './views/home/locations/locations.component';
import { CageAnimalsComponent } from './views/home/cage-animals/cage-animals.component';
import { AssignLocationComponent } from './views/home/assign-location/assign-location.component';
import { FarmerPayComponent } from './views/home/farmer-pay/farmer-pay.component';
import { CustomerPayComponent } from './views/home/customer-pay/customer-pay.component';
import { CageGutsComponent } from './views/home/cage-guts/cage-guts.component';
import { AnimalsSalesComponent } from './views/home/animals-sales/animals-sales.component';
import { GutsSalesComponent } from './views/home/guts-sales/guts-sales.component';
import { AnimalSaleDetailsComponent } from './views/home/animal-sale-details/animal-sale-details.component';
import { GutSaleDetailsComponent } from './views/home/gut-sale-details/gut-sale-details.component';
import { EmployeesComponent } from './views/home/employees/employees.component';
import { CheckComponent } from './views/check/check.component';
import { CheckGuard } from './interceptors/check.guard';
import { RegisterComponent } from './views/check/register/register.component';
import { SelectorComponent } from './views/check/selector/selector.component';
import { ButcheriesComponent } from './views/home/butcheries/butcheries.component';
import { PayrollComponent } from './views/home/payroll/payroll.component';
import { LoansComponent } from './views/home/loans/loans.component';
import { ChangePasswordComponent } from './views/home/change-password/change-password.component';
import { DeletedRecordsComponent } from './views/home/deleted-records/deleted-records.component';
import { CustomersRecordsComponent } from './views/home/deleted-records/customers-records/customers-records.component';
import { FarmersRecordsComponent } from './views/home/deleted-records/farmers-records/farmers-records.component';
import { EmployeesRecordsComponent } from './views/home/deleted-records/employees-records/employees-records.component';
import { LocationsRecordsComponent } from './views/home/deleted-records/locations-records/locations-records.component';
import { ButcheriesRecordsComponent } from './views/home/deleted-records/butcheries-records/butcheries-records.component';
import { CreditsComponent } from './views/home/credits/credits.component';
import { CagesReportComponent } from './views/home/reports/cages-report/cages-report.component';


const routes: Routes = [
  // Landing
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    component: LandingComponent,
    children: [
      { path: 'login', component: LoginComponent }
    ]
  },

  // Views
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
      { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
      { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
      { path: 'credits', component: CreditsComponent, canActivate: [AuthGuard] },
      { path: 'farmers', component: FarmersComponent, canActivate: [AuthGuard] },
      { path: 'cages', component: CagesComponent, canActivate: [AuthGuard] },
      { path: 'cage-animals', component: CageAnimalsComponent, canActivate: [AuthGuard] },
      { path: 'cage-guts', component: CageGutsComponent, canActivate: [AuthGuard] },
      { path: 'cage-details', component: CageDetailsComponent, canActivate: [AuthGuard] },
      { path: 'animals-sales', component: AnimalsSalesComponent, canActivate: [AuthGuard] },
      { path: 'animals-sale-details', component: AnimalSaleDetailsComponent, canActivate: [AuthGuard] },
      { path: 'guts-sales', component: GutsSalesComponent, canActivate: [AuthGuard] },
      { path: 'guts-sale-details', component: GutSaleDetailsComponent, canActivate: [AuthGuard] },
      { path: 'locations', component: LocationsComponent, canActivate: [AuthGuard] },
      { path: 'assign-location', component: AssignLocationComponent, canActivate: [AuthGuard] },
      { path: 'farmers-pay', component: FarmerPayComponent, canActivate: [AuthGuard] },
      { path: 'customers-pay', component: CustomerPayComponent, canActivate: [AuthGuard] },
      { path: 'employees', component: EmployeesComponent, canActivate: [AuthGuard] },
      { path: 'butcheries', component: ButcheriesComponent, canActivate: [AuthGuard] },
      { path: 'payroll', component: PayrollComponent, canActivate: [AuthGuard] },
      { path: 'loans', component: LoansComponent, canActivate: [AuthGuard] },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },

      // Reports
      { path: 'cages-report', component: CagesReportComponent, canActivate: [AuthGuard] },
      {
        path: 'deleted-records',
        component: DeletedRecordsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'customers', component: CustomersRecordsComponent },
          { path: 'farmers', component: FarmersRecordsComponent },
          { path: 'employees', component: EmployeesRecordsComponent },
          { path: 'locations', component: LocationsRecordsComponent },
          { path: 'butcheries', component: ButcheriesRecordsComponent },
        ]
      },
    ]
  },

  // Checker
  {
    path: 'check',
    component: CheckComponent,
    canActivate: [CheckGuard],
    children: [
      { path: 'butchery-selector', component: SelectorComponent, canActivate: [CheckGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [CheckGuard] },
    ]
  },


  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


<!-- Grid container -->
<div [hidden]="showForm || showDetails">
  <h4>
    Créditos
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar Cliente
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToSelect)="select($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<!-- Form container -->
<div [hidden]="!showForm" style="height:75vh;">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Cliente</h5>
      <h5 *ngIf="isEdition">Editar Detalles de Crédito</h5>

      <hr />
      <br />

      <form [formGroup]="customerForm">

        <app-search-customer #customerSelector
                             [getNoCredits]="true">
        </app-search-customer>

        <br />

        <igx-input-group>
          <input igxInput name="BalanceAlert" #balanceAlert
                 type="number"
                 aria-label="Saldo de Alerta"
                 formControlName="BalanceAlert" />
          <label igxLabel for="BalanceAlert">Saldo de Alerta</label>
        </igx-input-group>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isEditionValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

<!-- Details container -->
<div [hidden]="!showDetails">

  <div class="row">
    <div class="col-md-10 col-sm-12 mx-auto">
      <h5>Detalles de Cliente</h5>
      <button class="btn btn-outline-secondary"
              (click)="showDetails=false">
        Cerrar
      </button>
      <br /><hr />
      <app-header-details #details></app-header-details>
      <br />
      <app-generic-grid #movementsGrid></app-generic-grid>
    </div>
  </div>

</div>
<!-- Details container -->

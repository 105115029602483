<!--<app-menu></app-menu>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>-->

<app-loading></app-loading>

<app-header></app-header>
  <app-sidebar (collapsedEvent)="receiveCollapsed($event)"></app-sidebar>
  <div class="container-fluid">
    <section class="main-container"
             [ngClass]="{'collapsed': collapedSideBar}">
      <router-outlet></router-outlet>
    </section>
  </div>


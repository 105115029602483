
<div class="row">
  <div class="col-md-6 col-sm-12 mx-auto">
    <h5>
      {{ confirmTitle || '' }}
    </h5>
    <p>
      {{ confirmText || '' }}
    </p>

    <br />

    <igx-input-group>
      <textarea igxInput name="RemoveNotes"
             aria-label="Motivo de eliminación"
                maxlength="150"
                placeholder="Motivos de eliminación"
             [(ngModel)]="RemoveNotes"></textarea>
      <label igxLabel for="RemoveNotes">Motivos de eliminación</label>
    </igx-input-group>

    <br />
    <hr />

    <div class="text-right">
      <button class="btn btn-primary"
              type="button"
              (click)="delete()">
        Eliminar
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>
  </div>
</div>

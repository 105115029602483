import { Injectable } from '@angular/core';
import { GenericSelectorItem } from '../models/componentInterfaces';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor() { }

  getDaysOfTheWeek(): GenericSelectorItem[] {
    return [
      { Id: 'L', Name: 'Lunes' },
      { Id: 'M', Name: 'Martes' },
      { Id: 'W', Name: 'Miércoles' },
      { Id: 'J', Name: 'Jueves' },
      { Id: 'V', Name: 'Viernes' },
      { Id: 'S', Name: 'Sábado' },
      { Id: 'D', Name: 'Domingo' },
    ];
  }
}

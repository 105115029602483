import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { CustomerVM } from '../../../../models/ViewModels';
import { AlertifyService } from '../../../../services/alertify.service';
import { CustomerService } from '../../../../services/customer.service';

@Component({
  selector: 'app-customers-records',
  templateUrl: './customers-records.component.html',
  styleUrls: ['./customers-records.component.scss']
})
export class CustomersRecordsComponent implements OnInit {

  customers: CustomerVM[] = [];
  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  constructor(private customerService: CustomerService,
    private alertify: AlertifyService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.getCustomers();
  }

  getCustomers() {
    this.loading.show();
    this.customerService.getDeleted()
      .subscribe((response: CustomerVM[]) => {
        if (response) {
          this.customers = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.customers);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Clientes";
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showActivateButton = true;
    this.grid.options.showExportExcelButton = false;
    this.grid.options.showExportPDFButton = false;
    this.grid.columns = [
      { field: "FullName", title: "Nombre" },
      { field: "TotalPurchases", title: "Total Compras", currencyType: true },
      { field: "TotalPayments", title: "Total Pagos", currencyType: true },
      { field: "Balance", title: "Saldo", currencyType: true },
      { field: "LastPayment", title: "Último Pago", dateType: true },
      { field: "StateName", title: "Estado" },
    ];
    this.grid.setGridOptions();
  }

  activate(customer: CustomerVM) {
    this.alertify.confirm(
      `Activar cliente: ${customer.FullName}`,
      () => {
        this.loading.show();
        this.customerService.activate(customer)
          .subscribe((respon: boolean) => {
            if (Response) {
              this.grid.deleteRow(customer.UUID);
            }
            this.loading.hide();
          }, (err) => {
              this.loading.hide();
          });
      });
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

  confirmTitle: string;
  confirmText: string;
  RemoveNotes: string;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();  

  constructor() { }

  ngOnInit(): void {
  }

  delete() {
    this.onDelete.emit(this.RemoveNotes);
  }

  cancel() {
    this.onCancel.emit(false);
  }

}

import { Component, OnInit, Input, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RangeDatesVM } from '../../models/componentInterfaces';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {

  @Input() placeHolderStart: string;
  @Input() placeHolderEnd: string;

  rangeDatesForm: FormGroup;
  rangeDates: RangeDatesVM;
  public date: Date = new Date(Date.now());
  private dayFormatter = new Intl.DateTimeFormat("es-MX", { weekday: "long" });
  private monthFormatter = new Intl.DateTimeFormat("es-MX", { month: "long" });
  public CustomStartDate: string;
  public CustomEndDate: string;



  setEndDateAutomatically: boolean = true;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.CustomStartDate = null;
    this.CustomEndDate = null;
    this.rangeDates = null;
    this.initForm();
  }

  initForm() {
    this.rangeDatesForm = this.fb.group({
      StartDate: [null, [Validators.required]],
      EndDate: [null, [Validators.required]],
    });
  }

  setPlaceHolder(startMessage, endMessage) {
    this.placeHolderStart = startMessage;
    this.placeHolderEnd = endMessage;
  }

  public formatter = (date: Date) => {
    const currentDate = `${this.addZero(date.getDate())}/${this.addZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    if (currentDate == "01/01/1")
      return null;
    return currentDate;
  }

  setStartDate(e: Date) {
    this.CustomStartDate = this.formatter(e);
    this.rangeDatesForm.patchValue({
      StartDate: e
    });
    if (this.setEndDateAutomatically) {
      this.setEndDate(this.addDays(e, 6));
    }
  }

  setEndDate(e: Date) {
    this.CustomEndDate = this.formatter(e);
    this.rangeDatesForm.patchValue({
      EndDate: e
    });
  }

  getFormValues(): RangeDatesVM {
    this.rangeDates = Object.assign({
      StartDate: this.rangeDatesForm.controls.StartDate,
      EndDate: this.rangeDatesForm.controls.EndDate
    }, this.rangeDatesForm.value);
    return this.rangeDates;
  };

  resetForm() {
    this.CustomStartDate = null;
    this.CustomEndDate = null;
    this.rangeDatesForm.reset();
    Object.keys(this.rangeDatesForm.controls).forEach(key => {
      this.rangeDatesForm.controls[key].setErrors(null);
    });
  }

  addDays(date: Date, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addZero(value: number): string {
    var result = `${value}`;

    if (value < 10) 
      result = `0${result}`;

    return result;

  }

}

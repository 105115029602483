import { Component, OnInit, ViewChild, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { CageVM } from '../../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { CageService } from '../../../services/cage.service';
import { CageIdentifierSelectorComponent } from '../../../components/cage-identifier-selector/cage-identifier-selector.component';
import { FarmerSelectorComponent } from '../../../components/farmer-selector/farmer-selector.component';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';

@Component({
  selector: 'app-cages',
  templateUrl: './cages.component.html',
  styleUrls: ['./cages.component.scss']
})
export class CagesComponent implements OnInit {


  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  cages: CageVM[] = [];
  cageVM: CageVM;

  showForm: boolean = false;
  isEdition: boolean = false;
  cageForm: FormGroup;
  totalCost: number = 0;
  totalSkin: number = 0;
  totalSkinPrice: number = 0;

  @ViewChild("identifierSelector", { read: CageIdentifierSelectorComponent, static: true })
  public identifierSelector: CageIdentifierSelectorComponent;

  @ViewChild("farmerSelector", { read: FarmerSelectorComponent, static: true })
  public farmerSelector: FarmerSelectorComponent;

  @ViewChild("datePicker", { read: DatePickerComponent, static: true })
  public datePicker: DatePickerComponent;

  steps: any[] = [
    {
      title: 'Paso 1',
      content: 'Contenido del paso 1'
    },
    {
      title: 'Paso 2',
      content: 'Contenido del paso 2'
    }
  ];

  currentStep: number = 0;

  onNext = () => this.currentStep++;

  constructor(private cageService: CageService,
    private loading: LoadingService,
    private router: Router,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.farmerSelector.loadFarmersCombo();
    this.getAllCages();
    this.onFormChanges();
  }

  onFormChanges(): void {
    this.cageForm.valueChanges
      .subscribe(val => {
        //this.totalCost = val.Weight * val.KiloCost;
        this.totalCost = (val.Weight * val.KiloCost) + val.FreightCost;
        this.totalSkin = val.SkinWeight1 + val.SkinWeight2 + val.SkinWeight3 + val.SkinWeight4;
        this.totalSkinPrice = val.SkinPrice * this.totalSkin;
      });
  }

  initForm() {
    this.cageForm = this.fb.group({
      NumberOfHeads: [0, [Validators.required]],
      Weight: [0, [Validators.required]],
      KiloCost: [0, [Validators.required]],
      FreightCost: [0, [Validators.required]],
      SkinPrice: [0],
      SkinWeight1: [0],
      SkinWeight2: [0],
      SkinWeight3: [0],
      SkinWeight4: [0],
    });
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  getDayOfTheWeek(day: number) {
    this.identifierSelector.setComboByDayOfTheWeek(day);
  }

  isValidForm(): boolean {
    return this.cageForm.valid
      && this.identifierSelector.identifierForm.valid
      && this.farmerSelector.farmerForm.valid;
  }

  getAllCages() {
    this.loading.show();
    this.cageService.getAll()
      .subscribe((response: CageVM[]) => {
        if (response) {
          this.cages = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.cages);
    this.grid.options.showDetailsButton = true;
    this.grid.options.toolbarTitle = "Jaulas";
    //this.grid.options.rowClick = true;

    this.grid.columns= [
      { field: "CageInfo", title: "Identificador" },
      { field: "Date", title: "Fecha", dateType: true },
      { field: "NumberOfHeads", title: "Cabezas" },
      { field: "FarmerName", title: "Ganadero" },
      { field: "Weight", title: "Peso (KG)" },
      { field: "TotalWeight", title: "Peso Actual (KG)" },
      { field: "KiloCost", title: "$ / KG", currencyType: true },
      { field: "FreightCost", title: "Flete", currencyType: true },
      { field: "TotalCost", title: "Total", currencyType: true },
      { field: "SkinTotal", title: "Total Piel", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.totalCost = 0;
    this.totalSkin = 0;
    this.totalSkinPrice = 0;
    this.setFormVisibility();
    this.farmerSelector.resetForm();
    this.datePicker.setDate(new Date());
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newCage: CageVM = Object.assign({
        Identifier: this.identifierSelector.getIdentifier(),
        TotalCost: this.totalCost,
        TotalWeight: 0,
        Date: this.datePicker.getDate(),
        Farmer: this.farmerSelector.selectedFarmer,
        FarmerName: this.farmerSelector.getFarmerName(),
        SkinWeight: this.totalSkin
      }, this.cageForm.value);
      this.cageService.create(newCage)
        .subscribe((response: CageVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();

            localStorage.setItem(LocalData.SELECTED_CAGE_UUID, response.UUID);
            this.router.navigate(['home/cage-animals']);
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events

  select(cage: CageVM) {
    localStorage.setItem(LocalData.SELECTED_CAGE_UUID, cage.UUID);
    this.router.navigate(['home/cage-details']);
  }

  edit(cage: CageVM) {
    this.cageVM = cage;
    this.cageForm.setValue({
      NumberOfHeads: this.cageVM.NumberOfHeads,
      Weight: this.cageVM.Weight,
      KiloCost: this.cageVM.KiloCost,
      FreightCost: this.cageVM.FreightCost,
      SkinPrice: this.cageVM.SkinPrice,
      SkinWeight1: this.cageVM.SkinWeight,
      SkinWeight2: 0,
      SkinWeight3: 0,
      SkinWeight4: 0,
    });
    this.totalSkin = this.cageVM.SkinWeight;
    this.totalSkinPrice = this.cageVM.SkinTotal;

    this.identifierSelector.setComboById(this.cageVM.Identifier);
    this.totalCost = this.cageVM.TotalCost;
    this.farmerSelector.setComboById(this.cageVM.Farmer.UUID);
    this.farmerSelector.disabled = true;
    this.datePicker.setDate(new Date(this.cageVM.Date));
    this.isEdition = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedCage: CageVM = Object.assign({
        UUID: this.cageVM.UUID,
        Id: this.cageVM.Id,
        Identifier: this.cageVM.Identifier,
        CageInfo: this.cageVM.CageInfo,
        TotalCost: this.totalCost,
        TotalWeight: this.cageVM.TotalWeight,
        Date: this.datePicker.getFormValue().CustomDate,
        Farmer: this.farmerSelector.selectedFarmer,
        FarmerName: this.farmerSelector.getFarmerName(),
        SkinWeight: this.totalSkin,
        SkinTotal: this.totalSkinPrice,
      }, this.cageForm.value);


      this.cageService.update(updatedCage)
        .subscribe((response: CageVM) => {
          if (response) {
            this.grid.updateRowByUUID(updatedCage);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(cage: CageVM) {
    this.alertify.confirm(
      `Eliminar jaula: ${cage.Identifier}${cage.Id}`,
      () => {
        this.loading.show();
        this.cageService.delete(cage.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(cage.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  

}

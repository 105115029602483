import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deleted-records',
  templateUrl: './deleted-records.component.html',
  styleUrls: ['./deleted-records.component.scss']
})
export class DeletedRecordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HeaderDetailsItem } from '../../models/componentInterfaces';

@Component({
  selector: 'app-header-details',
  templateUrl: './header-details.component.html',
  styleUrls: ['./header-details.component.scss']
})
export class HeaderDetailsComponent implements OnInit {

  headerItems: HeaderDetailsItem[] = [];
  showHeader: boolean = false;
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  itemClicked(item: HeaderDetailsItem) {
    if (item.isLink) {
      this.onItemClick.emit(item);
    }
  }

}

<div class="row">
  <div class="col-md-6 col-sm-12">

    <igx-date-picker locale="es-MX"
                     cancelButtonLabel="Cancelar"
                     todayButtonLabel="Hoy"
                     [formatter]="formatter"
                     name="CustomStartDate"
                     (onSelection)="setStartDate($event)">
      <ng-template igxDatePickerTemplate
                   let-openDialog="openDialog"
                   let-value="CustomStartDate"
                   let-displayData="CustomStartDate">
        <igx-input-group (click)="openDialog()">
          <label igxLabel>{{ placeHolderStart }}</label>
          <input igxInput [value]="CustomStartDate"
                 placeholder="{{placeHolderStart}}"/>
        </igx-input-group>
      </ng-template>
    </igx-date-picker>

  </div>

  <div class="col-md-6 col-sm-12">

    <igx-date-picker locale="es-MX"
                     cancelButtonLabel="Cancelar"
                     todayButtonLabel="Hoy"
                     [formatter]="formatter"
                     name="CustomEndDate"
                     (onSelection)="setEndDate($event)">
      <ng-template igxDatePickerTemplate
                   let-openDialog="openDialog"
                   let-value="CustomEndDate"
                   let-displayData="CustomEndDate">
        <igx-input-group (click)="openDialog()">
          <label igxLabel>{{ placeHolderEnd }}</label>
          <input igxInput [value]="CustomEndDate"
                 placeholder="{{ placeHolderEnd }}"/>
        </igx-input-group>
      </ng-template>
    </igx-date-picker>

  </div>
</div>

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { PaymentMethodVM } from '../../models/ViewModels';
import { PaymentMethodService } from '../../services/payment-method.service';

@Component({
  selector: 'app-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss']
})
export class PaymentMethodSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione Método de Pago...";
  items: PaymentMethodVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: PaymentMethodVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output()
  onItemSelected: EventEmitter<PaymentMethodVM> = new EventEmitter<PaymentMethodVM>();


  constructor(private apiService: PaymentMethodService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.apiService.getAll()
      .subscribe((response: PaymentMethodVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = this.selectedValue.Name;
    this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    this.onItemSelected.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectedItem = this.selectedValue.Name;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public getItemInfo(): string {
    if (this.selectedValue) {
      this.selectedItem = this.selectedValue.Name;
      return this.selectedItem;
    }
    return null;
  }

  public getItemId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
    this.selectedItem = null;
  }

  openDropDown() {

  }


}


<div [hidden]="showForm">
  <h4>
    Ventas de Reses
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-10 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Venta</h5>
      <h5 *ngIf="isEdition">Editar Venta</h5>

      <hr />
      <br />

      <form [formGroup]="saleForm">

        <div class="row mb-5">
          <div class="col-md-4 col-sm-12 p-3">
            <app-date-picker #saleDate
                             (onDateChanged)="dateChanged($event)">
            </app-date-picker>
          </div>

          <div class="col-md-4 col-sm-12 p-3">
            <div [hidden]="isEdition">
              <app-search-customer #customerSelector></app-search-customer>
            </div>

            <p class="form-control"
               *ngIf="isEdition">
              <small>Cliente: </small>{{ saleCustomer.FullName || '' }}
            </p>
          </div>

          <div class="col-md-4 col-sm-12 p-3">
            <div [hidden]="isEdition">
              <app-location-selector #locationSelector
                                     (onItemSelected)="selectLocation($event)">
              </app-location-selector>
            </div>

            <p class="form-control"
               *ngIf="isEdition">
              <small>Ubicación:</small> {{ saleLocation || '' }}
            </p>
          </div>
        </div>

        <div [hidden]="animalsSelector.showItems"
             class="jumbotron text-center">
          <h5>No hay animales para seleccionar</h5>
        </div>

        <div [hidden]="!animalsSelector.showItems">
          <app-animals-selector #animalsSelector
                                (onAnimalSelected)="selectAnimal($event)"
                                (onCutGridAction)="cutGridAction($event)">
          </app-animals-selector>

          <igx-input-group>
            <input igxInput name="KiloCost"
                   [ngClass]="{'is-invalid': saleForm.get('KiloCost').errors && saleForm.get('KiloCost').touched}"
                   type="number"
                   aria-label="Precio por kilo"
                   formControlName="KiloCost" />
            <label igxLabel for="KiloCost">Precio por KG</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="NoteNumber"
                   type="text"
                   aria-label="Numero de nota"
                   formControlName="NoteNumber" />
            <label igxLabel for="NoteNumber">Número de Nota</label>
          </igx-input-group>

          <p class="form-control d-flex justify-content-between">
            <b>Costo Total:</b>
            <b>
              {{ totalCost | currency }}
            </b>
          </p>
        </div>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isUpdateValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->


<div [hidden]="showForm">
  <h4>
    Carnicerías
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <h5 *ngIf="!isEdition">Agregar Carnicería</h5>
      <h5 *ngIf="isEdition">Editar Carnicería</h5>

      <hr />
      <br />

      <form [formGroup]="butcheryForm">

        <igx-input-group>
          <input igxInput name="Name"
                 [igxFocus]="showForm"
                 [ngClass]="{'is-invalid': butcheryForm.get('Name').errors && butcheryForm.get('Name').touched}"
                 type="text"
                 aria-label="Nombre"
                 formControlName="Name" />
          <label igxLabel for="Name">Nombre</label>
        </igx-input-group>

        <app-user-selector #userSelector></app-user-selector>

        <igx-input-group>
          <input igxInput name="StringTime"
                 [ngClass]="{'is-invalid': butcheryForm.get('StringTime').errors && butcheryForm.get('StringTime').touched}"
                 type="time"
                 aria-label="Hora de entrada"
                 formControlName="StringTime" />
          <label igxLabel for="StringTime">Hora de Entrada</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="DelayMinutes"
                 [ngClass]="{'is-invalid': butcheryForm.get('DelayMinutes').errors && butcheryForm.get('DelayMinutes').touched}"
                 type="number"
                 aria-label="Minutos de tolerancia"
                 formControlName="DelayMinutes" />
          <label igxLabel for="DelayMinutes">Minutos de tolerancia</label>
        </igx-input-group>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->

import { Pipe, PipeTransform } from '@angular/core';
import { AnimalVM } from '../models/ViewModels';

@Pipe({
  name: 'sold'
})
export class SoldPipe implements PipeTransform {

  transform(animals: AnimalVM[], isSold: boolean): AnimalVM[]{
    if (!animals) {
      return;
    }
    if (!isSold) {
      return animals;
    }
    return animals.filter(a => a.Sold === false);
    
  }

}

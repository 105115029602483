
<div [hidden]="showForm">
  <h4>Usuarios</h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <div class="btn-group">
        <button class="btn btn-info"
                [ngClass]="{'active': showEditInfoForm}"
                (click)="switchEdition(1)">
          Editar información
        </button>
        <button class="btn btn-info"
                [ngClass]="{'active': showChangePasswordForm}"
                (click)="switchEdition(2)">
          Cambiar contraseña
        </button>
      </div>

      <div [hidden]="showChangePasswordForm">

        <h5 *ngIf="!isEdition">Agregar Usuario</h5>
        <h5 *ngIf="isEdition">Editar Usuario</h5>

        <hr />
        <br />

        <form [formGroup]="userForm">

          <igx-input-group>
            <input igxInput name="Username"
                   [igxFocus]="showForm"
                   [disabled]="isEdition"
                   [ngClass]="{'is-invalid': userForm.get('Username').errors && userForm.get('Username').touched}"
                   type="text"
                   aria-label="Nombre de usuario"
                   formControlName="Username" />
            <label igxLabel for="Username">Nombre de Usuario</label>
          </igx-input-group>

          <app-rol-selector #rolSelector></app-rol-selector>

          <igx-input-group>
            <input igxInput name="FirstName"
                   [ngClass]="{'is-invalid': userForm.get('FirstName').errors && userForm.get('FirstName').touched}"
                   type="text"
                   aria-label="Nombre"
                   formControlName="FirstName" />
            <label igxLabel for="FirstName">Nombre</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="LastName"
                   [ngClass]="{'is-invalid': userForm.get('LastName').errors && userForm.get('LastName').touched}"
                   type="text"
                   aria-label="Apellido"
                   formControlName="LastName" />
            <label igxLabel for="LastName">Apellido</label>
          </igx-input-group>

          <div [hidden]="isEdition">
            <span class="bg-warning"
                  *ngIf="!passwordMatch()">
              <b>Las contraseñas no coinciden.</b>
            </span>

            <igx-input-group *ngIf="!showPassword">
              <input igxInput name="Password"
                     [ngClass]="{'is-invalid': userForm.get('Password').errors && userForm.get('Password').touched}"
                     type="password"
                     aria-label="Contraseña"
                     formControlName="Password" />
              <label igxLabel for="Password">Contraseña</label>
            </igx-input-group>
            <igx-input-group *ngIf="showPassword">
              <input igxInput name="Password"
                     [ngClass]="{'is-invalid': userForm.get('Password').errors && userForm.get('Password').touched}"
                     type="text"
                     aria-label="Contraseña"
                     formControlName="Password" />
              <label igxLabel for="Password">Contraseña</label>
            </igx-input-group>

            <igx-input-group *ngIf="!showPassword">
              <input igxInput name="ConfirmPassword"
                     [ngClass]="{'is-invalid': userForm.get('ConfirmPassword').errors && userForm.get('ConfirmPassword').touched}"
                     type="password"
                     aria-label="Confirmar Contraseña"
                     formControlName="ConfirmPassword" />
              <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
            </igx-input-group>
            <igx-input-group *ngIf="showPassword">
              <input igxInput name="ConfirmPassword"
                     [ngClass]="{'is-invalid': userForm.get('ConfirmPassword').errors && userForm.get('ConfirmPassword').touched}"
                     type="text"
                     aria-label="Confirmar Contraseña"
                     formControlName="ConfirmPassword" />
              <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
            </igx-input-group>

            <igx-checkbox [checked]="showPassword"
                          (change)="showPassword=!showPassword">
              Mostrar Contraseña
            </igx-checkbox>
          </div>

          <br />
          <hr />

          <div class="d-flex justify-content-between">
            <button class="btn btn-primary"
                    *ngIf="!isEdition"
                    type="submit"
                    [disabled]="!isValidForm()"
                    (click)="save()">
              Guardar
            </button>
            <button class="btn btn-primary"
                    *ngIf="isEdition"
                    type="submit"
                    [disabled]="!isValidForm()"
                    (click)="update()">
              Guardar cambios
            </button>

            <button class="btn btn-secondary"
                    type="button"
                    (click)="cancel()">
              Cancelar
            </button>
          </div>

        </form>
      </div>

      <div [hidden]="showEditInfoForm">

        <h5>Cambiar Contraseña</h5>

        <hr />
        <br />

        <form [formGroup]="changePasswordForm">

          <div class="form-control d-flex justify-content-between align-items-center"
               *ngIf="userVM">
            <b>Usuario</b>
            <span>{{ userVM.Username }}</span>
          </div>

          <br />

          <span class="bg-warning"
                *ngIf="!changePasswordMatch()">
            <b>Las contraseñas no coinciden.</b>
          </span>

          <igx-input-group *ngIf="!showPassword">
            <input igxInput name="Password"
                   [ngClass]="{'is-invalid': changePasswordForm.get('Password').errors && changePasswordForm.get('Password').touched}"
                   type="password"
                   aria-label="Contraseña"
                   formControlName="Password" />
            <label igxLabel for="Password">Contraseña</label>
          </igx-input-group>
          <igx-input-group *ngIf="showPassword">
            <input igxInput name="Password"
                   [ngClass]="{'is-invalid': changePasswordForm.get('Password').errors && changePasswordForm.get('Password').touched}"
                   type="text"
                   aria-label="Contraseña"
                   formControlName="Password" />
            <label igxLabel for="Password">Contraseña</label>
          </igx-input-group>

          <igx-input-group *ngIf="!showPassword">
            <input igxInput name="ConfirmPassword"
                   [ngClass]="{'is-invalid': userForm.get('ConfirmPassword').errors && userForm.get('ConfirmPassword').touched}"
                   type="password"
                   aria-label="Confirmar Contraseña"
                   formControlName="ConfirmPassword" />
            <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
          </igx-input-group>
          <igx-input-group *ngIf="showPassword">
            <input igxInput name="ConfirmPassword"
                   [ngClass]="{'is-invalid': userForm.get('ConfirmPassword').errors && userForm.get('ConfirmPassword').touched}"
                   type="text"
                   aria-label="Confirmar Contraseña"
                   formControlName="ConfirmPassword" />
            <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
          </igx-input-group>

          <igx-checkbox [checked]="showPassword"
                        (change)="showPassword=!showPassword">
            Mostrar Contraseña
          </igx-checkbox>

          <div *ngIf="showConfirmation">
            <br />
            <hr />
            <b>Contraseña modificada</b>
          </div>

          <br />
          <hr />

          <div class="d-flex justify-content-between">
            <button class="btn btn-primary"
                    type="submit"
                    [disabled]="!isChangePasswordValidForm()"
                    (click)="updatePassword()">
              Cambiar Contraseña
            </button>

            <button class="btn btn-secondary"
                    type="button"
                    (click)="cancel()">
              Cerrar
            </button>
          </div>

        </form>
      </div>

    </div>
  </div>

</div>
<!-- form container -->


<div *ngIf="cageVM">
  <h4>
    Precios de Visceras ({{cageVM.Identifier}}{{cageVM.Id}})
  </h4>

  <hr />

  <div class="row __weight">
    <div class="col-md-8 col-sm-12 mx-auto">
      <div class="form-control d-flex justify-content-between">
        <b>Precio General:</b>
        <input type="number"
               class="form-control"
               [(ngModel)]="generalPrice"
               placeholder="Precio General" />
        <button class="btn btn-outline-primary"
                (click)="setGeneralPrice()">
          Guardar
        </button>
      </div>
    </div>
  </div>

  <br />

  <div class="row"
       *ngIf="guts">
    <div class="col-md-2 col-sm-6 d-flex justify-content-between __inputContainer"
         *ngFor="let gut of guts">

      <div class="row">
        <div class="col-3">
          <small>{{gut.Id}}</small>
        </div>
        <div class="col-9">
          <input type="number"
                 class="form-control-sm border-0"
                 [(ngModel)]="gut.Price"
                 (focusout)="setPrice(gut)"
                 placeholder="Precio {{gut.Id}}" />
        </div>
      </div>

      <!--<span class="font-weight-bold small">{{gut.Id}}</span>
      <input type="number"
             class="form-control-sm"
             [(ngModel)]="gut.Price"
             (focusout)="setPrice(gut)"
             placeholder="Precio {{gut.Id}}" />-->
    </div>
  </div>

  <hr />

  <div class="text-right">
    <button class="btn btn-primary"
            type="button"
            (click)="close()">
      Cerrar
    </button>
  </div>
</div>


<div *ngIf="!showGrid"
     class="jumbotron text-center">
  <h5>No hay registros</h5>
</div>

<div *ngIf="showGrid">

  <kendo-grid [data]="gridData"
              (cellClick)="cellClickHandler($event)"
              [pageSize]="options.perPage"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              (dataStateChange)="dataStateChange($event)">

    <ng-template kendoGridToolbarTemplate
                 *ngIf="options.showToolbar">
      <input *ngIf="options.showSearchField"
             placeholder="Buscar..."
             kendoTextBox
             (input)="onFilter($event.target.value)" />
      <button *ngIf="options.showExportExcelButton"
              kendoGridExcelCommand type="button"
              icon="file-excel"
              style="float:right;">
        Exportar a Excel
      </button>
      <button *ngIf="options.showExportPDFButton"
              kendoGridPDFCommand
              icon="file-pdf"
              style="float:right;">
        Exportar a PDF
      </button>
    </ng-template>

    <kendo-grid-column *ngFor="let col of columns"
                       field="{{col.field}}"
                       title="{{col.title}}">
      <ng-template kendoGridCellTemplate let-dataItem let-column="column">
        <div *ngIf="!col.dateType && !col.currencyType && !col.booleanType && !col.timeType&& !col.numberType"
             [ngClass]="{'__pointer':options.rowClick}">
          {{ dataItem[column.field] }}
        </div>
        <div *ngIf="col.dateType"
             [ngClass]="{'__pointer':options.rowClick}">
          <div *ngIf="dataItem[column.field]=='2000-01-01T00:00:00'">
            N/A
          </div>
          <div *ngIf="dataItem[column.field]!='2000-01-01T00:00:00'">
            {{ dataItem[column.field] | date:'dd/MM/yyyy' }}
          </div>
        </div>
        <div *ngIf="col.currencyType"
             [ngClass]="{'__pointer':options.rowClick}">
          {{ dataItem[column.field] | currency }}
        </div>
        <div *ngIf="col.numberType"
             [ngClass]="{'__pointer':options.rowClick}">
          {{ dataItem[column.field] | number }}
        </div>
        <div *ngIf="col.booleanType"
             [ngClass]="{'__pointer':options.rowClick}">
          <b *ngIf="dataItem[column.field]">Si</b>
          <b *ngIf="!dataItem[column.field]">No</b>
        </div>
        <div *ngIf="col.timeType"
             [ngClass]="{'__pointer':options.rowClick}">
          {{ dataItem[column.field] | date:'HH:mm' }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages noRecords="No hay registros"
                         pagerPage="Página"
                         pagerOf="de"
                         pagerItems="ítems"
                         pagerItemsPerPage="ítems por página">
    </kendo-grid-messages>

    <!-- Action buttons -->
    <kendo-grid-column title=""
                       width="120"
                       *ngIf="!options.hideActionButtons">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex justify-content-around">
          <button class="btn btn-outline-secondary btn-sm"
                  *ngIf="options.showDetailsButton"
                  (click)="select(dataItem)"
                  aria-label="Ver detalles">
            <i class="fa fa-list"></i>
            <span class="sr-only">Ver detalles</span>
          </button>
          <button class="btn btn-outline-secondary btn-sm"
                  *ngIf="options.showEditButton"
                  (click)="edit(dataItem)"
                  aria-label="Editar">
            <i class="fa fa-pencil-square"></i>
            <span class="sr-only">Editar</span>
          </button>
          <button class="btn btn-outline-secondary btn-sm"
                  *ngIf="options.showDeleteButton"
                  (click)="delete(dataItem)"
                  aria-label="Eliminar">
            <i class="fa fa-trash-o"></i>
            <span class="sr-only">Eliminar</span>
          </button>
          <button class="btn btn-outline-secondary btn-sm"
                  *ngIf="options.showActivateButton"
                  (click)="activate(dataItem)"
                  aria-label="Activar">
            <i class="fa fa-check"></i>
            <span class="sr-only">Activar</span>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-pdf fileName="{{options.toolbarTitle}}.pdf" [repeatHeaders]="true"></kendo-grid-pdf>
    <kendo-grid-excel fileName="{{options.toolbarTitle}}.xlsx"></kendo-grid-excel>

  </kendo-grid>
</div>


<kendo-dialog title="Acciones"
              *ngIf="showActionsDialog"
              (close)="showActionsDialog=false"
              [minWidth]="250"
              [width]="300">

  <button class="btn btn-outline-primary btn-block"
          (click)="dialogSelectButton()">
    Ver Detalles
  </button>
  <button class="btn btn-outline-primary btn-block"
          (click)="dialogEditButton()">
    Editar
  </button>
  <button class="btn btn-outline-primary btn-block"
          (click)="dialogDeleteButton()">
    Eliminar
  </button>

</kendo-dialog>

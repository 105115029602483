<div [hidden]="disabled">
  <form [formGroup]="farmerForm">
    <igx-input-group #inputGroup
                     [igxToggleAction]="dropDown">
      <input #input
             class="form-control"
             type="text"
             igxInput
             [igxDropDownItemNavigation]="igxDropDown"
             [ngClass]="{'is-invalid': farmerForm.get('ComboValue').errors && farmerForm.get('ComboValue').touched}"
             formControlName="ComboValue"
             readonly="readonly"
             placeholder="{{ ComboPlaceHolder }}"
             (keydown.ArrowDown)="openDropDown()" />
    </igx-input-group>

    <igx-drop-down #dropDown [width]="'100%'"
                   (igx-drop-down-open)="isOpen"
                   (onSelection)="onSelection($event)">
      <div class="drop-down__scroll-container">
        <igx-drop-down-item *ngFor="let item of items"
                            class="__dropdownItem"
                            [value]="item.Name">
          <span *ngIf="showBalance">
            {{ item.Name }} ({{ item.Balance | currency }})
          </span>
          <span *ngIf="!showBalance">
            {{ item.Name }}
          </span>
        </igx-drop-down-item>
      </div>
    </igx-drop-down>
  </form>
</div>

<p [hidden]="!disabled"
   class="form-control">
  {{ selectedItem || '' }}
</p>

import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeVM, ButcheryVM, ChangeEmployeePassword } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { ButcherySelectorComponent } from '../../../components/butchery-selector/butchery-selector.component';
import { GenericSelectorComponent } from '../../../components/generic-selector/generic-selector.component';
import { DateSelectorComponent } from '../../../components/date-selector/date-selector.component';
import { EmployeeService } from '../../../services/employee.service';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  employees: EmployeeVM[] = [];
  employeeVM: EmployeeVM;

  showForm: boolean = false;
  showDetails: boolean = false;
  isEdition: boolean = false;
  showPassword: boolean = false;
  employeeForm: FormGroup;
  changePasswordForm: FormGroup;

  @ViewChild("butcherySelector", { read: ButcherySelectorComponent, static: false })
  public butcherySelector: ButcherySelectorComponent;

  @ViewChild("daySelector", { read: GenericSelectorComponent, static: true })
  public daySelector: GenericSelectorComponent;

  //@ViewChild("dateSelector", { read: DateSelectorComponent, static: false })
  //public dateSelector: DateSelectorComponent;

  hidePassword: boolean = true;

  @ViewChild("employeeDetails", { read: EmployeeDetailsComponent, static: false })
  public employeeDetails: EmployeeDetailsComponent;

  showEditInfoForm: boolean = false;
  showChangePasswordForm: boolean = false;
  showConfirmation: boolean = false;

  constructor(private employeeService: EmployeeService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.initChangePasswordForm();
    this.daySelector.setDaysOfTheWeek();
    this.daySelector.ComboPlaceHolder = "Día de descanso";
    this.getAllEmployees();
  }

  initForm() {
    this.employeeForm = this.fb.group({
      EmployeeNumber: ['', [Validators.required, Validators.minLength(3)]],
      Name: ['', [Validators.required, Validators.minLength(3)]],
      FirstLastName: ['', [Validators.required, Validators.minLength(3)]],
      SecondLastName: ['', [Validators.required, Validators.minLength(3)]],
      DailySalary: [0, [Validators.required]],
      VacationDays: [0, [Validators.required]],
      Username: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required, Validators.minLength(3)]],



    });
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(3)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  async addNew() {
    await this.getDefaultDemployeeNumber();
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.employeeForm.valid;
  }

  changePasswordMatch(): boolean {
    return this.changePasswordForm.value.Password == this.changePasswordForm.value.ConfirmPassword;
  }

  getAllEmployees() {
    this.loading.show();
    this.employeeService.getAll()
      .subscribe((response: EmployeeVM[]) => {
        if (response) {
          this.employees = response;
        }
        this.employees.forEach(item => {
          item.FullName = this.setFullName(item);
        });
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.employees);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Empleados";
    this.grid.options.showDetailsButton = true;
    this.grid.columns = [
      { field: "FullName", title: "Nombre" },
      { field: "DailySalary", title: "Sueldo Diario", currencyType: true },
      { field: "EmployeeNumber", title: "No. Empleado" },
      { field: "ButcheryName", title: "Carnicería" },
      { field: "DayOff", title: "Día de Descanso" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.butcherySelector.resetForm();
    this.daySelector.resetForm();
    this.initForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newEmployee: EmployeeVM = Object.assign({
        ButcheryId: this.butcherySelector.getButcheryId(),
        ButcheryName: this.butcherySelector.getButcheryName(),
        EntryDate: new Date(),
        DayOff: this.daySelector.getItemId(),
      }, this.employeeForm.value);

      this.employeeService.create(newEmployee)
        .subscribe((response: EmployeeVM) => {
          if (response) {
            response.FullName = this.setFullName(response);
            this.grid.addRow(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  // Grid events
  edit(employee: EmployeeVM) {
    this.employeeVM = employee;
    this.employeeForm.setValue({
      Name: this.employeeVM.Name,
      FirstLastName: this.employeeVM.FirstLastName,
      SecondLastName: this.employeeVM.SecondLastName,
      EmployeeNumber: this.employeeVM.EmployeeNumber,
      DailySalary: this.employeeVM.DailySalary,
      VacationDays: this.employeeVM.VacationDays,
      Username: this.employeeVM.Username,
      Password: "123456789",
    });
    this.butcherySelector.setComboById(this.employeeVM.ButcheryId);
    this.daySelector.setComboById(this.employeeVM.DayOff);
    this.isEdition = true;
    this.showEditInfoForm = true;
    this.showChangePasswordForm = false;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedEmployee: EmployeeVM = Object.assign({
        UUID: this.employeeVM.UUID,
        ButcheryId: this.butcherySelector.getButcheryId(),
        ButcheryName: this.butcherySelector.getButcheryName(),
        EntryDate: new Date(),
        DayOff: this.daySelector.getItemId(),
      }, this.employeeForm.value);


      this.employeeService.update(updatedEmployee)
        .subscribe((response: EmployeeVM) => {
          if (response) {
            updatedEmployee.FullName = this.setFullName(updatedEmployee);
            this.grid.updateRowByUUID(updatedEmployee);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(employee: EmployeeVM) {
    this.alertify.confirm(
      `Eliminar empleado: ${employee.FullName}`,
      () => {
        this.loading.show();
        this.employeeService.delete(employee.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(employee.UUID);
              this.loading.hide();
            }
          }, (err) => {
            console.log(err);
            this.loading.hide();
          });
      });
  }

  setFullName(employee: EmployeeVM): string {
    return `${employee.Name} ${employee.FirstLastName} ${employee.SecondLastName}`;
  }

  select(employee: EmployeeVM) {
    this.employeeDetails.employeeId = employee.UUID;
    this.employeeDetails.getEmployeeInfo();
    this.showDetails = true;
  }

  closeDetails() {
    this.showDetails = false;
    this.employeeDetails.close();
  }

  switchEdition(value: number) {
    this.showEditInfoForm = value === 1;
    this.showChangePasswordForm = value === 2;

  }

  isChangePasswordValidForm(): boolean {
    return this.changePasswordForm.valid
      && this.changePasswordMatch();
  }

  updatePassword() {
    if (this.isChangePasswordValidForm()) {
      this.loading.show();
      const passwordInfo: ChangeEmployeePassword = Object.assign({
        EmployeeId: this.employeeVM.UUID
      }, this.changePasswordForm.value);
      this.employeeService.changePassword(passwordInfo)
        .subscribe((response: boolean) => {
          if (response) {
            this.showConfirmation = true;
            this.loading.hide();
            this.initChangePasswordForm();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  async getDefaultDemployeeNumber() {
    this.loading.show();
    this.employeeService.getNextEmployeeNumber()
      .subscribe((response: string) => {
        if (response) {
          this.employeeForm.controls["EmployeeNumber"].setValue(response);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

}

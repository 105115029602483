
<button class="btn btn-outline-secondary btn-block text-left"
        [hidden]="!disabled">
  Cliente: {{ itemText || 'Seleccione' }}
</button>

<button class="btn btn-outline-secondary btn-block text-left"
        [hidden]="disabled"
        (click)="changeCollapseStatus()">
  Cliente: {{ itemText || 'Seleccione' }}
</button>

<div class="__searchContainer"
     [ngClass]="{'collapse': collapse}">

  <igx-input-group>
    <input igxInput name="search"
           #search
           placeholder="Buscar cliente..."
           [(ngModel)]="searchValue"
           aria-label="Buscar cliente" />
    <!--<label igxLabel for="search">Buscar Cliente</label>-->
  </igx-input-group>
  <br />
  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center"
         style="cursor:pointer;"
         *ngFor="let item of items | search:searchValue"
         (click)="selectItem(item)">
      <span>{{ item.FullName }}</span>
      <span *ngIf="showBalance" class="badge">{{ item.Balance | currency }}</span>
    </div>
  </div>
</div>

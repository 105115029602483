import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../components/loading/loading.service';
import { ChangePasswordVM } from '../../../models/ViewModels';
import { AlertifyService } from '../../../services/alertify.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  user: any;
  passwordForm: FormGroup;
  showForm: boolean = false;
  showPassword: boolean = false;
  
  showConfirmation: boolean = false;

  constructor(private userService: UserService,
  private fb: FormBuilder,
  private loading: LoadingService,
  private alertify: AlertifyService) {
    this.user = JSON.parse(localStorage.getItem('sessionUser'));
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.passwordForm = this.fb.group({
      NewPassword: ['', [Validators.required, Validators.minLength(4)]],
      ConfirmNewPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  isValidForm(): boolean {
    return this.passwordForm.valid
      && this.passwordsMatch();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const passwordInfo: ChangePasswordVM = Object.assign({
        UserId: this.user.uuid
      }, this.passwordForm.value);
      this.userService.changePassword(passwordInfo)
        .subscribe((response: boolean) => {
          if (response) {
            this.showConfirmation = true;
            this.loading.hide();
            this.initForm();
          }
        }, (err) => {
            this.loading.hide();
        });
    }
  }

  passwordsMatch(): boolean {
    return this.passwordForm.value["NewPassword"] == this.passwordForm.value["ConfirmNewPassword"];
  }

}

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ButcheryVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { CustomerService } from '../../services/customer.service';
import { ButcheryService } from '../../services/butchery.service';

@Component({
  selector: 'app-butchery-selector',
  templateUrl: './butchery-selector.component.html',
  styleUrls: ['./butchery-selector.component.scss']
})
export class ButcherySelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione una carnicería...";
  items: ButcheryVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: ButcheryVM;

  showBalance: boolean = false;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onButcherySelect: EventEmitter<ButcheryVM> = new EventEmitter<ButcheryVM>();

  constructor(private butcheryService: ButcheryService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.butcheryService.getAll()
      .subscribe((response: ButcheryVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = this.selectedValue.Name;
    this.selectorForm.get('ComboValue').setValue(this.selectedValue.Name);
    this.onButcherySelect.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      this.selectedItem = this.selectedValue.Name;
      this.selectorForm.get('ComboValue').setValue(this.selectedValue.Name);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedValue = this.items.find(item => item.Name == name);
      this.selectedItem = this.selectedValue.Name;
      this.selectorForm.get('ComboValue').setValue(this.selectedValue.Name);
    }
  }

  getButcheryName(): string {
    if (this.selectedValue)
      return this.selectedValue.Name;

    return null;
  }

  public getButcheryId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
  }

  openDropDown() {

  }


}

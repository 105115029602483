import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalData } from '../../../models/local.data';
import { EmployeeRegistryVM, EmployeeVM } from '../../../models/ViewModels';
import { Router } from '@angular/router';
import { LoadingService } from '../../../components/loading/loading.service';
import { EmployeeRegistryService } from '../../../services/employee-registry.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  butcheryId: string;
  butcheryName: string;

  showForm: boolean = false;
  showWelcome: boolean = false;
  formTitle: string;
  isEntry: boolean;
  isExit: boolean;

  registryForm: FormGroup;
  registryType: string;
  employee: EmployeeVM;
  showError: boolean = false;
  errorMsg: string = "";

  constructor(private registryService: EmployeeRegistryService,
    private fb: FormBuilder,
    private loading: LoadingService,
    private router: Router) {

    if (!localStorage.getItem(LocalData.SELECTED_BUTCHERY_UUID)) {
      this.router.navigate(['check/butchery-selector'])
    }
    this.butcheryId = localStorage.getItem(LocalData.SELECTED_BUTCHERY_UUID).toString();
    this.butcheryName = localStorage.getItem(LocalData.SELECTED_BUTCHERY_NAME).toString();

  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.registryForm = this.fb.group({
      UsernameOrNumber: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.registryForm.valid;
  }

  entryRegistry() {
    this.formTitle = "Registrar entrada";
    this.isEntry = true;
    this.isExit = false;
    this.setFormVisibility();
    this.registryType = LocalData.REGISTRY_TYPE_ENTRY;
  }

  exitRegistry() {
    this.formTitle = "Registrar salida";
    this.isExit = true;
    this.isEntry = false;
    this.setFormVisibility();
    this.registryType = LocalData.REGISTRY_TYPE_EXIT;
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newRegistry: EmployeeRegistryVM = Object.assign({
        ButcheryId: this.butcheryId,
        RegistryType: this.registryType,
        CreateDate:new Date()
      }, this.registryForm.value);

      this.registryService.create(newRegistry)
        .subscribe((response: EmployeeRegistryVM) => {
          if (response) {
            this.employee = response.Employee;
            this.employee.FullName = `${this.employee.Name} ${this.employee.FirstLastName} ${this.employee.SecondLastName}`;
            this.cancel();
            this.showWelcome = true;
          }
          this.loading.hide();
        }, (err) => {
            this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    
    this.setFormVisibility();
  }

  close() {
    this.showWelcome = false;
  }

}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { CageVM, OperationResult, CageIdentifier, AnimalVM, GutVM, GutPriceVM, CageReportVM } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';
import { CageAnimals } from '../models/LocalModels';
import { LocalData } from '../models/local.data';
import { LocationAndDateDto } from '../models/dtos';

@Injectable({
  providedIn: 'root'
})
export class CageService {

  cageApi = `${environment.apiUrl}/Cage`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(cage: CageVM) {
    return this.http.post(this.cageApi, cage).pipe(
      map((response: OperationResult<CageVM>) => {
        if (response.Success) {
          this.alertify.success("Jaula creada exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(cage: CageVM) {
    return this.http.put(this.cageApi, cage).pipe(
      map((response: OperationResult<CageVM>) => {
        if (response.Success) {
          this.alertify.success("Jaula editada exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.cageApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Jaula eliminada exitosamente");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateTotalWeight(cage: CageVM) {
    const uri = `${this.cageApi}/UpdateTotalWeight`;
    return this.http.put(uri, cage).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el peso de los animales.`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateAnimalWeight(animal: AnimalVM) {
    const uri = `${this.cageApi}/UpdateAnimalWeight`;
    return this.http.put(uri, animal).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el peso dl animale ${animal.Id}`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateAnimalSaleWeight(animal: AnimalVM) {
    const uri = `${this.cageApi}/UpdateAnimalSaleWeight`;
    return this.http.put(uri, animal).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el peso de venta del animal ${animal.AnimalInfo}`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateAnimalForCut(animal: AnimalVM) {
    const uri = `${this.cageApi}/UpdateAnimalForCut`;
    return this.http.put(uri, animal).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se asigno la res ${animal.AnimalInfo} a corte`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateAnimalCutDate(animal: AnimalVM) {
    const uri = `${this.cageApi}/UpdateAnimalCutDate`;
    return this.http.put(uri, animal).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se asigno la res ${animal.AnimalInfo} a corte`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateGutPrice(gut: GutVM) {
    const uri = `${this.cageApi}/UpdateGutPrice`;
    return this.http.put(uri, gut).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el precio de la viscera ${gut.Id}`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateGutGeneralPrice(gutPrice: GutPriceVM) {
    const uri = `${this.cageApi}/UpdateGutGeneralPrice`;
    return this.http.put(uri, gutPrice).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Se modificó el precio de todas las visceras`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateAnimalLocation(cage: CageVM) {
    const uri = `${this.cageApi}/UpdateAnimalLocation`;
    return this.http.put(uri, cage).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Ubicación editada exitosamente`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  updateGutLocation(cage: CageVM) {
    const uri = `${this.cageApi}/UpdateGutLocation`;
    return this.http.put(uri, cage).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success(`Ubicación editada exitosamente`);
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.cageApi).pipe(
      map((response: OperationResult<CageVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getFarmerCages(farmerId: string) {
    const uri = `${this.cageApi}/GetCagesFromFarmer?farmerId=${farmerId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CageVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.cageApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CageVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getAvailableAnimals(cageId: string) {
    const uri = `${this.cageApi}/GetAvailableAnimalsFromCage?cageId=${cageId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<AnimalVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getAnimalsFromLocation(locationId: string) {
    const uri = `${this.cageApi}/GetAnimalsFromLocation?locationId=${locationId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<AnimalVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getAnimalsToSaleFromLocation(locationId: string) {
    const uri = `${this.cageApi}/GetAnimalsToSaleFromLocation?locationId=${locationId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<AnimalVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getAnimalsToSaleFromLocationAndDate(locationAndDate: LocationAndDateDto) {
    const uri = `${this.cageApi}/GetAnimalsToSaleFromLocationAndDate?locationId=${locationAndDate.LocationId}&date=${locationAndDate.Date}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<AnimalVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getAvailableGuts(cageId: string) {
    const uri = `${this.cageApi}/GetAvailableGutsFromCage?cageId=${cageId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<GutVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getGutsFromLocation(locationId: string) {
    const uri = `${this.cageApi}/GetGutsFromLocation?locationId=${locationId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<GutVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getCageIdentifiers(): CageIdentifier[] {
    return [
      { Id: 'D', Description: 'Domingo (D)' },
      { Id: 'L', Description: 'Lunes (L)' },
      { Id: 'M', Description: 'Martes (M)' },
      { Id: 'W', Description: 'Miércoles (W)' },
      { Id: 'J', Description: 'Jueves (J)' },
      { Id: 'V', Description: 'Viernes (V)' },
      { Id: 'S', Description: 'Sábado (S)' },
    ];
  }

  createCageReport(cageId: string) {
    const uri = `${this.cageApi}/CreateCageReport?cageId=${cageId}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<CageReportVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  // Local data methods
  createEmptyCageAnimals() {
    let cageAnimals: CageAnimals = { CageUUID: "", TotalWeight: 0, animals: [] };
    this.setCageAnimals(cageAnimals);
  }

  setCageAnimals(cageAnimals: CageAnimals) {
    localStorage.setItem(LocalData.CAGE_ANIMALS, JSON.stringify(cageAnimals));
  }

  getLocalCageAnimals(): CageAnimals {
    return JSON.parse(localStorage.getItem(LocalData.CAGE_ANIMALS));
  }

  setLocalCageAnimals(cage: CageVM) {
    let _ca: CageAnimals = {
      CageUUID: cage.UUID,
      TotalWeight: cage.TotalWeight,
      animals: cage.Animals
    };
    this.setCageAnimals(_ca);
  }

  setLocalTotalWeight(totalWeight: number) {
    let _ca: CageAnimals = this.getLocalCageAnimals();
    _ca.TotalWeight = totalWeight;
    this.setCageAnimals(_ca);
  }

  setLocalAnimalWeight(animal: AnimalVM) {
    let _ca: CageAnimals = this.getLocalCageAnimals();

    _ca.animals.find(item => item.UUID === animal.UUID).Weight = animal.Weight;
    _ca.animals.find(item => item.UUID === animal.UUID).SaleWeight = animal.SaleWeight;

    this.setCageAnimals(_ca);
  }

  getLocalCageAnimalsTotalWeight(): number {
    return this.getLocalCageAnimals().TotalWeight;
  }

  getLocalCageAnimalsListOfAnimals(): AnimalVM[] {
    return this.getLocalCageAnimals().animals;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { StateVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss']
})
export class StateSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un estado...";
  items: StateVM[];
  stateForm: FormGroup;
  //public loadingTemplate: TemplateRef<any>;
  public loading = false;
  selectedItem: string;
  public selectedState: StateVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  constructor(private stateService: StateService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadStatesCombo();

  }

  initStateForm() {
    this.stateForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedState = this.items.find(item => item.Name == eventArgs.newSelection.value);
    this.stateForm.get('ComboValue').setValue(this.selectedState.Name);
  }

  public loadStatesCombo(): void {
    this.initStateForm();

    this.stateService.getAll()
      .subscribe((response: StateVM[]) => {
      if (response) {
        this.items = response;
      }
    });
  }

  public setComboById(id: string) {
    if (this.items
      && this.items.length > 0
      && id !== ""
      && id !== null) {
      this.selectedState = this.items.find(item => item.UUID == id);
      if (this.selectedState) {
        this.stateForm.get('ComboValue').setValue(this.selectedState.Name);
      }
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedState = this.items.find(item => item.Name == name);
      this.stateForm.get('ComboValue').setValue(this.selectedState.Name);
    }
  }

  public getState(): StateVM {
    if (this.selectedState)
      return this.selectedState;

    return {
      UUID: "",
      Name: ""
    };
  }

  public getStateName(): string {
    if (this.selectedState)
      return this.selectedState.Name;

    return "";
  }

  public getStateId(): string {
    if (this.selectedState)
      return this.selectedState.UUID;

    return "";
  }

  resetForm() {
    this.initStateForm();
    this.selectedState = null;
  }

  openDropDown() {

  }


}

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FarmerVM } from '../../models/ViewModels';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { FarmerService } from '../../services/farmer.service';

@Component({
  selector: 'app-farmer-selector',
  templateUrl: './farmer-selector.component.html',
  styleUrls: ['./farmer-selector.component.scss']
})
export class FarmerSelectorComponent implements OnInit {

  disabled: boolean = false;

  ComboPlaceHolder: string = "Seleccione un Ganadero...";
  items: FarmerVM[];
  farmerForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedFarmer: FarmerVM;

  showBalance: boolean = false;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onFarmerSelect: EventEmitter<FarmerVM> = new EventEmitter<FarmerVM>();

  isOpen: boolean = false;

  constructor(private farmerService: FarmerService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadFarmersCombo();

  }

  initFarmerForm() {
    this.farmerForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedFarmer = this.items.find(item => item.Name == eventArgs.newSelection.value);
    this.farmerForm.get('ComboValue').setValue(this.selectedFarmer.Name);
    this.onFarmerSelect.emit(this.selectedFarmer);
  }

  public loadFarmersCombo(): void {
    this.initFarmerForm();

    this.farmerService.getAll()
      .subscribe((response: FarmerVM[]) => {
        if (response) {
          this.items = response;
        }
      });
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedFarmer = this.items.find(item => item.UUID == id);
      this.selectedItem = this.selectedFarmer.Name;
      this.farmerForm.get('ComboValue').setValue(this.selectedFarmer.Name);
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedFarmer = this.items.find(item => item.Name == name);
      this.farmerForm.get('ComboValue').setValue(this.selectedFarmer.Name);
    }
  }

  public getFarmerName(): string {
    if (this.selectedFarmer)
      return this.selectedFarmer.Name;

    return null;
  }

  public getFarmerId(): string {
    if (this.selectedFarmer)
      return this.selectedFarmer.UUID;

    return null;
  }

  resetForm() {
    this.initFarmerForm();
    this.selectedFarmer = null;
    this.disabled = false;
  }

  openDropDown() {
    this.isOpen = true;
  }


}

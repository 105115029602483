
<div>
  <h4>
    Detalles de Jaula
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="assignLocation()">
    Asignar Ubicación
  </button>

  <hr />

  <app-header-details #headerDetails></app-header-details>

  <app-header-details #moreDetails
                      (onItemClick)="itemClicked($event)">
  </app-header-details>

  <br />

  <igx-tabs>
    <igx-tabs-group label="Reses">
      <app-animals-details #animalsList></app-animals-details>
    </igx-tabs-group>
    <igx-tabs-group label="Visceras">
      <app-guts-details #gutsList></app-guts-details>
    </igx-tabs-group>
    <igx-tabs-group label="Piel">
      <app-skin-details #skinDetails></app-skin-details>
    </igx-tabs-group>
  </igx-tabs>

</div>

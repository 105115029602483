import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GutVM, CageVM } from '../../models/ViewModels';
import { CageService } from '../../services/cage.service';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-guts-selector',
  templateUrl: './guts-selector.component.html',
  styleUrls: ['./guts-selector.component.scss']
})
export class GutsSelectorComponent implements OnInit {

  items: GutVM[] = [];
  showItems: boolean = false;
  cageId: string = "";
  totalPrice: number = 0;
  showTotalPrice: boolean = true;
  selectedQuantity: number = 0;

  selectorButton: string;
  collapse: boolean = true;

  @Output()
  onItemSelected: EventEmitter<number> = new EventEmitter<number>();

  gutsList: string = "";
  gutsArray: string[];

  forSale: boolean = true;

  constructor(private cageService: CageService,
    private loading: LoadingService) { }

  ngOnInit(): void {
  }

  getGuts() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.items = response.Guts;
          this.showItems = true;
          this.collapse = false;
          this.loading.hide();
        }
      }, (err) => {

      });
  }

  selectItem(item: GutVM) {
    if (!item.Sold) {
      item.IsSelected = !item.IsSelected;
      this.calculateTotalPrice();
    }
  }

  calculateTotalPrice() {
    this.showTotalPrice = false;

    this.totalPrice = 0;
    this.selectedQuantity = 0;
    this.gutsArray = [];
    this.gutsList = "";

    this.items.forEach(item => {
      if (item.IsSelected) {
        this.totalPrice += item.Price;
        this.selectedQuantity++;
        this.gutsArray.push(item.Id.toString());
        this.gutsList += `${item.Id}, `;
      }
    });

    this.onItemSelected.emit(this.totalPrice);
    this.showTotalPrice = true;
  }

  isValid(): boolean {
    return this.selectedQuantity > 0
      && this.totalPrice > 0;
  }

  isValidForLocation(): boolean {
    return this.selectedQuantity > 0;
  }

  getGutsArray(): string[] {
    let array: string[] = [];

    this.items.forEach(item => {
      if (item.IsSelected) {
        array.push(item.Id.toString());
      }
    });

    return array;
  }

  reset() {
    this.items = [];
    this.showItems = false;
    this.totalPrice = 0;
    this.selectedQuantity = 0;
  }

  setSaleGuts(saledGuts:GutVM[]) {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {

          saledGuts.forEach(gut => {
            response.Guts.find(item => item.UUID === gut.UUID).Sold = false;
            response.Guts.find(item => item.UUID === gut.UUID).IsSelected = true;            
          });
          this.items = response.Guts;
          this.calculateTotalPrice();
          this.showItems = true;
          this.collapse = false;
          this.loading.hide();
        }
      }, (err) => {

      });
  }

  // Methods for location assignment

  selectForLocation(item: GutVM) {
    if (!item.Sold) {
      item.IsSelected = !item.IsSelected;
      this.calculateQuantity();
    }
  }

  selectAll() {
    this.items.forEach(item => {
      if (!item.Sold)
        item.IsSelected = true;
    });
    this.calculateQuantity();
  }

  calculateQuantity() {
    this.showTotalPrice = false;
    this.selectedQuantity = this.items.filter(item => item.IsSelected == true).length;

    this.showTotalPrice = true;
  }

  getSelectedGuts(): GutVM[] {
    return this.items.filter(item => item.IsSelected == true);
  }

}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CageVM, AnimalVM } from '../../../models/ViewModels';
import { CageService } from '../../../services/cage.service';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { LocalData } from '../../../models/local.data';
import { Router } from '@angular/router';
import { HeaderDetailsComponent } from '../../../components/header-details/header-details.component';
import { AnimalsDetailsComponent } from '../../../components/animals-details/animals-details.component';
import { GutsDetailsComponent } from '../../../components/guts-details/guts-details.component';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';
import { SkinDetailsComponent } from '../../../components/skin-details/skin-details.component';

@Component({
  selector: 'app-cage-details',
  templateUrl: './cage-details.component.html',
  styleUrls: ['./cage-details.component.scss']
})
export class CageDetailsComponent implements OnInit, OnDestroy {

  cageVM: CageVM;
  animals: AnimalVM[] = [];
  cageId: string;

  @ViewChild("headerDetails", { read: HeaderDetailsComponent, static: false })
  public headerDetails: HeaderDetailsComponent;

  @ViewChild("moreDetails", { read: HeaderDetailsComponent, static: false })
  public moreDetails: HeaderDetailsComponent;

  @ViewChild("animalsList", { read: AnimalsDetailsComponent, static: false })
  public animalsList: AnimalsDetailsComponent;

  @ViewChild("gutsList", { read: GutsDetailsComponent, static: false })
  public gutsList: GutsDetailsComponent;

  @ViewChild("skinDetails", { read: SkinDetailsComponent })
  public skinDetails: SkinDetailsComponent;

constructor(private cageService: CageService,
    private alertify: AlertifyService,
    private router: Router,
    private loading: LoadingService) {

    if (localStorage.getItem(LocalData.SELECTED_CAGE_UUID) == null) {
      this.router.navigate(['home/cages']);
    }
    this.cageId = localStorage.getItem(LocalData.SELECTED_CAGE_UUID).toString();
  this.getCageInfo();

  }
  
  ngOnInit(): void {
  }

  getCageInfo() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.cageVM = response;
          this.animalsList.animals = this.cageVM.Animals;
          this.animalsList.setGridOptions();
          this.gutsList.guts = this.cageVM.Guts;
          this.skinDetails.skinPrice = this.cageVM.SkinPrice;
          this.skinDetails.skinWeight = this.cageVM.SkinWeight;
          this.skinDetails.skinTotal = this.cageVM.SkinTotal;
          this.gutsList.setGridOptions();
          this.createHeader();
        }
        this.loading.hide();
      }, (err) => {
        console.log(err);
          this.loading.hide();
          this.router.navigate(['home/cages']);
      });
  }

  createHeader() {
    this.headerDetails.headerItems = [
      { title: "Identificador", value: `${this.cageVM.Identifier}${this.cageVM.Id}` },
      { title: "Fecha", value: this.cageVM.Date, isDate: true },
      { title: "Ganadero", value: this.cageVM.Farmer.Name },
      { title: "Estado", value: this.cageVM.Farmer.State.Name },
      { title: "Peso", value: `${this.cageVM.Weight} KG` },
    ];

    this.moreDetails.headerItems = [
      { title: "Cabezas", value: this.cageVM.NumberOfHeads },
      { title: "Precio por Kilo", value: this.cageVM.KiloCost, isCurrency: true },
      { title: "Precio de Flete", value: this.cageVM.FreightCost, isCurrency: true },
      { title: "Costo Total", value: this.cageVM.TotalCost, isCurrency: true },
      { title: "Peso Actual", value: `${this.cageVM.TotalWeight} Kg`, isLink: true },
      { title: "Visceras", value: "", isLink: true },
    ];

    this.headerDetails.showHeader = true;
    this.moreDetails.showHeader = true;
  }

  itemClicked(item: HeaderDetailsItem) {
    switch (item.title) {
      case "Peso Actual": this.router.navigate(['home/cage-animals']);
        break;
      case "Visceras": this.router.navigate(['home/cage-guts']);
        break;
      default:
    }
  }

  assignLocation() {
    this.router.navigate(['home/assign-location']);
  }

  // remove local storage on component destroy
  ngOnDestroy(): void {
    //localStorage.removeItem(LocalData.SELECTED_CAGE_UUID);
  }

}

import { Component, OnInit, OnDestroy, ViewChild, Renderer2, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { ButcherySelectorComponent } from '../../../../components/butchery-selector/butchery-selector.component';
import { DateRangeComponent } from '../../../../components/date-range/date-range.component';
import { HeaderDetailsComponent } from '../../../../components/header-details/header-details.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { HeaderDetailsItem } from '../../../../models/componentInterfaces';
import { PayrollService } from '../../../../services/payroll.service';
import { AlertifyService } from '../../../../services/alertify.service';
import { EmployeeVM, PayrollRequestVM, PayrollVM } from '../../../../models/ViewModels';

const createFormGroup = dataItem => new FormGroup({
  'Employee': new FormControl(dataItem.Employee),
  'DaysWorked': new FormControl(dataItem.DaysWorked, Validators.required),
  'DailySalary': new FormControl(dataItem.DailySalary, Validators.required),
  'CalculatedSalary': new FormControl(dataItem.CalculatedSalary, Validators.required),
  'Discounts': new FormControl(dataItem.Discounts, Validators.required),
  'Food': new FormControl(dataItem.Food, Validators.required),
  'TotalMeals': new FormControl(dataItem.TotalMeals, Validators.required),
  'Delays': new FormControl(dataItem.Delays, Validators.required),
  'Extra': new FormControl(dataItem.Extra, Validators.required),
  'Total': new FormControl(dataItem.Total, Validators.required),
});

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-payroll-form',
  templateUrl: './payroll-form.component.html',
  styleUrls: ['./payroll-form.component.scss']
})
export class PayrollFormComponent implements OnInit, OnDestroy {

  @ViewChild(GridComponent)
  private grid: GridComponent;

  public groups: GroupDescriptor[] = [];
  public view: any[];

  public formGroup: FormGroup;

  private editedRowIndex: number;
  private docClickSubscription: any;
  private isNew: boolean;

  showForm: boolean = true;
  showGrid: boolean = false;

  payrollForm: FormGroup;
  records: PayrollVM[] = [];
  backup: PayrollVM[] = [];
  payrollVM: PayrollVM;
  selectedRowId: string = "";

  filename: string = "Nomina-";

  @ViewChild("butcherySelector", { read: ButcherySelectorComponent, static: true })
  public butcherySelector: ButcherySelectorComponent;

  @ViewChild("datesSelector", { read: DateRangeComponent, static: true })
  public datesSelector: DateRangeComponent;

  @ViewChild("payrollHeader", { read: HeaderDetailsComponent, static: false })
  public payrollHeader: HeaderDetailsComponent;

  payrollRequest: PayrollRequestVM;

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  public testInfo: PayrollRequestVM;

  showDetails: boolean = false;

  isEditing: boolean = false;

  viewDetails: boolean = false;

  constructor(private payrollService: PayrollService,
    private renderer: Renderer2,
    private alertify: AlertifyService,
    private fb: FormBuilder,
    private loading: LoadingService) { }

  ngOnDestroy(): void {
    this.docClickSubscription();
  }

  ngOnInit(): void {
    this.datesSelector.setPlaceHolder("Fecha inicial", "Fecha final");
    this.initForm();
    this.filename = this.setFilename();
    this.docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
  }

  onFormChanges(): void {
    this.formGroup.valueChanges
      .subscribe(val => {
        val.CalculatedSalary = val.DailySalary * val.DaysWorked;
        val.TotalMeals = val.Food * (val.DaysWorked - val.Delays);
        val.Total = (val.CalculatedSalary + val.TotalMeals + val.Extra) - val.Discounts;
      });
  }

  initForm() {
    this.payrollForm = this.fb.group({
      Food: [0, [Validators.required]]
    });
  }

  isValidForm(): boolean {
    return this.payrollForm.valid
      && this.butcherySelector.selectorForm.valid
      && this.datesSelector.rangeDatesForm.valid;
  }

  getData() {
    if (this.isValidForm()) {
      this.loading.show();
      this.payrollRequest = Object.assign({
        ButcheryId: this.butcherySelector.getButcheryId(),
        StartDate: this.datesSelector.getFormValues().StartDate,
        EndDate: this.datesSelector.getFormValues().EndDate,
      }, this.payrollForm.value);

      this.payrollService.getRecordsFromButcheryAndDateRange(this.payrollRequest)
        .subscribe((response: PayrollVM[]) => {
          if (response) {
            this.backup = response;
            this.records = response;
            this.records.forEach(item => {
              item.Employee.FullName = this.setFullName(item.Employee);
            });
            this.view = this.records;
            this.showForm = false;
            this.showGrid = true;
            this.setHeaders();
            this.loading.hide();

          } else {
            this.alertify.warning("No hay registros");
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  setHeaders() {
    this.payrollHeader.headerItems = [
      { title: "Carnicería", value: this.butcherySelector.getButcheryName() },
      { title: "Fecha Inicial", value: this.datesSelector.getFormValues().StartDate, isDate: true },
      { title: "Fecha Final", value: this.datesSelector.getFormValues().EndDate, isDate: true },
      { title: "Monto de Comidas", value: this.payrollForm.value["Food"], isCurrency: true },
    ];
    this.payrollHeader.showHeader = true;
  }

  cancel() {
    this.butcherySelector.resetForm();
    this.initForm();
    this.datesSelector.resetForm();
    this.records = [];
    this.view = null;
    this.showForm = true;
    this.showDetails = false;
    this.showGrid = false;
    this.payrollHeader.showHeader = false;
    this.viewDetails = false;
    this.payrollHeader.headerItems = [];
    this.onCancel.emit();
  }

  close() {
    this.records = [];
    this.view = null;
    this.showForm = true;
    this.showDetails = false;
    this.showGrid = false;
    this.payrollHeader.showHeader = false;
    this.payrollHeader.headerItems = [];
    this.onCancel.emit(false);
  }

  test() {
    this.loading.show();
    this.testInfo = {
      ButcheryId: "D810C381-1D95-48E3-BDA8-12AB57582892",
      StartDate: new Date(2020, 10, 30, 12, 0, 0),
      EndDate: new Date(2020, 11, 6, 12, 0, 0),
      Food: 50,
    };

    this.butcherySelector.setComboByName("Carnicería 1");
    this.datesSelector.setStartDate(new Date(this.testInfo.StartDate));
    this.datesSelector.setEndDate(new Date(this.testInfo.EndDate));
    this.payrollForm.setValue({
      Food: this.testInfo.Food
    });

    this.payrollService.getRecordsFromButcheryAndDateRange(this.testInfo)
      .subscribe((response: PayrollVM[]) => {
        if (response) {

          this.records = response;
          this.records.forEach(item => {
            item.Employee.FullName = this.setFullName(item.Employee);
          });
          this.view = this.records;
          this.showForm = false;
          this.showGrid = true;
          this.setHeaders();
          this.loading.hide();

        } else {
          this.alertify.warning("No hay registros");
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setFullName(employee: EmployeeVM): string {
    return `${employee.Name} ${employee.FirstLastName}`;
  }

  setFilename(): string {
    let fn: string = "";
    let date: Date = new Date();
    fn = `Nómina-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return fn;
  }

  save() {
    this.onSave.emit(true);
  }

  public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
    if (this.editedRowIndex != undefined) {
      //this.cancelHandler();
    } else {
      this.isEditing = true;
      this.selectedRowId = dataItem.UUID;
      this.editedRowIndex = rowIndex;
      if (isEdited || (this.formGroup && !this.formGroup.valid))
        return;
      this.saveCurrent();
      this.formGroup = createFormGroup(dataItem);
      this.onFormChanges();
      this.grid.editRow(rowIndex, this.formGroup);
    }

  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.payrollVM = null;
    this.isEditing = false;
    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private onDocumentClick(e: any): void {
    if (this.formGroup
      && this.formGroup.valid
      && !matches(e.target, '#productsGrid tbody *, #productsGrid .k-grid-toolbar .k-button')) {
      //this.saveCurrent();
    }
  }

  public saveRow(): void {
    this.saveCurrent();
    this.isEditing = false;
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      Object.assign(
        this.records.find(({ UUID }) => UUID === this.selectedRowId),
        this.formGroup.value
      );

      this.closeEditor();
    }
  }

  restore() {
    this.getData();
  }

}

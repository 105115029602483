<div *ngIf="showGrid">
  <kendo-grid [data]="gridData"
              [height]="410">

    <kendo-grid-column *ngFor="let col of columns"
                       [field]="col.title">
    </kendo-grid-column>

  </kendo-grid>
</div>

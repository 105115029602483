<!-- display selector for sale -->
<div *ngIf="forSale">
  <div class="__animalsContainer"
       *ngIf="showItems">

    <div class="row">
      <div class="col-md-4 col-sm-12 text-center">
        <p *ngIf="showTotalPrice">
          Precio Total: {{totalPrice| currency}}
        </p>
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <p *ngIf="showTotalPrice">
          {{selectedQuantity}} seleccionados
        </p>
      </div>
      <div class="col-md-4 col-sm-12">
        <p>
          <button class="btn btn-outline-secondary btn-block d-flex justify-content-between"
                  (click)="collapse=!collapse">
            <b *ngIf="collapse">
              Mostrar selector <i class="fa fa-caret-down"></i>
            </b>
            <b *ngIf="!collapse">
              Ocultar selector <i class=" fa fa-caret-up"></i>
            </b>
            <i class="fa fa-caret-up" *ngIf="!collapse"></i>
            <i class="fa fa-caret-down" *ngIf="collapse"></i>
          </button>
        </p>
      </div>
    </div>
    <!-- row -->

    <div [ngClass]="{'collapse': collapse}">
      <div class="row">

        <div class="col-md-2 col-sm-6 __animalItem"
             *ngFor="let item of items"
             [ngClass]="{'__selected': item.IsSelected, '__sold': item.Sold}"
             (click)="selectItem(item)">
          <b class="font-weight-bolder">{{item.Id}}</b>
          <i class="fa fa-check"
             *ngIf="item.IsSelected"></i>
          <i class="fa fa-circle"
             style="color:#d00;"
             aria-label="Vendido"
             *ngIf="item.Sold"></i>
          <br />
          <div class="font-weight-lighter text-center">
            {{item.Price | currency }}
          </div>
        </div>
        <!-- col -->

      </div>
      <!-- row -->
    </div>
    <!-- collapse -->

  </div>
  <!-- __animalsContainer -->
</div>
<!-- for sale -->

<!-- display selector for location assignment -->
<div *ngIf="!forSale">
  <div class="__animalsContainer"
       *ngIf="showItems">

    <div class="row">
      <div class="col-md-4 col-sm-12 mx-auto text-center">
        <p *ngIf="showTotalPrice">
          {{selectedQuantity}} seleccionados
        </p>
      </div>
      <div class="col-md-4 col-sm-12 mx-auto ">
        <p>
          <button class="btn btn-outline-primary"
                  (click)="selectAll()">
            Seleccionar todos
          </button>
        </p>
      </div>
      <div class="col-md-4 col-sm-12 mx-auto ">
        <p>
          <button class="btn btn-outline-primary"
                  (click)="collapse=!collapse">
            <span *ngIf="collapse">
              Mostrar Selector <i class="fa fa-caret-down"></i>
            </span>
            <span *ngIf="!collapse">
              Ocultar Selector <i class="fa fa-caret-up"></i>
            </span>
          </button>
        </p>
      </div>
    </div>
    <!-- row -->

    <div [ngClass]="{'collapse': collapse}">
      <div class="row">

        <div class="col-md-2 col-sm-6 __animalItem"
             *ngFor="let item of items"
             [ngClass]="{'__selected': item.IsSelected, '__sold': item.Sold}"
             (click)="selectForLocation(item)">
          <b class="font-weight-bolder">{{item.Id}}</b>
          <i class="fa fa-check"
             *ngIf="item.IsSelected"></i>
          <br />
          <div class="font-weight-lighter text-center"
               style="border-top: 1px solid #bbb; padding-top: 3px;">
            {{ item.Price | currency }} Kg
            <br />
            <small class="small">Peso</small>
          </div>
          <div class="font-weight-lighter text-center"
               style="border-top: 1px solid #bbb; padding-top: 3px;">
            {{ item.LocationAlias }}
            <br />
            <small class="small">Ubicación actual</small>
          </div>
        </div>
        <!-- col -->

      </div>
      <!-- row -->
    </div>
    <!-- collapse -->

  </div>
  <!-- __animalsContainer -->
</div>
<!-- for location assignment -->

import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericGridComponent } from '../../../../components/generic-grid/generic-grid.component';
import { HeaderDetailsComponent } from '../../../../components/header-details/header-details.component';
import { ListDetailsComponent } from '../../../../components/list-details/list-details.component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { GenericSelectorItem } from '../../../../models/componentInterfaces';
import { EmployeeLoanVM, EmployeePayVM, EmployeeVM } from '../../../../models/ViewModels';
import { AlertifyService } from '../../../../services/alertify.service';
import { EmployeeService } from '../../../../services/employee.service';
import { GenericService } from '../../../../services/generic.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  @ViewChild("details", { read: ListDetailsComponent, static: false })
  public details: ListDetailsComponent;

  @ViewChild("loansGrid", { read: GenericGridComponent, static: false })
  public loansGrid: GenericGridComponent;

  @ViewChild("paymentsGrid", { read: GenericGridComponent, static: false })
  public paymentsGrid: GenericGridComponent;

  @ViewChild("paymentDetails", { read: HeaderDetailsComponent })
  public paymentDetails: HeaderDetailsComponent;

  showLoans: boolean = false;
  showPayments: boolean = false;

  employeeId: string;
  employeeVM: EmployeeVM;
  loans: EmployeeLoanVM[] = [];
  days: GenericSelectorItem[] = [];

  constructor(private employeeService: EmployeeService,
    private genericService: GenericService,
    private loading: LoadingService,
    private alertify: AlertifyService) {

    this.days = this.genericService.getDaysOfTheWeek();

  }

  ngOnInit(): void {
  }

  getEmployeeInfo() {
    this.loading.show();
    this.employeeService.getById(this.employeeId)
      .subscribe((response: EmployeeVM) => {
        if (response) {
          response.FullName = `${response.Name} ${response.FirstLastName} ${response.SecondLastName}`;
          this.employeeVM = response;
          
          if (response.Loans) {
            this.loans = response.Loans;
            this.showLoans = this.loans.length > 0;
          }
        }
        this.setDetails();
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
          console.log(err);
          this.loading.hide();
      });
  }

  setGridOptions() {
    this.loansGrid.setData(this.loans);
    this.loansGrid.options.toolbarTitle = "Préstamos";
    this.loansGrid.options.hideActionButtons = false;
    this.loansGrid.options.rowClick = true;
    this.loansGrid.options.hideActionButtons = true;
    this.loansGrid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "WeeklyPayment", title: "Pago Semanal", currencyType: true },
      { field: "Amount", title: "Prestado", currencyType: true },
      { field: "AmountPaid", title: "Pagado", currencyType: true },
      { field: "Debit", title: "Adeudo", currencyType: true },
      { field: "Paid", title: "Liquidado", booleanType: true },
      { field: "CalculateDate", title: "Fecha de Calculo", dateType: true },
    ];
    this.loansGrid.setGridOptions();
  }

  setDetails() {
    this.details.detailsTitle = `Empleado: ${this.employeeVM.Name} ${this.employeeVM.FirstLastName}`;
    this.details.collapse = true;
    this.details.details = [
      { title: "Nombre", value: this.employeeVM.FullName },
      { title: "No. Empleado", value: this.employeeVM.EmployeeNumber },
      { title: "Fecha de Entrada", value: this.employeeVM.EntryDate, isDate: true },
      { title: "Carnicería", value: this.employeeVM.ButcheryName },
      { title: "Sueldo Diario", value: this.employeeVM.DailySalary, isCurrency: true },
      { title: "Día de Descanso", value: this.getDayName(this.employeeVM.DayOff) },
      { title: "Días de Vacaciones", value: this.employeeVM.VacationDays },
      { title: "Nombre de Usuario", value: this.employeeVM.Username },
    ];
    this.details.showItems = true;
  }

  getDayName(day: string): string {
    return this.days.find(item => item.Id == day).Name;
  }

  close() {
    this.employeeId = "";
    this.employeeVM = null;
    this.loans = [];
    this.details.details = [];
    this.details.showItems = false;
    this.details.collapse = false;
    this.details.detailsTitle = "";
  }

  select(loan: EmployeeLoanVM) {
    let payments: EmployeePayVM[] = [];

    if (loan.Payments !== null) {
      payments = loan.Payments;
    }
    this.paymentsGrid.setData(payments);
    this.paymentsGrid.options.perPage = payments.length;
    this.paymentsGrid.options.toolbarTitle = "Pagos";
    this.paymentsGrid.options.hideActionButtons = true;
    this.paymentsGrid.options.hideActionButtons = true;
    this.paymentsGrid.columns = [
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "Amount", title: "Monto", currencyType: true },
    ];
    this.paymentsGrid.setGridOptions();
    this.showPayments = true;

    this.paymentDetails.headerItems = [
      { title: "Fecha", value: loan.CreateDate, isDate: true },
      { title: "Pago Semanal", value: loan.WeeklyPayment, isCurrency: true },
      { title: "Prestado", value: loan.Amount, isCurrency: true },
      { title: "Pagado", value: loan.AmountPaid, isCurrency: true },
      { title: "Adeudo", value: loan.Debit, isCurrency: true },
      { title: "Calculado", value: loan.CalculateDate, isDate: true },
    ];
    this.paymentDetails.showHeader = true;
  }

}

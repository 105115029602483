import { Component, OnInit, ViewChild } from '@angular/core';
import { AnimalVM, CageVM, CustomerVM, EditAnimalSale, LocationVM, SaleAnimalsVM } from '../../../models/ViewModels';
import { AnimalsSelectorComponent } from '../../../components/animals-selector/animals-selector.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { SaleAnimalsService } from '../../../services/sale-animals.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { AlertifyService } from '../../../services/alertify.service';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';
import { LocationSelectorComponent } from '../../../components/location-selector/location-selector.component';
import { SearchCustomerComponent } from '../../../components/search-customer/search-customer.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { CustomDateVM } from '../../../models/componentInterfaces';

@Component({
  selector: 'app-animals-sales',
  templateUrl: './animals-sales.component.html',
  styleUrls: ['./animals-sales.component.scss']
})
export class AnimalsSalesComponent implements OnInit {

  @ViewChild("customerSelector", { read: SearchCustomerComponent, static: true })
  public customerSelector: SearchCustomerComponent;

  @ViewChild("locationSelector", { read: LocationSelectorComponent, static: true })
  public locationSelector: LocationSelectorComponent;

  @ViewChild("animalsSelector", { read: AnimalsSelectorComponent, static: false })
  public animalsSelector: AnimalsSelectorComponent;

  saleForm: FormGroup;
  sales: SaleAnimalsVM[] = [];
  saleVM: SaleAnimalsVM;

  collapse: boolean = true;

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  showForm: boolean = false;
  isEdition: boolean = false;
  animalsCost: number = 0;
  totalCost: number = 0;

  locationHasAnimals: boolean = false;

  saleCustomer: CustomerVM;
  saleLocation: string;

  @ViewChild("saleDate", { read: DatePickerComponent })
  public saleDate: DatePickerComponent;

  selectedDate: Date = new Date();

  constructor(private salesService: SaleAnimalsService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private router: Router,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllSales();
    
  }

  onFormChanges(): void {
    this.saleForm.valueChanges
      .subscribe(val => {
        this.animalsCost = val.KiloCost * this.animalsSelector.totalWeight;
        this.totalCost = this.animalsCost + this.animalsSelector.cutsPrice;
      });
  }

  cutGridAction(cutsPrice) {
    this.totalCost = this.animalsCost + this.animalsSelector.cutsPrice;
  }

  initForm() {
    this.saleForm = this.fb.group({
      KiloCost: [0, [Validators.required]],
      NoteNumber: [''],
    });
    this.onFormChanges();
  }

  addNew() {
    this.setFormVisibility();
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.saleForm.valid
      && this.customerSelector.isValidForm()
      && this.locationSelector.selectorForm.valid
      && this.animalsSelector.isValid();
  }

  isUpdateValidForm(): boolean {
    return this.saleForm.valid
      && this.animalsSelector.isValid();
  }

  getAllSales() {
    this.loading.show();
    this.salesService.getAll()
      .subscribe((response: SaleAnimalsVM[]) => {
        if (response) {
          this.sales = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.sales);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Ventas";
    this.grid.options.showDetailsButton = true;
    this.grid.options.showEditButton = true;
    this.grid.options.showDeleteButton = true;
    this.grid.columns = [
      { field: "Date", title: "Fecha", dateType: true },
      { field: "CustomerName", title: "Cliente" },
      { field: "AnimalsQuantity", title: "Cantidad" },
      { field: "AnimalsList", title: "Reses" },
      { field: "Weight", title: "Peso (Kg)" },
      { field: "KiloCost", title: "Precio Kg", currencyType: true },
      { field: "TotalAnimals", title: "Total Reses", currencyType: true },
      { field: "TotalCuts", title: "Córtes", currencyType: true },
      { field: "Total", title: "Total", currencyType: true },
      { field: "NoteNumber", title: "Nota" },

    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.saleDate.resetForm();
    this.initForm();
    this.animalsCost = 0;
    this.totalCost = 0;
    this.customerSelector.resetForm();
    this.locationSelector.resetForm();
    this.animalsSelector.reset();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.animalsSelector.showItems = false;
      this.loading.show();
      let date: CustomDateVM = this.saleDate.getFormValue();
      const newSale: SaleAnimalsVM = Object.assign({
        CustomerId: this.customerSelector.getCustomerId(),
        CustomerName: this.customerSelector.getCustomerName(),
        Date: date.CustomDate,
        AnimalsQuantity: this.animalsSelector.selectedQuantity,
        Weight: this.animalsSelector.totalWeight,
        Total: this.totalCost,
        AnimalsArray: this.animalsSelector.getAnimalsArray(),
        AnimalsList: this.animalsSelector.animalsList,
        Animals: this.animalsSelector.getSelectedAnimals(),
        Paid: false,
        Cuts: this.animalsSelector.getCuts(this.customerSelector.getCustomerId()),
        TotalCuts: this.animalsSelector.cutsPrice,
        TotalAnimals: this.totalCost - this.animalsSelector.cutsPrice,
      }, this.saleForm.value);

      this.salesService.create(newSale)
        .subscribe((response: SaleAnimalsVM) => {
          if (response) {
            this.grid.addRowAtTop(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });

    }
  }

  selectLocation(location: LocationVM) {
    //this.animalsSelector.cageId = location.UUID;
    this.animalsSelector.locationId = location.UUID;
    this.animalsSelector.getAnimalsFromLocationAndDate(this.selectedDate);
  }

  selectAnimal(totalWeight: number) {
    let kiloCost: number = this.saleForm.value["KiloCost"];
    this.animalsCost = totalWeight * kiloCost;
    this.totalCost = this.animalsCost + this.animalsSelector.cutsPrice;
  }

  // Grid events

  select(sale: SaleAnimalsVM) {
    localStorage.setItem(LocalData.SELECTED_SALE_UUID, sale.UUID);
    this.router.navigate(['home/animals-sale-details']);
  }

  edit(sale: SaleAnimalsVM) {
    this.loading.show();
    this.salesService.getById(sale.UUID)
      .subscribe((response) => {
        if (response) {
          this.saleVM = response;
          this.setForm();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setForm() {
    this.saleForm.setValue({
      KiloCost: this.saleVM.KiloCost,
      NoteNumber: this.saleVM.NoteNumber,
    });
    this.saleDate.setDate(new Date(this.saleVM.Date));
    this.saleCustomer = this.saleVM.Customer;
    this.saleLocation = this.saleVM.Animals[0].LocationAlias;
    this.totalCost = this.saleVM.Total;

    this.animalsSelector.locationId = this.saleVM.Animals[0].LocationId;
    this.animalsSelector.setSaleAnimalsAndCuts(this.saleVM.Animals, this.saleVM.Cuts);

    this.isEdition = true;
    this.setFormVisibility();

  }

  update() {
    if (this.isUpdateValidForm()) {
      this.animalsSelector.showItems = false;
      this.loading.show();
      let date: CustomDateVM = this.saleDate.getFormValue();
      const updatedSale: SaleAnimalsVM = Object.assign({
        UUID: this.saleVM.UUID,
        CustomerId: this.saleVM.CustomerId,
        CustomerName: this.saleVM.CustomerName,
        Date: date.CustomDate,
        AnimalsQuantity: this.animalsSelector.selectedQuantity,
        Weight: this.animalsSelector.totalWeight,
        Total: this.totalCost,
        AnimalsArray: this.animalsSelector.getAnimalsArray(),
        AnimalsList: this.animalsSelector.animalsList,
        Animals: this.animalsSelector.getSelectedAnimals(),
        Paid: this.saleVM.Paid,
        Cuts: this.animalsSelector.getCuts(this.saleVM.CustomerId),
        TotalCuts: this.animalsSelector.cutsPrice,
        TotalAnimals: this.totalCost - this.animalsSelector.cutsPrice,
      }, this.saleForm.value);

      const editSale: EditAnimalSale = {
        SavedSale: this.saleVM,
        UpdatedSale: updatedSale
      };

      this.salesService.update(editSale)
        .subscribe((response: SaleAnimalsVM) => {
          if (response) {
            this.grid.updateRowByUUID(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          console.log(err);
          this.loading.hide();
        });
    }
  }

  delete(sale: SaleAnimalsVM) {
    this.alertify.confirm(
      `Eliminar venta de cliente ${sale.CustomerName}`,
      () => {
        this.loading.show();
        this.salesService.delete(sale.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(sale.UUID);
            }
            this.loading.hide();

          }, (err) => {
            this.loading.hide();
          });
      });
  }

  dateChanged(date: Date) {
    if (this.isDateAfterCurrentDate(date)) {
      this.alertify.warning("La fecha no puede ser posterior a la fecha actual");
this.saleDate.setDate(new Date());
    } else {
      this.selectedDate = date;
      if (this.showForm) 
        this.animalsSelector.getAnimalsFromLocationAndDate(this.selectedDate);
    }
  }

  isDateAfterCurrentDate(date: Date): boolean {
    const today = new Date();
    return date > today;
  }

}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { OperationResult, EmployeeRegistryVM, PayrollRequestVM, PayrollVM, PayrollHeaderVM } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  payrollApi = `${environment.apiUrl}/Payroll`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  getRecordsFromButcheryAndDateRange(payrollRequest: PayrollRequestVM) {
    const uri = `${this.payrollApi}/GetFromButcheryIdAndDateRange`;
    return this.http.post(uri, payrollRequest).pipe(
      map((response: OperationResult<PayrollVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  create(payroll: PayrollHeaderVM) {
    return this.http.post(this.payrollApi, payroll).pipe(
      map((response: OperationResult<PayrollHeaderVM>) => {
        if (response.Success) {
          this.alertify.success("Registro creado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  update(payroll: PayrollHeaderVM) {
    return this.http.put(this.payrollApi, payroll).pipe(
      map((response: OperationResult<PayrollHeaderVM>) => {
        if (response.Success) {
          this.alertify.success("Registro editado exitosamente.");
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  delete(id: string) {
    const uri = `${this.payrollApi}?uuid=${id}`;
    return this.http.delete(uri).pipe(
      map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Registro eliminado exitosamente.");
          return true;
        } else {
          this.errorService.logerrorMessage(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.payrollApi).pipe(
      map((response: OperationResult<PayrollHeaderVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getById(id: string) {
    const uri = `${this.payrollApi}/GetByIdentifier?uuid=${id}`;
    return this.http.get(uri).pipe(
      map((response: OperationResult<PayrollHeaderVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

  getEmptyPayroll(): PayrollVM {
    return {
      UUID: "",
      EmployeeId: "",
      ButcheryId: "",
      DaysWorked: 0,
      DailySalary: 0,
      CalculatedSalary: 0,
      Discounts: 0,
      Food: 0,
      TotalMeals: 0,
      Delays: 0,
      Extra: 0,
      Total: 0,
      CreateDate: new Date()
    };
  }

}

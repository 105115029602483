import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IgxDropDownComponent, IgxInputGroupComponent, IgxInputDirective, ISelectionEventArgs } from 'igniteui-angular';
import { LocalData } from '../../models/local.data';
import { UserVM } from '../../models/ViewModels';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

  ComboPlaceHolder: string = "Seleccione un usuario...";
  items: UserVM[];
  selectorForm: FormGroup;
  public loading = false;
  selectedItem: string;
  public selectedValue: UserVM;

  @ViewChild(IgxDropDownComponent)
  public igxDropDown: IgxDropDownComponent;

  @ViewChild("inputGroup", { read: IgxInputGroupComponent })
  public inputGroup: IgxInputGroupComponent;

  @ViewChild("input", { read: IgxInputDirective })
  public input: IgxInputDirective;

  @Output() onUserSelect: EventEmitter<UserVM> = new EventEmitter<UserVM>();

  constructor(private userService: UserService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.loadComboValues();

  }

  initForm() {
    this.selectorForm = this.fb.group({
      ComboValue: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  public loadComboValues(): void {
    this.initForm();
    this.userService.getAll()
      .subscribe((response: UserVM[]) => {
        if (response) {
          this.items = response.filter(item => item.Rol == LocalData.CHECK_ROL);
        }
      });
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    this.selectedValue = this.items.find(item => item.UUID == eventArgs.newSelection.value);
    this.selectedItem = this.selectedValue.Username;
    this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    this.onUserSelect.emit(this.selectedValue);
  }

  public setComboById(id: string) {
    if (this.items && this.items.length > 0 && id !== "" && id !== null) {
      this.selectedValue = this.items.find(item => item.UUID == id);
      if (this.selectedValue) {
        this.selectedItem = this.selectedValue.Username;
        this.selectorForm.get('ComboValue').setValue(this.selectedItem);
      }
    }
  }

  public setComboByName(name: string) {
    if (this.items && this.items.length > 0 && name !== "" && name !== null) {
      this.selectedValue = this.items.find(item => item.Username == name);
      this.selectedItem = this.selectedValue.Username;
      this.selectorForm.get('ComboValue').setValue(this.selectedItem);
    }
  }

  public getUsername(): string {
    if (this.selectedValue)
      return this.selectedValue.Username;

    return null;
  }

  public getUserId(): string {
    if (this.selectedValue)
      return this.selectedValue.UUID;

    return null;
  }

  resetForm() {
    this.initForm();
    this.selectedValue = null;
    this.selectedItem = "";
  }

  openDropDown() {

  }


}

import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {

  static greaterThanZero(control: FormControl): ValidationErrors {
    let num = parseFloat(control.value);
    if (num > 0)
      return null;
    else
      return { multiplo5: true }
  }

  static removeSpecialCharacters(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const pattern = /^[a-zA-Z0-9]*$/;

    if (value && !pattern.test(value)) {
      const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '');
      control.setValue(cleanedValue);
    }

    return null;
  }



}

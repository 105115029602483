import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxDialogComponent } from 'igniteui-angular';
import { AnimalVM } from '../../models/ViewModels';
import { AlertifyService } from '../../services/alertify.service';
import { CageService } from '../../services/cage.service';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-animals-details',
  templateUrl: './animals-details.component.html',
  styleUrls: ['./animals-details.component.scss']
})
export class AnimalsDetailsComponent implements OnInit {

  animals: AnimalVM[] = [];
  animalVM: AnimalVM;
  animalWeight: number = 0;
  showGrid: boolean = false;
  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;

  locationDetails: boolean = false;

  @ViewChild("form_sendToCut", { read: IgxDialogComponent })
  public form_sendToCut: IgxDialogComponent;

  editAnimalCreatedDate: boolean = false;
  dialogTitle: string = "Enviar a Corte";

  @ViewChild("dateSelector", { read: DatePickerComponent, static: true })
  public dateSelector: DatePickerComponent;

  constructor(private cageService: CageService,
    private loading: LoadingService,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
  }

  itemClick(animal: AnimalVM) {
    this.animalVM = animal;
  }

  setGridOptions() {
    this.grid.setData(this.animals);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Medias";
    this.grid.options.hideActionButtons = true;
    this.grid.options.perPage = this.animals.length;

    this.grid.columns = [
      { field: "AnimalInfo", title: "Id" },
      { field: "Weight", title: "Peso" },
      { field: "LocationAlias", title: "Ubicación" },
      { field: "Sold", title: "Vendido", booleanType: true },
      { field: "SaleDate", title: "Fecha de Venta", dateType: true },
      { field: "CustomerName", title: "Vendido a" },
    ];
    this.grid.setGridOptions();
  }

  setGridOptionsForLocationDetails() {
    this.grid.setData(this.animals);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Medias";
    this.grid.options.hideActionButtons = true;
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.perPage = this.animals.length;

    this.grid.columns = [
      { field: "CageInfo", title: "Jaula" },
      { field: "AnimalInfo", title: "Id" },
      { field: "Weight", title: "Peso" },
    ];
    this.grid.setGridOptions();
  }

  sendToCut(animal: AnimalVM) {
    animal.Sold = true;
    animal.IsForCut = true;
    animal.UpdateDate = this.dateSelector.getFormValue().CustomDate;
    animal.Weight = this.animalWeight;
    animal.SaleWeight = this.animalWeight;
    this.loading.show();
    this.cageService.updateAnimalForCut(animal)
      .subscribe((response: boolean) => {
        if (response) {
          this.animals.find(({ UUID }) => UUID === animal.UUID).Sold = true;
          this.animals.find(({ UUID }) => UUID === animal.UUID).IsForCut = true;
          this.animals.find(({ UUID }) => UUID === animal.UUID).UpdateDate = animal.UpdateDate;
          this.animals.find(({ UUID }) => UUID === animal.UUID).CustomerName = "N/A";
          this.form_sendToCut.close();
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  confirmSendToCut(animal: AnimalVM) {
    this.editAnimalCreatedDate = false;
    this.dialogTitle = "Enviar a Corte";
    this.animalVM = animal;
    this.animalWeight = this.animalVM.Weight;
    this.dateSelector.setDate(new Date());
    this.form_sendToCut.open();
  }

  closeDialog = () => this.form_sendToCut.close();

  isSendToCutValid(): boolean {
    if (this.animalVM) {
      return this.animalWeight > 0;
    }
    return false;
  }

  sendAnimalToCut() {
    if (this.editAnimalCreatedDate) {
      this.updateCutDate(this.animalVM);
    } else {
      this.sendToCut(this.animalVM);
    }
  }

  updateCutDate(animal: AnimalVM) {
    animal.Sold = true;
    animal.IsForCut = true;
    animal.UpdateDate = this.dateSelector.getFormValue().CustomDate;
    this.loading.show();
    this.cageService.updateAnimalCutDate(animal)
      .subscribe((response: boolean) => {
        if (response) {
          this.animals.find(({ UUID }) => UUID === animal.UUID).Sold = true;
          this.animals.find(({ UUID }) => UUID === animal.UUID).IsForCut = true;
          this.animals.find(({ UUID }) => UUID === animal.UUID).UpdateDate = animal.UpdateDate;
          this.animals.find(({ UUID }) => UUID === animal.UUID).CustomerName = "N/A";
          this.form_sendToCut.close();
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  editDate(animal: AnimalVM) {
    if (animal.IsForCut) {
      this.editAnimalCreatedDate = true;
      this.dialogTitle = "Editar fecha de Corte";
      this.animalVM = animal;
      this.dateSelector.setDate(new Date(this.animalVM.UpdateDate));
      this.form_sendToCut.open();
    }
  }



}

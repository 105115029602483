import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { LocalData } from '../models/local.data';

@Injectable({
  providedIn: 'root'
})
export class CheckGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const sessionUser = this.authService.SessionUserValue();
    if (sessionUser && sessionUser.Rol == LocalData.CHECK_ROL) {
      return true;
    }
    this.router.navigate(['/landing'],
      { queryParams: { returnUrl: state.url } });
    return false;

  }

}

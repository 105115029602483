import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { WorkbookSheetRowCellBorderLeft } from '@progress/kendo-angular-excel-export';
import { AuthenticationService } from '../../interceptors/authentication.service';
import { LocalData } from '../../models/local.data';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    isActive: boolean;
    collapsed: boolean;
    showMenu: string;
    pushRightClass: string;

  @Output() collapsedEvent = new EventEmitter<boolean>();

  salesCollapsed: boolean = true;
  paymentsCollapsed: boolean = true;
  employeesCollapsed: boolean = true;
  reportsCollapsed: boolean = true;
  settingsCollapsed: boolean = true;

  constructor(public router: Router,
    private authService: AuthenticationService) {

    if (localStorage.getItem(LocalData.TOGGLE_MENU) == null) {
      localStorage.setItem(LocalData.TOGGLE_MENU, "0");
    }

    this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
      this.isActive = false;
      //this.collapsed = false;
      this.collapsed = this.getCollapse();
      this.collapsedEvent.emit(this.collapsed);
        this.showMenu = '';
        this.pushRightClass = 'push-right';
    }

  logout() {
    this.authService.logout();
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
          this.showMenu = '0';
          this.settingsCollapsed = true;
        } else {
          this.showMenu = element;
          this.settingsCollapsed = false;
        }
    }

  addExpandClass_sales(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.salesCollapsed = true;
    } else {
      this.showMenu = element;
      this.salesCollapsed = false;
    }
  }

  addExpandClass_payments(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.paymentsCollapsed = true;
    } else {
      this.showMenu = element;
      this.paymentsCollapsed = false;
    }
  }

  addExpandClass_employees(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.employeesCollapsed = true;
    } else {
      this.showMenu = element;
      this.employeesCollapsed = false;
    }
  }

  addExpandClass_reports(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.reportsCollapsed = true;
    } else {
      this.showMenu = element;
      this.reportsCollapsed = false;
    }
  }

    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.setCollapse(this.collapsed);
        this.collapsedEvent.emit(this.collapsed);
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    changeLang(language: string) {

    }

    onLoggedout() {
        localStorage.removeItem('isLoggedin');
    }

  setCollapse(value: boolean) {
    let result = 0;
    if (value)
      result = 1;

    localStorage.setItem(LocalData.TOGGLE_MENU, result.toString());
  }

  getCollapse(): boolean {
    let c: number = parseInt(localStorage.getItem(LocalData.TOGGLE_MENU));
    if (c === 1) 
      return true;
    
    return false;
  }
}


<div [hidden]="showForm">
  <h4>
    Nómina
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Crear
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>

<div [hidden]="!showForm">
  <app-payroll-form #payrollForm
                    (onSave)="save($event)"
                    (onCancel)="cancel()">
  </app-payroll-form>
</div>

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SaleAnimalsVM, AnimalVM, CustomerVM, CageVM } from '../../../models/ViewModels';
import { SaleAnimalsService } from '../../../services/sale-animals.service';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';
import { HeaderDetailsComponent } from '../../../components/header-details/header-details.component';
import { ListDetailsComponent } from '../../../components/list-details/list-details.component';
import { SquareDetailsComponent } from '../../../components/square-details/square-details.component';
import { SimpleGridComponent } from '../../../components/simple-grid/simple-grid.component';

@Component({
  selector: 'app-animal-sale-details',
  templateUrl: './animal-sale-details.component.html',
  styleUrls: ['./animal-sale-details.component.scss']
})
export class AnimalSaleDetailsComponent implements OnInit {

  @ViewChild("saleDetails", { read: HeaderDetailsComponent, static: true })
  public saleDetails: HeaderDetailsComponent;

  @ViewChild("customerDetails", { read: ListDetailsComponent, static: true })
  public customerDetails: ListDetailsComponent;

  @ViewChild("cageDetails", { read: ListDetailsComponent, static: true })
  public cageDetails: ListDetailsComponent;

  @ViewChild("animalsList", { read: SquareDetailsComponent, static: true })
  public animalsList: SquareDetailsComponent;

  saleVM: SaleAnimalsVM;
  animals: AnimalVM[] = [];
  saleId: string;

  @ViewChild("cutsGrid", { read: SimpleGridComponent, static: false })
  public cutsGrid: SimpleGridComponent;

  saleTotalPrice: number = 0;

  constructor(private salesService: SaleAnimalsService,
    private alertify: AlertifyService,
    private loading: LoadingService,
    private router: Router) {

    if (localStorage.getItem(LocalData.SELECTED_SALE_UUID) == null)
      this.router.navigate(['home/animals-sales']);
    this.saleId = localStorage.getItem(LocalData.SELECTED_SALE_UUID).toString();

  }

  ngOnInit(): void {
    this.getSaleInfo();
  }

  getSaleInfo() {
    this.loading.show();
    this.salesService.getById(this.saleId)
      .subscribe((response: SaleAnimalsVM) => {
        if (response) {
          this.saleVM = response;
          this.createCutsGrid();
          this.animals = this.saleVM.Animals;
          this.createHeader();
          this.createCustomerDetails();
          this.createCageDetails();
          this.createAnimalsList();
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  createHeader() {
    this.saleDetails.headerItems = [
      { title: "Fecha", value: this.saleVM.Date, isDate: true },
      { title: "Nota", value: this.saleVM.NoteNumber },
      { title: "Cantidad", value: this.saleVM.AnimalsQuantity },
      { title: "Peso", value: `${this.saleVM.Weight} Kg` },
      { title: "Precio Kg", value: this.saleVM.KiloCost, isCurrency: true },
      { title: "Córtes", value: this.cutsGrid.cutsPrice, isCurrency: true },
    ];

    this.saleDetails.showHeader = true;
    this.saleTotalPrice = this.saleVM.Total;
  }

  createCustomerDetails() {
    this.customerDetails.detailsTitle = `Cliente: ${this.saleVM.CustomerName}`;
    this.customerDetails.details = [
      { title: this.saleVM.Customer.FullName, value: "" },
      { value: this.saleVM.Customer.Telephone, title: "Teléfono" },
      { value: this.saleVM.Customer.MobilePhone, title: " Celular" },
      { value: this.saleVM.Customer.Email, title: "Correo electrónico" },
      { value: this.createFullAddress(this.saleVM.Customer), title: "Domicilio" },
      { title: "Saldo", value: this.saleVM.Customer.Balance, isCurrency: true },

    ];
    this.customerDetails.showItems = true;
    this.customerDetails.collapse = true;
  }

  createFullAddress(customer: CustomerVM): string {
    return `${customer.Address1} ${customer.Address2}, ${customer.ZipCode}, ${customer.City}, ${customer.StateName}`;
  }

  createCageDetails() {
    //this.cageDetails.detailsTitle = `Jaula: ${this.saleVM.CageInfo}`;
    //this.cageDetails.details = [
    //  { value: this.createCageIdentifier(this.saleVM.Cage) },
    //  { value: this.saleVM.Cage.Date, isDate: true },
    //  { title: "Número de Cabezas", value: this.saleVM.Cage.NumberOfHeads },
    //  { title: "Peso Total", value: `${this.saleVM.Cage.Weight} Kg` },
    //  { title: "Peso Actual", value: `${this.saleVM.Cage.TotalWeight} Kg` },
    //  { title: "Precio por Kilo", value: this.saleVM.Cage.KiloCost, isCurrency: true },
    //  { title: "Precio de Flete", value: this.saleVM.Cage.FreightCost, isCurrency: true },
    //  { title: "Total", value: this.saleVM.Cage.TotalCost, isCurrency: true },
    //];
    this.cageDetails.showItems = false;
    this.cageDetails.collapse = true;
  }

  createAnimalsList() {
    this.animalsList.detailsTitle = `Reses: ${this.saleVM.AnimalsQuantity}`;
    this.animals.forEach(animal => {
      this.animalsList.headerItems.push({
        title: animal.AnimalInfo, value: `${animal.SaleWeight} Kg`
      });
      this.animalsList.showHeader = true;
      this.animalsList.collapse = false;
    });
  }

  createCageIdentifier(cage: CageVM) {
    return `${cage.Identifier}${cage.Id}`;
  }

  createCutsGrid() {
    this.saleVM.Cuts.forEach(item => {
      this.cutsGrid.cutsPrice += item.Total;
    });
    this.cutsGrid.cuts = this.saleVM.Cuts;
    this.cutsGrid.cutsQuantity = this.saleVM.Cuts.length;
    
  }



  close() { this.router.navigate(['home/animals-sales']); }

  // remove local storage on component destroy
  ngOnDestroy(): void {
    localStorage.removeItem(LocalData.SELECTED_SALE_UUID);
  }

}


<div [hidden]="showForm">
  <h4>
    Ventas
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-10 col-sm-12 mx-auto">

      <h5>Agregar Venta</h5>

      <hr />
      <br />

      <form [formGroup]="saleForm">

        <app-customer-selector #customerSelector></app-customer-selector>

        <app-cage-selector #cageSelector
                           (onItemSelected)="selectCage($event)">
        </app-cage-selector>

        <app-animals-selector #animalsSelector
                              (onAnimalSelected)="selectAnimal($event)">
        </app-animals-selector>

        <igx-input-group>
          <input igxInput name="KiloCost"
                 [ngClass]="{'is-invalid': saleForm.get('KiloCost').errors && saleForm.get('KiloCost').touched}"
                 type="number"
                 aria-label="Precio por kilo"
                 formControlName="KiloCost" />
          <label igxLabel for="KiloCost">Precio por KG</label>
        </igx-input-group>

        <igx-input-group>
          <input igxInput name="NoteNumber"
                 [ngClass]="{'is-invalid': saleForm.get('NoteNumber').errors && saleForm.get('NoteNumber').touched}"
                 type="text"
                 aria-label="Numero de nota"
                 formControlName="NoteNumber" />
          <label igxLabel for="NoteNumber">Número de Nota</label>
        </igx-input-group>

        <p class="form-control d-flex justify-content-between">
          <b>Costo Total:</b>
          <b>
            {{ totalCost | currency }}
          </b>
        </p>

        <br />
        <hr />

        <div class="d-flex justify-content-between">
          <button class="btn btn-primary"
                  *ngIf="!isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="save()">
            Guardar
          </button>
          <button class="btn btn-primary"
                  *ngIf="isEdition"
                  type="submit"
                  [disabled]="!isValidForm()"
                  (click)="update()">
            Guardar cambios
          </button>

          <button class="btn btn-secondary"
                  type="button"
                  (click)="cancel()">
            Cancelar
          </button>
        </div>

      </form>

    </div>
  </div>

</div>
<!-- form container -->



import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { OperationResult, EmployeeRegistryVM } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRegistryService {

  registryApi = `${environment.apiUrl}/EmployeeRegistry`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(registry: EmployeeRegistryVM) {
    return this.http.post(this.registryApi, registry).pipe(
      map((response: OperationResult<EmployeeRegistryVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.alertify.warning(response.DetailMessages);
          return null;
        }
      }));
  }

}

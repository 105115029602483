
<div [hidden]="showForm || showDetails">
  <h4>
    Empleados
  </h4>

  <button class="btn btn-primary"
          type="button"
          (click)="addNew()">
    Agregar
  </button>

  <hr />

  <app-generic-grid #grid
                    (rowToSelect)="select($event)"
                    (rowToEdit)="edit($event)"
                    (rowToDelete)="delete($event)">
  </app-generic-grid>

</div>
<!-- grid container -->

<div [hidden]="!showForm">

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">

      <div class="btn-group" [hidden]="!isEdition">
        <button class="btn btn-info"
                [ngClass]="{'active': showEditInfoForm}"
                (click)="switchEdition(1)">
          Editar información
        </button>
        <button class="btn btn-info"
                [ngClass]="{'active': showChangePasswordForm}"
                (click)="switchEdition(2)">
          Cambiar contraseña
        </button>
      </div>

      <div [hidden]="showChangePasswordForm">

        <h5 *ngIf="!isEdition">Agregar Empleado</h5>
        <h5 *ngIf="isEdition">Editar Empleado</h5>

        <hr />
        <br />

        <form [formGroup]="employeeForm">

          <igx-input-group>
            <input igxInput name="EmployeeNumber"
                   [igxFocus]="showForm && !isEdition"
                   [readOnly]="isEdition"
                   [ngClass]="{'is-invalid': employeeForm.get('EmployeeNumber').errors && employeeForm.get('EmployeeNumber').touched}"
                   type="text"
                   aria-label="Número de empleado"
                   formControlName="EmployeeNumber" />
            <label igxLabel for="EmployeeNumber">No. Empleado</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="Name"
                   [igxFocus]="showForm && isEdition"
                   [ngClass]="{'is-invalid': employeeForm.get('Name').errors && employeeForm.get('Name').touched}"
                   type="text"
                   aria-label="Nombre"
                   formControlName="Name" />
            <label igxLabel for="Name">Nombre</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="FirstLastName"
                   [ngClass]="{'is-invalid': employeeForm.get('FirstLastName').errors && employeeForm.get('FirstLastName').touched}"
                   type="text"
                   aria-label="Apellido Paterno"
                   formControlName="FirstLastName" />
            <label igxLabel for="FirstLastName">Apellido Paterno</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="SecondLastName"
                   [ngClass]="{'is-invalid': employeeForm.get('SecondLastName').errors && employeeForm.get('SecondLastName').touched}"
                   type="text"
                   aria-label="Apellido Materno"
                   formControlName="SecondLastName" />
            <label igxLabel for="SecondLastName">Apellido Materno</label>
          </igx-input-group>

          <igx-input-group>
            <input igxInput name="DailySalary"
                   [ngClass]="{'is-invalid': employeeForm.get('DailySalary').errors && employeeForm.get('DailySalary').touched}"
                   type="number"
                   aria-label="Sueldo diario"
                   formControlName="DailySalary" />
            <label igxLabel for="DailySalary">Sueldo Diario</label>
          </igx-input-group>

          <div class=" row mt-1 mb-1">
            <div class="col-md-6 col-sm-12">
              <app-generic-selector #daySelector></app-generic-selector>
            </div>
            <div class="col-md-6 col-sm-12">
              <igx-input-group>
                <input igxInput name="VacationDays"
                       [ngClass]="{'is-invalid': employeeForm.get('VacationDays').errors && employeeForm.get('VacationDays').touched}"
                       type="number"
                       aria-label="Días de vacaciones"
                       formControlName="VacationDays" />
                <label igxLabel for="VacationDays">Días de Vacaciones</label>
              </igx-input-group>
            </div>
          </div>

          <app-butchery-selector #butcherySelector></app-butchery-selector>

          <div [hidden]="isEdition">
            <igx-input-group>
              <input igxInput name="Username"
                     [ngClass]="{'is-invalid': employeeForm.get('Username').errors && employeeForm.get('Username').touched}"
                     type="text"
                     aria-label="Nombre de usuario"
                     formControlName="Username" />
              <label igxLabel for="Username">Nombre de Usuario</label>
            </igx-input-group>

            <igx-input-group>
              <input igxInput name="Password"
                     type="{{ !hidePassword ? 'text' : 'password'}}"
                     [ngClass]="{'is-invalid': employeeForm.get('Password').errors && employeeForm.get('Password').touched}"
                     aria-label="Contraseña"
                     formControlName="Password" />
              <label igxLabel for="Password">Contraseña</label>
            </igx-input-group>

            <div class="mt-1 mb-1">
              <igx-checkbox [checked]="!hidePassword"
                            (change)="hidePassword=!hidePassword">
                Mostrar Contraseña
              </igx-checkbox>
            </div>
          </div>

          <br />
          <hr />

          <div class="d-flex justify-content-between">
            <button class="btn btn-primary"
                    *ngIf="!isEdition"
                    type="submit"
                    [disabled]="!isValidForm()"
                    (click)="save()">
              Guardar
            </button>
            <button class="btn btn-primary"
                    *ngIf="isEdition"
                    type="submit"
                    [disabled]="!isValidForm()"
                    (click)="update()">
              Guardar cambios
            </button>

            <button class="btn btn-secondary"
                    type="button"
                    (click)="cancel()">
              Cancelar
            </button>
          </div>

        </form>
      </div>

      <div [hidden]="showEditInfoForm">

        <h5>Cambiar Contraseña</h5>

        <hr />
        <br />

        <form [formGroup]="changePasswordForm">

          <div class="form-control d-flex justify-content-between align-items-center"
               *ngIf="employeeVM">
            <b>Empleado</b>
            <span>{{ employeeVM.FullName }}</span>
          </div>

          <br />

          <span class="bg-warning"
                *ngIf="!changePasswordMatch()">
            <b>Las contraseñas no coinciden.</b>
          </span>

          <igx-input-group *ngIf="!showPassword">
            <input igxInput name="Password"
                   type="password"
                   aria-label="Contraseña"
                   formControlName="Password" />
            <label igxLabel for="Password">Contraseña</label>
          </igx-input-group>
          <igx-input-group *ngIf="showPassword">
            <input igxInput name="Password"
                   type="text"
                   aria-label="Contraseña"
                   formControlName="Password" />
            <label igxLabel for="Password">Contraseña</label>
          </igx-input-group>

          <igx-input-group *ngIf="!showPassword">
            <input igxInput name="ConfirmPassword"
                   type="password"
                   aria-label="Confirmar Contraseña"
                   formControlName="ConfirmPassword" />
            <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
          </igx-input-group>
          <igx-input-group *ngIf="showPassword">
            <input igxInput name="ConfirmPassword"
                   type="text"
                   aria-label="Confirmar Contraseña"
                   formControlName="ConfirmPassword" />
            <label igxLabel for="ConfirmPassword">Confirmar Contraseña</label>
          </igx-input-group>

          <igx-checkbox [checked]="showPassword"
                        (change)="showPassword=!showPassword">
            Mostrar Contraseña
          </igx-checkbox>

          <div *ngIf="showConfirmation">
            <br />
            <hr />
            <b>Contraseña modificada</b>
          </div>

          <br />
          <hr />

          <div class="d-flex justify-content-between">
            <button class="btn btn-primary"
                    type="submit"
                    [disabled]="!isChangePasswordValidForm()"
                    (click)="updatePassword()">
              Cambiar Contraseña
            </button>

            <button class="btn btn-secondary"
                    type="button"
                    (click)="cancel()">
              Cerrar
            </button>
          </div>

        </form>
      </div>

    </div>
  </div>

</div>
<!-- form container -->

<div [hidden]="!showDetails">

  <h4>
    Detalles de Empleado
  </h4>

  <button class="btn btn-secondary"
          (click)="closeDetails()">
    Cerrar
  </button>


  <br /><hr />

  <app-employee-details #employeeDetails></app-employee-details>
</div>



import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SaleGutsVM, GutVM, CustomerVM, CageVM } from '../../../models/ViewModels';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { Router } from '@angular/router';
import { LocalData } from '../../../models/local.data';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';
import { HeaderDetailsComponent } from '../../../components/header-details/header-details.component';
import { ListDetailsComponent } from '../../../components/list-details/list-details.component';
import { SquareDetailsComponent } from '../../../components/square-details/square-details.component';
import { SaleGutsService } from '../../../services/sale-guts.service';

@Component({
  selector: 'app-gut-sale-details',
  templateUrl: './gut-sale-details.component.html',
  styleUrls: ['./gut-sale-details.component.scss']
})
export class GutSaleDetailsComponent implements OnInit {

  @ViewChild("saleDetails", { read: HeaderDetailsComponent, static: true })
  public saleDetails: HeaderDetailsComponent;

  @ViewChild("customerDetails", { read: ListDetailsComponent, static: true })
  public customerDetails: ListDetailsComponent;

  @ViewChild("cageDetails", { read: ListDetailsComponent, static: true })
  public cageDetails: ListDetailsComponent;

  @ViewChild("gutsList", { read: SquareDetailsComponent, static: true })
  public gutsList: SquareDetailsComponent;

  saleVM: SaleGutsVM;
  guts: GutVM[] = [];
  saleId: string;

  constructor(private salesService: SaleGutsService,
    private alertify: AlertifyService,
    private loading: LoadingService,
    private router: Router) {

    if (localStorage.getItem(LocalData.SELECTED_GUT_SALE_UUID) == null)
      this.router.navigate(['home/guts-sales']);
    this.saleId = localStorage.getItem(LocalData.SELECTED_GUT_SALE_UUID).toString();

  }

  ngOnInit(): void {
    this.getSaleInfo();
  }

  getSaleInfo() {
    this.loading.show();
    this.salesService.getById(this.saleId)
      .subscribe((response: SaleGutsVM) => {
        if (response) {
          this.saleVM = response;
          this.guts = this.saleVM.Guts;
          this.createHeader();
          this.createCustomerDetails();
          this.createCageDetails();
          this.createGutsList();
          this.loading.hide();
        }
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  createHeader() {
    this.saleDetails.headerItems = [
      { title: "Fecha", value: this.saleVM.Date, isDate: true },
      { title: "Nota", value: this.saleVM.NoteNumber },
      { title: "Cantidad", value: this.saleVM.GutsQuantity },
      { title: "Total", value: this.saleVM.Total, isCurrency: true },
    ];

    this.saleDetails.showHeader = true;
  }

  createCustomerDetails() {
    this.customerDetails.detailsTitle = `Cliente: ${this.saleVM.CustomerName}`;
    this.customerDetails.details = [
      { title: this.saleVM.Customer.FullName, value: "" },
      { value: this.saleVM.Customer.Telephone, title: "Teléfono" },
      { value: this.saleVM.Customer.MobilePhone, title: " Celular" },
      { value: this.saleVM.Customer.Email, title: "Correo electrónico" },
      { value: this.createFullAddress(this.saleVM.Customer), title: "Domicilio" },
      { title: "Saldo", value: this.saleVM.Customer.Balance, isCurrency: true },

    ];
    this.customerDetails.showItems = true;
    this.customerDetails.collapse = true;
  }

  createFullAddress(customer: CustomerVM): string {
    return `${customer.Address1} ${customer.Address2}, ${customer.ZipCode}, ${customer.City}, ${customer.StateName}`;
  }

  createCageDetails() {
    this.cageDetails.detailsTitle = `Jaula: ${this.saleVM.CageInfo}`;
    this.cageDetails.details = [
      { value: this.createCageIdentifier(this.saleVM.Cage) },
      { value: this.saleVM.Cage.Date, isDate: true },
      { title: "Número de Cabezas", value: this.saleVM.Cage.NumberOfHeads },
      { title: "Peso Total", value: `${this.saleVM.Cage.Weight} Kg` },
      { title: "Peso Actual", value: `${this.saleVM.Cage.TotalWeight} Kg` },
      { title: "Precio por Kilo", value: this.saleVM.Cage.KiloCost, isCurrency: true },
      { title: "Precio de Flete", value: this.saleVM.Cage.FreightCost, isCurrency: true },
      { title: "Total", value: this.saleVM.Cage.TotalCost, isCurrency: true },

    ];
    this.cageDetails.showItems = true;
    this.cageDetails.collapse = true;
  }

  createGutsList() {
    this.gutsList.detailsTitle = `Vísceras: ${this.saleVM.GutsQuantity}`;
    this.guts.forEach(gut => {
      this.gutsList.headerItems.push({
        title: gut.Id.toString(), value: gut.Price, isCurrency: true
      });
      this.gutsList.showHeader = true;
      this.gutsList.collapse = false;
    });
  }

  createCageIdentifier(cage: CageVM) {
    return `${cage.Identifier}${cage.Id}`;
  }



  close() { this.router.navigate(['home/guts-sales']); }

  // remove local storage on component destroy
  ngOnDestroy(): void {
    localStorage.removeItem(LocalData.SELECTED_GUT_SALE_UUID);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { GutVM } from '../../models/ViewModels';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';

@Component({
  selector: 'app-guts-details',
  templateUrl: './guts-details.component.html',
  styleUrls: ['./guts-details.component.scss']
})
export class GutsDetailsComponent implements OnInit {

  guts: GutVM[] = [];
  gutVM: GutVM;
  showGrid: boolean = false;
  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;

  locationDetails: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  itemClick(gut: GutVM) {
    this.gutVM = gut;
  }

  setGridOptions() {
    this.grid.setData(this.guts);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Visceras";
    this.grid.options.showDetailsButton = true;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.hideActionButtons = true;
    this.grid.options.perPage = this.guts.length;

    this.grid.columns = [
      { field: "Id", title: "Id" },
      { field: "Price", title: "Precio", currencyType: true },
      { field: "LocationAlias", title: "Ubicación" },
      { field: "Sold", title: "Vendido", booleanType: true },
      { field: "CustomerName", title: "Vendido a" },
    ];
    this.grid.setGridOptions();
  }

  setGridOptionsForLocationDetails() {
    this.grid.setData(this.guts);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Visceras";
    this.grid.options.hideActionButtons = true;
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.perPage = this.guts.length;

    this.grid.columns = [
      { field: "CageInfo", title: "Jaula" },
      { field: "Id", title: "Id" },
      { field: "Price", title: "Precio", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

}

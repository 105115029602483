import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CageVM, AnimalVM } from '../../../models/ViewModels';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { CageService } from '../../../services/cage.service';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { LocalData } from '../../../models/local.data';
import { Router } from '@angular/router';
import { HeaderDetailsItem } from '../../../models/componentInterfaces';
import { forEach } from 'jszip';
import { CageAnimals } from '../../../models/LocalModels';

@Component({
  selector: 'app-cage-animals',
  templateUrl: './cage-animals.component.html',
  styleUrls: ['./cage-animals.component.scss']
})
export class CageAnimalsComponent implements OnInit, OnDestroy {

  cageVM: CageVM;
  animals: AnimalVM[] = [];
  cageId: string;
  totalWeight: number = 0;

  cageAnimals: CageAnimals;
  localDataExists: boolean = false;

  constructor(private cageService: CageService,
    private alertify: AlertifyService,
    private router: Router,
    private loading: LoadingService) {

    // Get cage id local info
    if (localStorage.getItem(LocalData.SELECTED_CAGE_UUID) == null) {
      alert("no data found");
      this.router.navigate(['home/cages']);
    }
    this.cageId = localStorage.getItem(LocalData.SELECTED_CAGE_UUID).toString();

    // Get cage animals local info
    if (localStorage.getItem(LocalData.CAGE_ANIMALS) == null) {
      this.cageService.createEmptyCageAnimals();
    } else {
      this.cageAnimals = this.cageService.getLocalCageAnimals();
      this.localDataExists = true;
    }

  }

  ngOnInit(): void {
    this.getCageInfo();
  }

  getCageInfo() {
    this.loading.show();
    this.cageService.getById(this.cageId)
      .subscribe((response: CageVM) => {
        if (response) {
          this.cageVM = response;
          if (!this.localDataExists) {
            this.totalWeight = this.cageVM.TotalWeight;
            this.animals = this.cageVM.Animals;
            this.cageService.setLocalCageAnimals(this.cageVM);
          } else {
            if (this.cageAnimals.CageUUID == this.cageVM.UUID) {
              this.totalWeight = this.cageAnimals.TotalWeight;
              this.animals = this.cageAnimals.animals;
            } else {
              this.totalWeight = this.cageVM.TotalWeight;
              this.animals = this.cageVM.Animals;
              this.cageService.setLocalCageAnimals(this.cageVM);
            }
          }
        }
        this.loading.hide();
      }, (err) => {
        console.log(err);
        this.loading.hide();
      });
  }

  setWeight(animal: AnimalVM) {
    try {
      let w = parseFloat(animal.Weight.toString());
      if (w != 0) {
        this.loading.show();
        animal.SaleWeight = animal.Weight;
        this.calculateTotalWeight();
        const cage: CageVM = {
          UUID: this.cageVM.UUID,
          Id: this.cageVM.Id,
          Identifier: this.cageVM.Identifier,
          NumberOfHeads: this.cageVM.NumberOfHeads,
          Weight: this.cageVM.Weight,
          TotalWeight: this.totalWeight,
          KiloCost: this.cageVM.KiloCost,
          FreightCost: this.cageVM.FreightCost,
          TotalCost: this.cageVM.TotalCost,
          Date: this.cageVM.Date,
          Farmer: this.cageVM.Farmer,
          Animals: [animal]
        };

        this.cageService.updateTotalWeight(cage)
          .subscribe((response: boolean) => {
            if (response) {
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
            console.log(err);
          });
      }
    } catch (e) {
      this.alertify.warning(`El valor especificado no es válido`);
      animal.Weight = 0;
      this.loading.hide();
    }

  }

  // set local weight
  setLocalWeight(animal: AnimalVM) {
    try {
      let w = parseFloat(animal.Weight.toString());
      if (w != 0) {
        animal.SaleWeight = animal.Weight;
        this.calculateTotalWeight();
        this.cageService.setLocalAnimalWeight(animal);
      }
    } catch (e) {
      this.alertify.warning(`El valor especificado no es válido`);
      animal.Weight = 0;
    }

  }

  calculateTotalWeight() {
    this.totalWeight = 0;
    this.animals.forEach(item => {
      this.totalWeight += item.Weight;
    });
    this.cageService.setLocalTotalWeight(this.totalWeight);
  }

  save() {
    this.loading.show();
    const cage: CageVM = {
      UUID: this.cageVM.UUID,
      Id: this.cageVM.Id,
      Identifier: this.cageVM.Identifier,
      NumberOfHeads: this.cageVM.NumberOfHeads,
      Weight: this.cageVM.Weight,
      TotalWeight: this.cageService.getLocalCageAnimalsTotalWeight(),
      KiloCost: this.cageVM.KiloCost,
      FreightCost: this.cageVM.FreightCost,
      TotalCost: this.cageVM.TotalCost,
      Date: this.cageVM.Date,
      Farmer: this.cageVM.Farmer,
      Animals: this.cageService.getLocalCageAnimalsListOfAnimals()
    };

    this.cageService.updateTotalWeight(cage)
      .subscribe((response: boolean) => {
        if (response) {
          this.loading.hide();
        }
      }, (err) => {
        this.loading.hide();
        console.log(err);
      });
  }

  close() {
    localStorage.removeItem(LocalData.CAGE_ANIMALS);
    this.router.navigate(['home/cage-guts']);
  }

  // remove local storage on component destroy
  ngOnDestroy(): void {
    //localStorage.removeItem(LocalData.SELECTED_CAGE_UUID);
  }

}

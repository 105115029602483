import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AlertifyService } from '../services/alertify.service';
import { retry, catchError } from 'rxjs/operators';
import { OperationResult } from '../models/ViewModels';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertify: AlertifyService) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // client-side error
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            let errorDetails: OperationResult<any> = error.error;
            errorMessage = errorDetails.Details[0].Message;
            
            switch (error.status) {
              case 404: this.showWarningMessage(`No encontrado`);
                this.showWarningMessage(errorMessage);
                break;
              case 406: this.showWarningMessage(`No aceptado`);
                this.showWarningMessage(errorMessage);
                break;
              case 409: this.showWarningMessage("Registro duplicado");
                this.showWarningMessage(errorMessage);
                break;
              default: this.showErrorMessage(`Error Code: ${error.status}`);
                this.showWarningMessage(errorMessage);
                break;
            }
          }

          console.log(error); //TODO: Remove for PROD
          return throwError(errorMessage);
        }));
  }

  showWarningMessage(msg: string) {
    this.alertify.warning(msg);
  }

  showErrorMessage(msg: string) {
    this.alertify.error(msg);
    console.log(msg);
  }
}


<div class="__component">
  <button class="btn btn-secondary"
          [disabled]="disabled"
          (click)="collapse=!collapse">
    {{selectedSales}} notas seleccionadas
  </button>

  <div [ngClass]="{'collapse': collapse}">
    <div class="__listContainer">
      <div class="__listItem"
           *ngFor="let sale of sales"
           (click)="selectItem(sale)">
        <div class="row">
          <div class="col-sm-1">
            <i class="fa fa-check"
               *ngIf="sale.IsSelected"></i>
          </div>
          <div class="col-sm">
            <b>{{ sale.NoteNumber }}</b>
          </div>
          <!--<div class="col-sm-4">
            {{cage.TotalCost | currency
              }}
</div>-->
        </div>
      </div>
    </div>
  </div>

</div>

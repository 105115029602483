
export function DateToString(date: Date): string {
  return IsSameDate(date) ? FormatDateString(new Date()) : FormatDateString(date);

}

export function IsSameDate(date: Date): boolean { 
const today = new Date();
return date.getDate() === today.getDate() &&
  date.getMonth() === today.getMonth() &&
date.getFullYear() === today.getFullYear();

}

export function FormatDateString(date: Date) {
return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UserVM, UserDto, RegisterRequestVM, ChangePasswordVM } from '../../../models/ViewModels';
import { AlertifyService } from '../../../services/alertify.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GenericGridComponent } from '../../../components/generic-grid/generic-grid.component';
import { RolSelectorComponent } from '../../../components/rol-selector/rol-selector.component';
import { LocalData } from '../../../models/local.data';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  users: UserVM[] = [];
  userVM: UserVM;
  userDto: UserDto;

  showForm: boolean = false;
  isEdition: boolean = false;
  showPassword: boolean = false;
  userForm: FormGroup;
  changePasswordForm: FormGroup;

  @ViewChild("rolSelector", { read: RolSelectorComponent, static: false })
  public rolSelector: RolSelectorComponent;

  showEditInfoForm: boolean = false;
  showChangePasswordForm: boolean = false;
  showConfirmation: boolean = false;

  constructor(private userService: UserService,
    private loading: LoadingService,
    private fb: FormBuilder,
    private notify: AlertifyService) { }

  ngOnInit(): void {
    this.initForm();
    this.initChangePasswordForm();
    this.getAllUsers();
    
  }

  initForm() {
    this.userForm = this.fb.group({
      Username: ['', [Validators.required, Validators.minLength(3)]],
      FirstName: ['', [Validators.required, Validators.minLength(3)]],
      LastName: ['', [Validators.required, Validators.minLength(3)]],
      Password: ['', [Validators.required, Validators.minLength(3)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(3)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  addNew() {
    this.setFormVisibility();
    this.showEditInfoForm = true;
  }

  setFormVisibility() { this.showForm = !this.showForm; }

  isValidForm(): boolean {
    return this.userForm.valid
      && this.rolSelector.selectorForm.valid
      && this.passwordMatch();
  }

  passwordMatch(): boolean {
    return this.userForm.value.Password == this.userForm.value.ConfirmPassword;
  }

  changePasswordMatch(): boolean {
    return this.changePasswordForm.value.Password == this.changePasswordForm.value.ConfirmPassword;
  }

  getAllUsers() {
    this.loading.show();
    this.userService.getAll()
      .subscribe((response: UserVM[]) => {
        if (response) {
          response.forEach(usr => {
            usr.RolName = this.setRolName(usr.Rol);
          });
          this.users = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
          console.log(err);
          alert("error");
          this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.users);
    this.grid.options.primaryKey = "UUID";
    this.grid.options.toolbarTitle = "Usuarios";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Username", title: "Usuario" },
      { field: "FirstName", title: "Nombre" },
      { field: "LastName", title: "Apellido" },
      { field: "RolName", title: "Rol" },
    ];
    this.grid.setGridOptions();
  }

  cancel() {
    this.isEdition = false;
    this.initForm();
    this.setFormVisibility();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const newUser: RegisterRequestVM = Object.assign({
        RolId: this.rolSelector.getRolId()
      }, this.userForm.value);
      this.userService.create(newUser)
        .subscribe((response: boolean) => {
          if (response) {
            this.getAllUsers();
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
            console.log(err);
            this.loading.hide();
        });
    }
  }

  // Grid events
  edit(user: UserVM) {
    this.userVM = user;
    this.userForm.setValue({
      Username: this.userVM.Username,
      FirstName: this.userVM.FirstName,
      LastName: this.userVM.LastName,
      Password: '12345',
      ConfirmPassword: '12345'
    });
    this.isEdition = true;
    this.showChangePasswordForm = false;
    this.showEditInfoForm = true;
    this.setFormVisibility();
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const updatedUser: UserVM = Object.assign({
        UUID: this.userVM.UUID
      }, this.userForm.value);

      this.userService.update(updatedUser)
        .subscribe((response: boolean) => {
          if (response) {
            this.grid.updateRowByUUID(updatedUser);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
            console.log(err);
            this.loading.hide();
        });
    }
  }

  delete(user: UserVM) {
    this.notify.confirm(
      `Eliminar usuario: ${user.Username}`,
      () => {
        this.loading.show();
        this.userService.delete(user.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(user.UUID);
              this.loading.hide();
            }
          }, (err) => {
              console.log(err);
              this.loading.hide();
          });
      });
  }

  setRolName(rol: string): string {
    var result = "";
    switch (rol) {
      case LocalData.ADMIN_ROL: result = "Administrador";
        break;
      case LocalData.CHECK_ROL: result = "Checador";
        break;
      default: result = "";
        break;
    }
    return result;
  }

  switchEdition(value: number) {
    this.showEditInfoForm = value === 1;
    this.showChangePasswordForm = value === 2;

  }

  isChangePasswordValidForm(): boolean {
    return this.changePasswordForm.valid
      && this.changePasswordMatch();
  }

  updatePassword() {
    if (this.isChangePasswordValidForm()) {
      this.loading.show();
      const passwordInfo: ChangePasswordVM = {
        UserId: this.userVM.UUID,
        NewPassword: this.changePasswordForm.controls['Password'].value,
        ConfirmNewPassword: this.changePasswordForm.controls['ConfirmPassword'].value,
      };
      this.userService.changePassword(passwordInfo)
        .subscribe((response: boolean) => {
          if (response) {
            this.showConfirmation = true;
            this.loading.hide();
            this.initChangePasswordForm();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

}

<igx-date-picker locale="es-MX"
                 cancelButtonLabel="Cancelar"
                 todayButtonLabel="Hoy"
                 [formatter]="formatter"
                 name="customDate"
                 (onSelection)="setDate($event)">
  <ng-template igxDatePickerTemplate
               let-openDialog="openDialog"
               let-value="customDate"
               let-displayData="customDate">
    <igx-input-group (click)="openDialog()">
      <label igxLabel>{{ placeHolder }}</label>
      <input igxInput [value]="customDate" placeholder="{{placeHolder}}" />
      <igx-suffix>
        <igx-icon>today</igx-icon>
      </igx-suffix>
    </igx-input-group>
  </ng-template>
</igx-date-picker>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { LocationCutVM, OperationResult } from '../models/ViewModels';
import { environment } from '../../environments/environment';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class LocationCutsService {

  locationCutsApi = `${environment.apiUrl}/LocationCut`;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private alertify: AlertifyService) { }

  create(locationCut: LocationCutVM) {
    return this.http.post(this.locationCutsApi, locationCut).pipe(
      map((response: OperationResult<LocationCutVM>) => {
        if (response.Success) {
          if (locationCut.InChamber) {
            this.alertify.success(`${locationCut.Weight} Kg enviados a cámara`);
          } else {
            this.alertify.success("Se asignó la ubicación de los cortes");
          }
          return response.Data;
        } else {
          this.errorService.logerrorMessage(response);
          return null;
        }
      }));
  }

}
